import Layout from '../../components/layout/Layout';
import ListOfAccess from '../../components/tables/AccessTable/ListOfAccess';
import withAuth from '../../components/cookie/AuthenticatedComponent';
import WithClearCache from '../../ClearCache';

function DashAccess() {
      
    return (
        <Layout>
            <ListOfAccess></ListOfAccess>
        </Layout>
    );
}

export default withAuth(DashAccess);