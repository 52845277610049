import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import EditableTable from '../../components/timeTable/dateTableNew';

function DashUserPage() {
 
    return (
        
        <Layout>   
            <EditableTable></EditableTable>
        </Layout>
    );
}

export default withAuth(DashUserPage);