import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, designationHCode) => {
    const response = await fetch(`${SERVER_URL}/designation/update/${designationHCode}`, {
        method: 'PUT',
        credentials:"include",
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const DesignationModal = ({ designation, showModal, closeModal, change }) => {
    const designationHCode = designation.hcode;
    const [designationData, setDesignationData] = useState(designation);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);    
    const [msg, setMsg] = useState('Are you sure?');

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(designationData, designationHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    useEffect(() => {
        setDesignationData(designation);
    }, [designation]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDesignationData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };



    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit Designation</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="designation_name">
                            <Form.Label>Designation Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="designation_name"
                                value={designationData.designation_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};


export default DesignationModal;