import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';


// const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ConfirmModal = ({ confirmModal, closeConfirmModal, confirm, HCode, obj, msg}) => {

    const handleNo = () =>{
        closeConfirmModal();
    }

    const handleYes = () => {
        closeConfirmModal();
        confirm(obj, HCode);
    }

    return (
        <Modal show={confirmModal} onHide={closeConfirmModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Confirm Opinion</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <h5>{msg}</h5>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={handleNo}>
                    No
                </Button>
                <Button variant="primary" onClick={handleYes}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default ConfirmModal;