import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const RejectCommentsModal = ({ showModal, closeModal, setComments, handleCommentSubmit }) => {

    const handleOK = () => {
        handleCommentSubmit();
        closeModal();
    }

    const handleInputChange = (event) => {
        const value = event.target.value.trim();
        setComments(value);
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <input type="text" onChange={handleInputChange} placeholder='Please enter your comment here .. ' style={{ width: '100%' }} />
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="primary" onClick={handleOK}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default RejectCommentsModal;