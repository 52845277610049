import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import style from './style/modal.module.css';
import styles from '../../common/common.module.css';
import styleInput from './style/projectAccessModal.module.css';
import ConfirmModal from './ConfirmModal';
import { LogoutIfNoHcode } from "../../common/LogoutIfNoHcode";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const UserAccessModal = ({ employee_name, showAccessModal, closeModal, projectsAccess, setProjectsAccess, setProvideAccess, setRemoveAccess }) => {
    const [projectsList, setProjectsList] = useState([]);
    const [filteredProjectsList, setFilteredProjectsList] = useState([]);

    const [accessProjectsList, setAccessProjectsList] = useState(projectsAccess);
    const [filteredAccessProjectsList, setFilteredAccessProjectsList] = useState(projectsAccess);

    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedAccessProject, setSelectedAccessProject] = useState([]);
    const [highlightProject, setHighlightProject] = useState([]);
    const [highlightAccessProject, setHighlightAccessProject] = useState([]);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const result = findMissingAndExtraObjects(accessProjectsList, filteredAccessProjectsList);
        setProvideAccess(result.extra);
        setRemoveAccess(result.missing);
        setProjectsAccess(filteredAccessProjectsList);
        closeModal();
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    useEffect(() => {
        fetch(`${SERVER_URL}/project`,{
            method:"GET",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const projects = data.projects;
                const filteredList = projects.filter(obj1 =>
                    projectsAccess.length === 0 || !projectsAccess.some(obj2 => obj1.hcode === obj2.hcode)
                );
                setProjectsList(filteredList);
                setFilteredProjectsList(filteredList);
            })
            .catch((error) => console.error(error));
    }, []);

    const handleProjectClick = (project) => {
        setSelectedAccessProject([]);
        setHighlightAccessProject('');

        if (selectedProject.some(selected => selected.hcode === project.hcode)) {
            // User is already selected, so remove them from the list
            setSelectedProject((prev) => prev.filter(selected => selected.hcode !== project.hcode));
            setHighlightProject((prev) => prev.filter(hcode => hcode !== project.hcode));
        } else {
            // User is not selected, so add them to the list
            setSelectedProject((prev) => [...prev, project]);
            setHighlightProject((prev) => [...prev, project.hcode]);
        }
    };


    const handleAccessProjectClick = (project) => {
        setSelectedProject([]);
        setHighlightProject('');

        if (selectedAccessProject.some(selected => selected.hcode === project.hcode)) {
            // accessUser is already selected, so remove them from the list
            setSelectedAccessProject((prev) => prev.filter(selected => selected.hcode !== project.hcode));
            setHighlightAccessProject((prev) => prev.filter(hcode => hcode !== project.hcode));
        } else {
            // accessUser is not selected, so add them to the list
            setSelectedAccessProject((prev) => [...prev, project]);
            setHighlightAccessProject((prev) => [...prev, project.hcode]);
        }
    };


    const handleAddProjectClick = (projectsToAdd) => {
        if (!Array.isArray(projectsToAdd)) {
            return; // Return early if projectsToAdd is not an array
        }

        // Filter projectsToAdd to exclude those already in filteredAccessProjectsList
        const newProjectsToAdd = projectsToAdd.filter(project => !filteredAccessProjectsList.includes(project));

        // Add the new projects to filteredAccessProjectsList
        if (newProjectsToAdd.length > 0) {
            setFilteredAccessProjectsList((prev) => [...prev, ...newProjectsToAdd]);
        }

        // Filter filteredProjectsList to exclude projects with matching hcodes from projectsToAdd
        const fArray = filteredProjectsList.filter((obj) => !projectsToAdd.some(project => project.hcode === obj.hcode));
        setFilteredProjectsList(fArray);
    };

    const handleRemoveProjectClick = (projectsToRemove) => {
        if (!Array.isArray(projectsToRemove)) {
            return; // Return early if projectsToRemove is not an array
        }

        // Filter projectsToRemove to exclude those already in filteredProjectsList
        const newProjectsToRemove = projectsToRemove.filter(project => !filteredProjectsList.includes(project));

        // Add the new projects to filteredProjectsList
        if (newProjectsToRemove.length > 0) {
            setFilteredProjectsList((prev) => [...prev, ...newProjectsToRemove]);
        }

        // Filter filteredAccessProjectsList to exclude projects with matching hcodes from projectsToRemove
        const fArray = filteredAccessProjectsList.filter((obj) => !projectsToRemove.some(project => project.hcode === obj.hcode));
        setFilteredAccessProjectsList(fArray);
    };

    function findMissingAndExtraObjects(accessProjectsList, filteredAccessProjectsList) {
        const missingObjects = [];
        const extraObjects = [];

        for (const objX of accessProjectsList) {
            if (!filteredAccessProjectsList.some(objY => objY.hcode === objX.hcode)) {
                missingObjects.push(objX);
            }
        }

        for (const objY of filteredAccessProjectsList) {
            if (!accessProjectsList.some(objX => objX.hcode === objY.hcode)) {
                extraObjects.push(objY);
            }
        }

        return {
            missing: missingObjects,
            extra: extraObjects
        };
    }

    // Search for project 
    const handleProject = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredProjectsList(projectsList);
        }
        
        const fArray = projectsList.filter((obj) => (
            (obj.project_name + obj.project_id).toLowerCase().includes(value.toLowerCase())
        ));

        setFilteredProjectsList(fArray);
    };

    // Search for accessUser 
    const handleProjectAccess = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredAccessProjectsList(accessProjectsList);
        }
        const fArray = accessProjectsList.filter((obj) => (obj.project_name + obj.project_id).includes(value));
        setFilteredAccessProjectsList(fArray);
    };

    return (
        <>
            <Modal show={showAccessModal} onHide={closeModal} centered size="xl">
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Assign Projects to {employee_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <div className="d-flex flex-column gap-3" style={{ margin: 'auto', maxHeight: '65vh' }}>
                        <div className="d-flex flex-row justify-content-between mx-2" >
                            <input type="text" className="form-control" id="search" name="projectSearch" style={{ width: '40%' }} onChange={handleProject} placeholder="Add Project" autoComplete="off" required />
                            <input type="text" className="form-control" id="search2" name="projectAccessSearch" style={{ width: '40%' }} onChange={handleProjectAccess} placeholder="Existing Project" autoComplete="off" required />
                        </div>
                        <div className="overflow-auto d-flex flex-row justify-content-between gap-2" style={{ minHeight: '50vh', maxHeight: '60vh' }} >
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '42%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {filteredProjectsList.length > 0 ? filteredProjectsList.map((project) => (
                                        <li key={project.hcode} onClick={() => { handleProjectClick(project) }}
                                            className={`list-group-item ${highlightProject.includes(project.hcode) ? `${styleInput['highlight']}` : ''}`}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {project.project_id + ' - ' + project.project_name }
                                        </li>
                                    )) : <p>No matching records found. </p>}
                                </ul>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '6%' }}>
                                <button className={`btn-secondary rounded btn my-4`} onClick={() => handleAddProjectClick(selectedProject)}> {`>>`} </button>
                                <button className={`btn ${styles['btn-style']} rounded my-4`} onClick={() => handleRemoveProjectClick(selectedAccessProject)}> {`<<`} </button>
                            </div>
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '42%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {
                                        filteredAccessProjectsList.length > 0 ? (
                                            <ul className="list-group">
                                                {
                                                    filteredAccessProjectsList.map((project) => (
                                                        <li
                                                            key={project.hcode}
                                                            className={`list-group-item ${highlightAccessProject.includes(project.hcode) ? `${styleInput['highlight']}` : ''}`}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => { handleAccessProjectClick(project) }}
                                                        >
                                                            {project.project_id + ' - ' + project.project_name }
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        ) : (
                                            <p>{filteredAccessProjectsList.length > 0 ? 'No matching records found.' : 'Projects yet to be added.'}</p>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    )
};

export default UserAccessModal;
