import React, { useState, useEffect, useDebugValue } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import Select from 'react-select';
import ConfirmModal from './ConfirmModal';
import { format } from "date-fns";
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, projectHCode) => {
    const response = await fetch(`${SERVER_URL}/project/update/${projectHCode}`, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};

const ProjectModal = ({ project, showModal, closeModal, change }) => {
    const projectHCode = project.hcode;
    const [projectData, setProjectData] = useState(project);
    const [clientsList, setClientList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [disciplinesList, setDisciplinesList] = useState([]);
    const [approversList, setApproversList] = useState([]);

    const [projectDisciplines, setProjectDisciplines] = useState([{ value: 'P&ID', label: 'P&ID' }]);


    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');
   
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(projectData, projectHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };

    const fetchClients = async () => {
        // Fetch clients from the server here
        fetch(`${SERVER_URL}/client`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.clients.map((client) => ({
                    value: client.client_name,
                    label: client.client_name,
                }));
                setClientList(result);
            })
            .catch(error => console.error(error));
    };

    const fetchBusinessLines = async () => {
        // Fetch business lines from the server here
        fetch(`${SERVER_URL}/business`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.business.map((business_line) => ({
                    value: business_line.business_line_name,
                    label: business_line.business_line_name,
                }));
                setBusinessList(result);
            })
            .catch(error => console.error(error));
    };

    const fetchDisciplines = async () => {
        // Fetch disciplines from the server here
        fetch(`${SERVER_URL}/discipline`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.disciplines.map((discipline) => ({
                    value: discipline.discipline_name,
                    label: discipline.discipline_name,
                }));
                setDisciplinesList(result);
            })
            .catch(error => console.error(error));
    };

    const fetchManagers = async () => {
        // Fetch users from the server here
        fetch(`${SERVER_URL}/user`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.users.map((user) => ({
                    value: user.login_name,
                    label: user.login_name + ' (' + user.emp_id + ')',
                }));
                // setManagersList(result);
                setApproversList(result);
            })
            .catch(error => console.error(error));
    };

    const fetchProjectDisciplines = async () => {
        // Fetch disciplines from the server here
        fetch(`${SERVER_URL}/project/discipline/${projectHCode}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.disciplines.map((discipline) => ({
                    value: discipline.discipline_name,
                    label: discipline.discipline_name,
                }));
                setProjectDisciplines(result);
            })
            .catch(error => console.error(error));
    };

    useEffect(() => {
        setProjectData(project);
        fetchClients();
        fetchBusinessLines();
        fetchDisciplines();
        fetchManagers();
        // fetchProjectDisciplines();
    }, [project]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProjectData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

    };

    const handleSelectChange = (selectedOption, fieldName) => {
        setProjectData((prevState) => ({
            ...prevState,
            [fieldName]: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleRadioInputChange = (event) => {
        const { name, value, checked } = event.target;
        setProjectData((prevState) => ({
            ...prevState,
            [name]: checked ? parseInt(value) : 0,
        }));
    };


    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'yyyy-MM-dd');
        return formattedDate;
    };

    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered size='xl'>
                <Modal.Header closeButton className={`${style['modal-header']}`} >
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit Project</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <div className='d-flex gap-3'>
                            <Form.Group controlId="projectID" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Project ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="project_id"
                                    value={projectData.project_id}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="projectName" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Project Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="project_name"
                                    value={projectData.project_name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </div>
                        <br />
                        <div className='d-flex gap-3'>
                            <Form.Group controlId="po_number" style={{ width: '33%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Purchase Order Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="po_number"
                                    value={projectData.po_number}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="po_hours" style={{ width: '33%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Purchase Order Hours</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="po_hours"
                                    value={projectData.po_hours}
                                    onChange={handleInputChange}
                                    min="0"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="po_date" style={{ width: '33%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Purchase Order Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="po_date"
                                    value={projectData.po_date && formatDate(projectData.po_date)}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </div>
                        <br />
                        <div className='d-flex gap-3'>
                            <Form.Group controlId="start_date" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="start_date"
                                    value={formatDate(projectData.start_date)}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="end_date" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="end_date"
                                    value={projectData.end_date && formatDate(projectData.end_date)}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </div>
                        <br />
                        <div className='d-flex gap-3'>
                            <Form.Group controlId="is_active" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Is Active</Form.Label>
                                <div className="d-flex gap-3">
                                    <Form.Check
                                        type="radio"
                                        label="Yes"
                                        name="is_active"
                                        value={1}
                                        checked={projectData.is_active === 1}
                                        onChange={handleRadioInputChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="No"
                                        name="is_active"
                                        value={0}
                                        checked={projectData.is_active === 0}
                                        onChange={handleRadioInputChange}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="is_billable">
                                <Form.Label style={{ fontWeight: 'bold' }}>Is Billable</Form.Label>
                                <div className="d-flex gap-3">
                                    <Form.Check
                                        type="radio"
                                        label="Yes"
                                        name="is_billable"
                                        value={1}
                                        checked={projectData.is_billable === 1}
                                        onChange={handleRadioInputChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="No"
                                        name="is_billable"
                                        value={0}
                                        checked={projectData.is_billable === 0}
                                        onChange={handleRadioInputChange}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <br />
                        <div className='d-flex gap-3'>
                            <Form.Group controlId="client_name" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Client Name</Form.Label>
                                <Select
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            color: "#000000",
                                        })
                                    }}
                                    name="client_name"
                                    options={clientsList}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'client_name')}
                                    value={
                                        projectData.client_name
                                            ? { value: projectData.client_name, label: projectData.client_name }
                                            : null
                                    }
                                    placeholder="Select Client"
                                    isSearchable
                                    menuPosition="fixed"
                                    menuPlacement="bottom"
                                    required
                                    isClearable
                                />
                            </Form.Group>
                            <Form.Group controlId="business_line_name" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Business Line Name</Form.Label>
                                <Select
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            color: "#000000",
                                        })
                                    }}
                                    name="business_line_name"
                                    options={businessList}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'business_line_name')}
                                    value={
                                        projectData.business_line_name
                                            ? { value: projectData.business_line_name, label: projectData.business_line_name }
                                            : null
                                    }
                                    placeholder="Select Business Line"
                                    isSearchable
                                    menuPosition="fixed"
                                    menuPlacement="bottom"
                                    isClearable
                                />
                            </Form.Group>
                        </div>
                        <br />
                        {/* <h5>Update individual discpline PO hours</h5>
                        <div className='d-flex gap-3'>
                        <Form.Group controlId="individual_discipline_name" style={{ width: '50%' }}>
                                <Form.Label style={{ fontWeight: 'bold' }}>Discipline</Form.Label>
                                <Select
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            color: "#000000",
                                        })
                                    }}
                                    name="individual_discipline_name"
                                    options={disciplinesList}
                                    onChange={(selectedOption) => {
                                        handleSelectChange(selectedOption, 'individual_discipline_name');
                                    }}
                                    value={
                                             {value: projectData.individual_discipline_name, 
                                                label: projectData.individual_discipline_name} 
                                            }
                                    placeholder="Select Discipline"
                                    isSearchable
                                    menuPosition="fixed"
                                    menuPlacement="bottom"
                                    required
                                />
                            </Form.Group>
                            {projectData.individual_discipline_name && (
                                <Form.Group controlId="individual_hours" style={{ width: '33%' }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Individual hours</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="individual_hours"
                                        onChange={handleInputChange}
                                        min="0"
                                        required
                                    />
                                </Form.Group>)}
                        </div> */}

                        {/* <label className="w-100 d-flex flex-column">
                            Discipline
                            <MultiSelect options={disciplinesList} setSelectedOptions={setSelectedDiscipline} projectDisciplines={projectDisciplines}/>
                        </label> */}

                        {/* <Form.Group controlId="discipline_name">
                            <Form.Label style={{ fontWeight: 'bold' }}>Discipline</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="discipline_name"
                                options={disciplinesList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'discipline_name')}
                                value={
                                    projectData.discipline_name
                                        ? { value: projectData.discipline_name, label: projectData.discipline_name }
                                        : null
                                }
                                placeholder="Select Discipline"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                            />
                        </Form.Group> */}

                        {/* <Form.Group controlId="approver_name">
                            <Form.Label>Approver</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="approver_name"
                                options={approversList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'approver_name')}
                                value={
                                    projectData.approver_name
                                        ? { value: projectData.approver_name, label: projectData.approver_name }
                                        : null
                                }
                                placeholder="Select Approver"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                            />
                        </Form.Group> */}
                        {/* <Form.Group controlId="manager_name">
                            <Form.Label>Project Manager</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="manager_name"
                                options={managersList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'manager_name')}
                                value={
                                    projectData.manager_name
                                        ? { value: projectData.manager_name, label: projectData.manager_name }
                                        : null
                                }
                                placeholder="Select Project Manager"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                            />
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};


export default ProjectModal;