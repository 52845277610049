// withAuth.js - Higher-Order Component (HOC)
import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/Slice/userSlice';
import AppContext from "../../context/loginContext";
import fetchHcode from "../../redux/hcodeFetch";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const withAuth = (WrappedComponent) => {
    const AuthComponent = (props) => {
        const dispatch = useDispatch();
        const { isLogin, setIsLogin, isAdmin, setIsAdmin, setTheme } = useContext(AppContext);
        useEffect(() => {
            const verifyCookie = async () => {
                
                const getUser = fetchHcode() 

                if(getUser.hcode === ""){
                    await fetch(`${SERVER_URL}/logout`,{
                        method:"POST",
                        credentials:"include"
                    })
                    window.location.href = '/login';
                 }

                // try {
                //     const response = await fetch(`${SERVER_URL}/verify-cookie`,{
                //         method:"GET",
                //         credentials:"include"
                //     });
                //     const data = await response.json()
                //     if (data.statusCode !== 200) {
                //         // Not authenticated, logout user
                //         dispatch(logout({ hcode: "", isAdmin: "" }));
                //         setIsLogin(false);
                //         window.location.href = '/login';
                //     }
                // } catch (error) {
                //     console.error('Error verifying cookie:', error);
                //     // Handle error scenarios (e.g., network issues)
                // }
            };

            verifyCookie();
        }, [dispatch]);

        return <WrappedComponent {...props} />;
    };

    return AuthComponent;
};

export default withAuth;
