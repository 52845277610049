import EmployeeDetails from '../../components/Employee/EmployeeDetails';
import Layout from '../../components/layout/Layout';

function DashEmployeeDetails() {

    return (
        <Layout>            
            <EmployeeDetails></EmployeeDetails>
        </Layout>
    );
}

export default DashEmployeeDetails;