import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfClients from '../../components/tables/ClientTable/ListOfClients';
// import { useNavigate } from 'react-router';
// import fetchHcode from '../../redux/hcodeFetch';

function DashClients() {
    // const getUser = fetchHcode() 
    //  const navigate = useNavigate()
    //  if(getUser.hcode === ""){
    //     navigate("/login")
    //  }
    return (
        <Layout>           
            <ListOfClients></ListOfClients>           
        </Layout>
    );
}

export default withAuth(DashClients);