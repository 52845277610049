import withAuth from "../../components/cookie/AuthenticatedComponent";
import Layout from '../../components/layout/Layout';
import AddUserForm from '../../components/dashpage/AddUserForm';

function DashAddUser() {
    return (
        <Layout>
            <AddUserForm></AddUserForm>           
        </Layout>
    );
}

export default withAuth(DashAddUser);