
import Layout from '../../components/layout/Layout';
import AddClientForm from '../../components/dashpage/AddClientForm';
import withAuth from '../../components/cookie/AuthenticatedComponent';

function DashAddClient() {
    return (
        <Layout>     
            <AddClientForm></AddClientForm>
        </Layout>
    );
}

export default withAuth(DashAddClient);