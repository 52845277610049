import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import ConfirmModal from '../Modal/ConfirmModal';
import MultiSelect from "../sample/ReactSelect";
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';

import { useDispatch } from 'react-redux';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AddDisciplineAccess = () => {
   
    const [managersList, setManagersList] = useState([]);
    const [selectedManager, setSelectedManager] = useState(null);
    const [disciplineList, setDisciplineList] = useState([]) 
    const [selectedDiscipline, setSelectedDiscipline] = useState(null)

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

   const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };
    
    const arrayOfValues = (arrayOfObjects) => {
        const result = arrayOfObjects.map((obj) => {
            return obj.value;
        })
        return result;
    }


    const confirm = async () => {
        let formData = {
            manager: selectedManager.value,
            discipline:arrayOfValues(selectedDiscipline)
        };

        fetch(`${SERVER_URL}/discipline/manager/access/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })

            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/discipline/manager/access`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    }

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${SERVER_URL}/user`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.users.map((manager) => ({
                    value: manager.hcode,
                    label: manager.login_name + ' (' + manager.emp_id + ')',
                }));

                setManagersList(result);
            })
            .catch(error => console.error(error));
          
         fetch(`${SERVER_URL}/discipline`,{
            method:"GET",
            credentials:"include"
         }).then(response => response.json())
         .then(async data =>{
            if(data.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
            const result = await data.disciplines.map((discipline)=>(console.log(discipline),{
                value : discipline.hcode,
                label : discipline.discipline_name
            }))
            
            setDisciplineList(result)
            console.log(disciplineList)
         }).catch(error => console.error(error));

    }, [])

  
    const handleGoBack = () => {
        navigate('/discipline/manager/access')
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setConfirmModal(true);
        setSave(true);
    };

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>

                <form className="w-25" onSubmit={handleFormSubmit}>
                    <h1 className="h1 mb-3 fw-normal text-center font-weight-bold">Add Discipline Access</h1>
                    <label className="w-100 d-flex flex-column">
                        <MultiSelect options={disciplineList} setSelectedOptions={setSelectedDiscipline} />
                    </label>
                    <br/>
                    <label className="w-100 d-flex flex-column">
                        Discipline Manager
                        <Select
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    color: "#000000",
                                })
                            }}
                            name="manager"
                            options={managersList}
                            onChange={(selectedOption) => setSelectedManager(selectedOption)}
                            value={
                                selectedManager
                                    ? { value: selectedManager.value, label: selectedManager.label }
                                    : null
                            }
                            placeholder="Select Manager"
                            menuPosition="fixed"
                            menuPlacement="bottom"
                            isSearchable
                        />
                    </label>
                    <br />
                    <div className="d-flex justify-content-between">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} type="submit">Add Discipline</button>
                    </div>
                    <br />
                </form>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};

export default AddDisciplineAccess;