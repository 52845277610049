import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';
import { useNavigate } from "react-router-dom";
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useDispatch } from 'react-redux';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const NetworkIDModal = ({ project_name, projectHCode, project_id, showModal, closeModal, change }) => {
    const [networkID, setNetworkID] = useState();
    // const [softwaresList, setSoftwaresList] = useState([]);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        let formData = {
            networkID: networkID,
            projectHCode: projectHCode,
        };

        fetch(`${SERVER_URL}/network/ids/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        closeModal();
                        change(data);
                        navigate(`/${project_name}/network/ids/${projectHCode}/${project_id}`);
                    }
                }, 1000);
            })
            .catch(error => console.error(error));
    };

    const handleSaveChanges = async () => {
        if (!networkID) {
            return;
        }
        setConfirmModal(true);
        setSave(true);
    };


    const handleInputChange = (event) => {
        setNetworkID(event.target.value.trim());
    };

    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Add Network ID</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="network_id">
                            <Form.Label>Network ID</Form.Label>
                            <Form.Control
                                type="text"
                                name="network_id"
                                placeholder='6339919-0030'
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Add
                </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};


export default NetworkIDModal;