import React, { useState, useEffect} from 'react';
import { useParams} from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import ConfirmModal from '../../Modal/ConfirmModal';
import NetworkIDModal from '../../Modal/NetworkIDModal';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import DataTable from 'react-data-table-component';
import Alert from "../../alert/Alert"
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfProjectNetworkIDS() {
    const [networks, setNetworks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');
    const [searchText, setSearchText] = useState('');  
    const [filteredData, setFilteredData] = useState([]);
    const [responseData, setResponseData] = useState({})
    const [showAlert, setShowAlert] = useState(false)

    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (network) => {
        await fetch(`${SERVER_URL}/network/ids/delete/${network.hcode}`, {
            method: 'DELETE',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then(respData =>{
            if(respData.statusCode === 200){
                setResponseData(respData)
                const updatedSw = networks.filter(item => item.hcode !== network.hcode)
                setNetworks(updatedSw)
                setFilteredData([])
                setSearchText("")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000);
            }
            if(respData.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        })
    };

    const { project_name, projectHCode, project_id } = useParams();

    // Function to open the modal when the "Provide Project Tasks" button is clicked
    const handleAddNetworkIDS = () => {
        setShowModal(true);
    };


    // Function to close the modal
    const closeModal = () => {
        setShowModal(false);
    };


    useEffect(() => {
        fetch(`${SERVER_URL}/network/ids/${projectHCode}`,{
            method:"GET",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setNetworks(data.networks);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);

    const handleRemoveNetwork = async (network) => {
        setSelectedNetwork(network);
        setConfirmModal(true);
        setSave(true);
    };

   //react table columns
   const columns = [
    {
        name: <span style={{ fontWeight: '800' }}>NETWORK ID</span>,
        selector: 'date',
        cell: row => <div style={{fontWeight:"500"}}>{row.network_id}</div>,
    },
    {
        name: <span style={{ fontWeight: '800' }}>PROJECT NAME</span>,
        selector: 'hdesc',
        cell: row => <div style={{fontWeight:"500"}}>{row.project_name}</div>,
    },
     {
        name :<span style={{ fontWeight: "800" }}>DELETE</span>,
        cell: row=>(<a style={{ cursor: 'pointer' }} onClick={() => handleRemoveNetwork(row)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a>),
        width: '100px'
    }

];

const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    // Filter the data based on the search text
    
    const filteredItems = networks.filter(item =>
      item.network_id.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filteredItems);
  };

  const dataToDisplay = searchText.trim() === '' ? networks : filteredData;

    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
                <h2 className="pt-3 text-center w-75 fw-normal" >Network IDS for <strong>{project_id + ' - ' + project_name}</strong></h2>
                <div className="p-3 w-75 d-flex flex-column align-items-center">
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                    <div className="w-100 d-flex justify-content-end">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={handleAddNetworkIDS}
                        >
                            Add Network ID
                        </button>
                        {showModal && (
                            <NetworkIDModal project_name={project_name} projectHCode={projectHCode} project_id={project_id} showModal={showModal} closeModal={closeModal} change={setChange} />
                        )}
                    </div>

                    {/* <div className="w-100 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Network ID</th>
                                    <th>Project Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {networks.map((network) => (
                                    <tr key={network.hcode}>
                                        <td>{network.network_id}</td>
                                        <td>{network.project_name}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleRemoveNetwork(network)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}
                         <div className='w-75 flex align-items-end justify-content-between'>
                        <input
                         style={{ marginBottom: '10px'}}
                         type='text'
                         placeholder='search network id'
                         value={searchText}
                         onChange={handleSearch}
                        /> 
                           
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={dataToDisplay}
                         />

                    </div>
                   

                </div>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={selectedNetwork}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default ListOfProjectNetworkIDS;
