
import React, { useEffect, useState } from "react";
import Input from "./InputStyler";
import OverTimeInput from "./OverTimesInputStyler";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";

const tdStyle = {
    border: "1px solid black",
    textAlign: "center",
    width: "65px",
    height: "63px",
    color: "black",
};

const tdAdvanceStyle = {
    border: "1px solid black",
    textAlign: "center",
    width: "65px",
    height: "30.5px",
    color: "black",
};

const totalTdStyle = {
    ...tdStyle,
    color: "black",
};

const TableRow = ({ project, projectIndex, dates, handleProjectNameChange, handleHourChange,
    handleOverTimeHourChange, handleDuplicateProject, selectedDate, handleDeleteProject, advanceView, submitDay, permissionToSubmit }) => {

    const [total, setTotal] = useState('');
    const [hourChange, setHourChange] = useState(true);
    const [overtime, setOvertime] = useState(false);
    const [deleteRowModal, setDeleteRowModal] = useState(false)


    useEffect(() => {
        setTotal(calculateTotal())
    }, [hourChange, selectedDate])


    const calculateDecimalHours = (hour) => {
        if (hour) {
            const [hours, minutes] = hour.split(":");
            const minutesWorked = parseInt(hours) * 60 + parseInt(minutes);
            return minutesWorked;
        }
    };

    const formatToHHMM = (minutesWorked) => {
        const hours = Math.floor(minutesWorked / 60);
        const minutes = minutesWorked % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    };

    const calculateTotal = () => {
        try {
            let total = 0;
            project.hours.forEach((hour) => {
                const decimalHour = calculateDecimalHours(hour['hours']);
                const overtimedecimalHour = calculateDecimalHours(hour['ot_hours']);
                if (!isNaN(decimalHour)) {
                    total += parseInt(decimalHour);
                }
                if (!isNaN(overtimedecimalHour)) {
                    total += parseInt(overtimedecimalHour);
                }
            });
            return formatToHHMM(total);
        } catch (err) {
            console.log(err, project.hours)
        }
    };


    const calculateNormaltimeTotal = () => {
        try {
            let total = 0;
            project.hours.forEach((hour) => {
                const decimalHour = calculateDecimalHours(hour['hours']);
                if (!isNaN(decimalHour)) {
                    total += parseInt(decimalHour);
                }

            });
            return formatToHHMM(total);
        } catch (err) {
            console.log(err, project.hours)
        }
    };


    const calculateOvertimeTotal = () => {
        try {
            let total = 0;
            project.hours.forEach((hour) => {
                const overtimedecimalHour = calculateDecimalHours(hour['ot_hours']);
                if (!isNaN(overtimedecimalHour)) {
                    total += parseInt(overtimedecimalHour);
                }
            });
            return formatToHHMM(total);
        } catch (err) {
            console.log(err, project.hours)
        }
    };


    const handleDelete = () => {
        setDeleteRowModal(true)
    }

    const deleteRowModalStatus = () => {
        setDeleteRowModal(false)
    }


    return (
        <>
            <tr key={projectIndex}>
                {project.hours.map(({ hour, hcode, ot_hours }, dayIndex) => (
                    <td key={dayIndex} style={tdStyle}>
                        {advanceView ? (
                            <table style={{ margin: 'auto', border: '2px solid', width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>NT</th>
                                        <th>OT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={advanceView ? tdAdvanceStyle : tdStyle}>
                                            <Input
                                                date={dates[dayIndex]}
                                                project={project}
                                                selectedDate={selectedDate}
                                                projectIndex={projectIndex}
                                                dayIndex={dayIndex}
                                                dates={dates}
                                                handleProjectNameChange={handleProjectNameChange}
                                                handleHourChange={handleHourChange}
                                                setHourChange={setHourChange}
                                                submitDay={submitDay}
                                                permissionToSubmit={permissionToSubmit}
                                            />
                                        </td>
                                        <td style={advanceView ? tdAdvanceStyle : tdStyle}>
                                            <OverTimeInput
                                                advanceView={advanceView}
                                                date={dates[dayIndex]}
                                                project={project}
                                                selectedDate={selectedDate}
                                                projectIndex={projectIndex}
                                                dayIndex={dayIndex}
                                                dates={dates}
                                                handleProjectNameChange={handleProjectNameChange}
                                                handleOverTimeHourChange={handleOverTimeHourChange}
                                                setOvertime={setOvertime}
                                                submitDay={submitDay}
                                                permissionToSubmit={permissionToSubmit}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <Input
                                date={dates[dayIndex]}
                                project={project}
                                selectedDate={selectedDate}
                                projectIndex={projectIndex}
                                dayIndex={dayIndex}
                                dates={dates}
                                handleProjectNameChange={handleProjectNameChange}
                                handleHourChange={handleHourChange}
                                setHourChange={setHourChange}
                                submitDay={submitDay}
                                permissionToSubmit={permissionToSubmit}
                            />
                        )}
                    </td>
                ))}
                <td style={totalTdStyle}>
                    <a style={{ cursor: 'pointer' }} onClick={() => { handleDuplicateProject(project) }}>
                        <img src={process.env.PUBLIC_URL + '/images/copy.png'} alt="Edit" height='20px' width='20px' />
                    </a>
                    &nbsp;&nbsp;
                    {!project.status &&
                        <a style={{ cursor: 'pointer' }} onClick={() => { handleDelete(projectIndex) }}>
                            <img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Edit" height='20px' width='20px' />
                        </a>
                    }
                </td>
                <td style={totalTdStyle}>
                    {advanceView ? (
                        <table style={{ margin: 'auto', border: '2px solid', width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>NT</th>
                                    <th>OT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "33px",
                                        height: "30px",
                                        color: "black",
                                        fontWeight: '700'
                                    }}>
                                        {calculateNormaltimeTotal()}
                                    </td>
                                    <td style={{
                                        border: "1px solid black",
                                        textAlign: "center",
                                        width: "33px",
                                        height: "30px",
                                        color: "black",
                                        fontWeight: '700'
                                    }}>
                                        {calculateOvertimeTotal()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <span style={{ fontWeight: 'bold' }}>{calculateTotal()}</span>
                    )}
                </td>
            </tr>
            {deleteRowModal && (
                <ConfirmDeleteModal
                    confirm={handleDeleteProject}
                    projectIndex={projectIndex}
                    statusModal={deleteRowModal}
                    closeStatusModal={deleteRowModalStatus}
                />
            )}
        </>
    );

};

export default TableRow;
