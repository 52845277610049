import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/loginContext";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import fetchHcode from "../../redux/hcodeFetch";
import { LogoutIfNoHcode } from "../../common/LogoutIfNoHcode";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const NavbarComponent = () => {
    const { isLogin, setIsLogin, isAdmin, setIsAdmin, theme } = useContext(AppContext);
    const [access, setAccess] = useState(false);
    const [navbarAccess, getNavbarAccess] = useState([]) 

   const navigate = useNavigate()
   const dispatch = useDispatch()

    const data = [
        { name: 'Employees', href: '/employees' },
        { name: 'Clients', href: '/clients' },
        { name: 'Tasks', href: '/tasks' },
        { name: 'Designations', href: '/designations' },
        { name: 'Disciplines', href: '/disciplines' },
        { name: 'Softwares', href: '/softwares' },
        { name: 'Permissions', href: '/permissions' },
        { name: 'Holidays', href: '/holidays' },
        { name: 'Business Lines', href: '/business' },
        { name: "Discipline Manage", href:"/discipline/manager/access"}
    ];

    const getUser = fetchHcode()

    const fetchUserAccessForReports = async () => {
        if (getUser.hcode != "") {
            const response = await fetch(`${SERVER_URL}/check/report/access`, {
                method: "get",
                credentials: "include"
            });
            const data = await response.json();
            if (data.statusCode === 200) {
                setAccess(data.access);
            }
            if(data.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        }
    };
     
    const fetchNavbarAccess = async()=>{
        fetch(`${SERVER_URL}/navbar/options/access/${getUser.hcode}`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {

                if (data.statusCode === 200) {
                    getNavbarAccess(data.navbarAccess);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            }
            )
            .catch(error => console.error(error));  
        }

    useEffect(() => {
        // fetchData();
        fetchUserAccessForReports();
        fetchNavbarAccess()
    }, [])


    return (
        <>
            {getUser.hcode ?
                <Navbar sticky="top" bg={theme ? 'light' : 'dark'} data-bs-theme={theme ? 'light' : 'dark'} className="flex flex-column" style={{ minHeight: '7vh' }}>
                    <div className="w-25 d-flex align-items-center justify-content-center">
                        <Navbar.Brand href="https://www.sidvinoutotec.com/" target="_blank">
                        </Navbar.Brand>
                        <Nav>
                            {/* {(access || getUser.isAdmin === 1) &&
                                <>
                                    <Nav.Link href="/timelog">Timesheet</Nav.Link>
                                    <Nav.Link href="/approvals">Approvals</Nav.Link>
                                    <Nav.Link href="/reports">Reports</Nav.Link>
                                </>
                            } */}
                           {
                                navbarAccess.length > 0 && (
                                    <>
                                        {navbarAccess.slice(0, 4).map((navbar, index) => (
                                            <Nav.Link key={navbar.navbar_module_name} href={`/${navbar.navbar_module_name.toLowerCase()}`}>
                                                {navbar.navbar_module_name}
                                            </Nav.Link>
                                        ))}
                                    </>
                                )
                            }

                            
                            {navbarAccess.length > 4 &&
                                <>
                                    <NavDropdown title="Utilities" id="collasible-nav-dropdown">
                                        {data.map((item) => (
                                            <NavDropdown.Item key={item.name} href={item.href} >{item.name}</NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                </>
                            }
                        </Nav>
                    </div>
                </Navbar >
                :
                <>
                </>
            }
        </>
    );

}

export default NavbarComponent;