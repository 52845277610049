import React from "react";
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import fetchHcode from "../../redux/hcodeFetch";
import Layout from '../../components/layout/Layout';

import forgotStyle from './Forgot.module.css';
import ForgotForm from "../../components/forgotpasspage/ForgotForm";


function ForgotPasswordPage() {
    const { isLogin, setIsLogin, isAdmin } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const userDetails = fetchHcode()
        if(userDetails.hcode !== ""){
            navigate("/timesheet")
        }
    }, []);

    return (
        <Layout>
            <div className={forgotStyle['div-style']}  style={{minHeight: '86vh'}}>
                <ForgotForm />
            </div>
        </Layout>
    );
}

export default ForgotPasswordPage;