import React, { useState} from "react";
import { useNavigate} from "react-router-dom";
import fetchHcode from "../../redux/hcodeFetch";
import Alert from "../../components/alert/Alert";
import { Modal, Button, Form } from "react-bootstrap";
import style from '../../components/Modal/style/modal.module.css';
import { update } from "../../redux/Slice/userSlice";
import { useDispatch } from "react-redux";
import styles from "./LoginPage.module.css"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function OtpVerify({ show}) {
    const dispatch = useDispatch()
    const [otp, setOtp] = useState("")
    const [showAlert, setShowAlert] = useState(false);
    const userDetaiils = fetchHcode()
    const [incorrectAttempts, setIncorrectAttempts] = useState(0);
    const email = userDetaiils.email
    const [responeMsg, setResponseMsg] = useState("")

    const navigate = useNavigate()

    //masking email
    const [username, domain] = email.split("@");
    const usernameParts = username.split(".");
    const maskedUsername = usernameParts.map((part, index) => {
        return index === 0 ? part : maskCharacters(part);
    }).join(".");

    function maskCharacters(str) {
        return '*'.repeat(str.length);
    }
    const maskedEmail = `${maskedUsername}@${domain}`;

    const handleOtp = (event) => {
        setOtp(event.target.value.trim())
    }

    const verifyOtp = async (event) => {
        event.preventDefault();
        if (event.key === 'Enter') {
            return
        }
        try {
            const response = await fetch(`${SERVER_URL}/otp/api/verify-otp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    email: email,
                    otp: otp
                })
            });
             // Parse response JSON data
            const responseData = await response.json();

            if(responseData.statusCode === 401){
                new Promise((resolve) => {
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        resolve(window.location.href = '/login');
                    }, 1000);
                });
            }

                if (responseData.statusCode === 200) {
                    dispatch(update({ ...userDetaiils, hcode: responseData.data.hcode }))
                    setResponseMsg(responseData);
                    new Promise((resolve) => {
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            resolve(navigate("/timesheet"));
                        }, 1000);
                    });
                }
                else {
                    
                    // OTP verification failed
                    setResponseMsg(responseData);
                    setIncorrectAttempts(incorrectAttempts + 1);
                    setShowAlert(true);
                    if (incorrectAttempts > 2) {
                        // closeModal()
                        setShowAlert(false);
                        window.location.href = '/login';
                    } else {
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 2000);
                    }
                }
        } catch (error) {
            console.error("Error during OTP verification:", error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          
        }
      };

    return (
        <>
            <Modal show={show} centered>
                <Modal.Header className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Enter the OTP received on {maskedEmail}</Modal.Title>

                </Modal.Header>

                <Modal.Body className={`${style['modal-body']}`}>
                    <Form onSubmit={(event)=>{event.preventDefault()}}>
                        <Form.Group controlId="client_code">
                            <Form.Control
                               className={`${styles["no-arrow"]}`}
                                placeholder="OTP"
                                type="number"
                                name="client_code"
                                inputMode="numeric"
                                onChange={handleOtp}
                                onKeyDown={handleKeyPress}
                                required
                            />
                        </Form.Group>
                    </Form>
                    {
                        showAlert && <Alert data={responeMsg}></Alert>
                    }
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="primary" onClick={verifyOtp}>
                        Verify
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default OtpVerify;