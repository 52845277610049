import React, { useState, useEffect, useRef } from 'react';
import $, { data } from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import DisciplineModal from '../../Modal/DisciplineModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import DataTable from 'react-data-table-component';
import Alert from "../../alert/Alert"
import { logout } from '../../../redux/Slice/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfDisciplines() {
    const [disciplines, setDisciplines] = useState([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState(false);

    const [searchText, setSearchText] = useState('');  
    const [filteredData, setFilteredData] = useState([]);
    const [responseData, setResponseData] = useState({})
    const [showAlert, setShowAlert] = useState(false)

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (discipline) => {
        await fetch(`${SERVER_URL}/discipline/delete/${discipline.hcode}`, {
            method: 'DELETE',
            credentials: "include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then(data =>{
            if(data.statusCode === 200){
                setResponseData(data)
                const updatedSw = disciplines.filter(item => item.hcode !== discipline.hcode)
                setDisciplines(updatedSw)
                setFilteredData([])
                setSearchText("")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000);
            }
        })
    }

    useEffect(() => {
        fetch(`${SERVER_URL}/discipline`,{
            method:'get',
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setDisciplines(data.disciplines);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);

    const closeModal = () => {
        setShowModal(false);
    };

    const handleDisciplineEdit = (discipline) => {
        setSelectedDiscipline(discipline);
        setShowModal(true);
    };

    const handleDisciplineDelete = (discipline) => {
        setSelectedDiscipline(discipline);
        setConfirmModal(true);
        setSave(true);
    };

    //react table
    const columns = [
        {
            name: <span style={{ fontWeight: '800' }}>NAME</span>,
            selector: 'discipline_name',
            cell: row => <div style={{fontWeight:"500"}}>{row.discipline_name}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>MANAGER</span>,
            selector: 'manager_name',
            cell: row => <div style={{fontWeight:"500"}}>{row.manager_name}</div>,
        },
        {
            name :<span style={{ fontWeight: '800' }}>EDIT</span>,
            cell: row =>(<a style={{ cursor: 'pointer' }} onClick={() => handleDisciplineEdit(row)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a>),
            width: '100px'
        },
        {
            name :<span style={{ fontWeight: "800" }}>DELETE</span>,
            cell: row=>(<a style={{ cursor: 'pointer' }} onClick={() => handleDisciplineDelete(row)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a>),
            width: '100px'
        }
    
    ];


    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
    
        // Filter the data based on the search text
        
        const filteredItems = disciplines.filter(item =>
          item.discipline_name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredData(filteredItems);
      };
    
      const dataToDisplay = searchText.trim() === '' ? disciplines : filteredData;



    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
                <h2 className="text-center w-75 pt-3">List of Disciplines </h2>
                <div className="p-3 w-75 d-flex flex-column align-items-center">
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                    <div className="d-flex justify-content-end w-75">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={() => window.location.href = `/disciplines/add`}
                        >
                            Add Discipline
                    </button>
                    </div>
                    
                    {/* <div className="w-75 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    
                                    <th>Name</th>
                                   
                                    <th>Manager</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {disciplines.map((discipline) => (
                                    <tr key={discipline.hcode}>
                                        
                                        <td>{discipline.discipline_name}</td>
                                        
                                        <td>{discipline.manager_name}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleDisciplineEdit(discipline)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleDisciplineDelete(discipline)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}
                   
                   <div className='w-75 flex align-items-end justify-content-between'>
                        <input
                         style={{ marginBottom: '10px'}}
                         type='text'
                         placeholder='search discipline'
                         value={searchText}
                         onChange={handleSearch}

                        /> 
                           
                        
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={dataToDisplay}
                         />

                    </div>
                     

                </div>
            </div>
            {
                selectedDiscipline && (
                    <DisciplineModal
                        discipline={selectedDiscipline}
                        showModal={showModal}
                        closeModal={closeModal}
                        change={setChange}
                    />
                )
            }
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={selectedDiscipline}
                        msg = {msg}
                    />
                )
            }
        </>
    );
}

export default ListOfDisciplines;
