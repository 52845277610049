
import Layout from '../../components/layout/Layout';
import ListOfSoftwares from '../../components/tables/SoftwareTable/ListOfSoftwares';
import fetchHcode from '../../redux/hcodeFetch';
import { useNavigate } from 'react-router';
import withAuth from '../../components/cookie/AuthenticatedComponent';

function DashSoftwares() {
     
    //  const getUser = fetchHcode() 
    //  const navigate = useNavigate()
    //  if(getUser.hcode === ""){
    //     navigate("/login")
    //  }
    return (
        <Layout>            
            <ListOfSoftwares></ListOfSoftwares>            
        </Layout>
    );
}

export default withAuth(DashSoftwares);