import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import style from './style/modal.module.css';
import styles from '../../common/common.module.css';
import styleInput from './style/projectAccessModal.module.css';
import ConfirmModal from './ConfirmModal';
import { LogoutIfNoHcode } from "../../common/LogoutIfNoHcode";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ProjectTasksModal = ({ showModal, closeModal, tasksAccess, setTasksAccess, setProvideAccess, setRemoveAccess }) => {
    const [tasksList, setTasksList] = useState([]);
    const [filteredTasksList, setFilteredTasksList] = useState([]);

    const [accessTasksList, setAccessTasksList] = useState(tasksAccess);
    const [filteredAccessTasksList, setFilteredAccessTasksList] = useState(tasksAccess);

    const [selectedTask, setSelectedTask] = useState([]);
    const [selectedAccessTask, setSelectedAccessTask] = useState([]);
    const [highlightTask, setHighlightTask] = useState([]);
    const [highlightAccessTask, setHighlightAccessTask] = useState([]);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const result = await findMissingAndExtraObjects(accessTasksList, filteredAccessTasksList);
        setProvideAccess(result.extra);
        setRemoveAccess(result.missing);
        setTasksAccess(filteredAccessTasksList);
        closeModal();
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    useEffect(() => {
        fetch(`${SERVER_URL}/task`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const tasks = data.tasks;
                const filteredList = tasks.filter(obj1 =>
                    tasksAccess.length === 0 || !tasksAccess.some(obj2 => obj1.hcode === obj2.hcode)
                );
                setTasksList(filteredList);
                setFilteredTasksList(filteredList);
            })
            .catch((error) => console.error(error));
    }, []);

    const handleTaskClick = (task) => {
        setSelectedAccessTask([]);
        setHighlightAccessTask('');

        if (selectedTask.some(selected => selected.hcode === task.hcode)) {
            // Task is already selected, so remove them from the list
            setSelectedTask((prev) => prev.filter(selected => selected.hcode !== task.hcode));
            setHighlightTask((prev) => prev.filter(hcode => hcode !== task.hcode));
        } else {
            // Task is not selected, so add them to the list
            setSelectedTask((prev) => [...prev, task]);
            setHighlightTask((prev) => [...prev, task.hcode]);
        }
    };

    const handleAccessTaskClick = (accessTask) => {
        setSelectedTask([]);
        setHighlightTask('');

        if (selectedAccessTask.some(selected => selected.hcode === accessTask.hcode)) {
            // accessTask is already selected, so remove them from the list
            setSelectedAccessTask((prev) => prev.filter(selected => selected.hcode !== accessTask.hcode));
            setHighlightAccessTask((prev) => prev.filter(hcode => hcode !== accessTask.hcode));
        } else {
            // accessTask is not selected, so add them to the list
            setSelectedAccessTask((prev) => [...prev, accessTask]);
            setHighlightAccessTask((prev) => [...prev, accessTask.hcode]);
        }
    };

    const handleAddTaskClick = (tasksToAdd) => {
        if (!Array.isArray(tasksToAdd)) {
            return; // Return early if tasksToAdd is not an array
        }

        // Filter tasksToAdd to exclude those already in filteredAccessTasksList
        const newTasksToAdd = tasksToAdd.filter(task => !filteredAccessTasksList.includes(task));

        // Add the new tasks to filteredAccessTasksList
        if (newTasksToAdd.length > 0) {
            setFilteredAccessTasksList((prev) => [...prev, ...newTasksToAdd]);
        }

        // Filter filteredTasksList to exclude users with matching hcodes from tasksToAdd
        const fArray = filteredTasksList.filter((obj) => !tasksToAdd.some(task => task.hcode === obj.hcode));
        setFilteredTasksList(fArray);
    };

    const handleRemoveTaskClick = (tasksToRemove) => {
        if (!Array.isArray(tasksToRemove)) {
            return; // Return early if tasksToRemove is not an array
        }

        // Filter tasksToRemove to exclude those already in filteredTasksList
        const newTasksToRemove = tasksToRemove.filter(task => !filteredTasksList.includes(task));

        // Add the new tasks to filteredTasksList
        if (newTasksToRemove.length > 0) {
            setFilteredTasksList((prev) => [...prev, ...newTasksToRemove]);
        }

        // Filter filteredAccessTasksList to exclude tasks with matching hcodes from tasksToRemove
        const fArray = filteredAccessTasksList.filter((obj) => !tasksToRemove.some(task => task.hcode === obj.hcode));
        setFilteredAccessTasksList(fArray);
    };


    function findMissingAndExtraObjects(accessTasksList, filteredAccessTasksList) {
        const missingObjects = [];
        const extraObjects = [];

        for (const objX of accessTasksList) {
            if (!filteredAccessTasksList.some(objY => objY.hcode === objX.hcode)) {
                missingObjects.push(objX);
            }
        }

        for (const objY of filteredAccessTasksList) {
            if (!accessTasksList.some(objX => objX.hcode === objY.hcode)) {
                extraObjects.push(objY);
            }
        }

        return {
            missing: missingObjects,
            extra: extraObjects
        };
    }


    const handleTask = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredTasksList(tasksList);
        }
        const fArray = tasksList.filter((obj) => obj.task_name.toLowerCase().includes(value.toLowerCase()));
        setFilteredTasksList(fArray);
    };

    const handleTaskAccess = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredAccessTasksList(accessTasksList);
        }
        const fArray = accessTasksList.filter((obj) => obj.task_name.toLowerCase().includes(value.toLowerCase()));
        setFilteredAccessTasksList(fArray);
    };

    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered size="xl">
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Provide Access of the Tasks</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <div className="d-flex flex-column gap-3" style={{ margin: 'auto', maxHeight: '65vh' }}>
                        <div className="d-flex flex-row justify-content-between mx-3" >
                            <input type="text" className="form-control" id="task" name="taskSearch" style={{ width: '40%' }} onChange={handleTask} placeholder="Add Task" autoComplete="off" required />
                            <input type="text" className="form-control" id="task2" name="taskAccessSearch" style={{ width: '40%' }} onChange={handleTaskAccess} placeholder="Existing Task" required />
                        </div>
                        <div className="overflow-auto d-flex flex-row justify-content-between gap-2" style={{ minHeight: '50vh', maxHeight: '60vh' }} >
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '42%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {filteredTasksList.length > 0 ? filteredTasksList.map((task) => (
                                        <li key={task.hcode} onClick={() => { handleTaskClick(task) }}
                                            className={`list-group-item ${highlightTask.includes(task.hcode) ? `${styleInput['highlight']}` : ''}`}
                                            style={{ backgroundColor: 'transparent', borderColor: '#ebb30b', cursor: 'pointer' }}
                                        >
                                            {task.task_name}
                                        </li>
                                    )) : <p>No matching records found. </p>}
                                </ul>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '6%' }}>
                                <button className={`btn-secondary rounded btn my-4`} onClick={() => handleAddTaskClick(selectedTask)}> {`>>`} </button>
                                <button className={`btn ${styles['btn-style']} rounded my-4`} onClick={() => handleRemoveTaskClick(selectedAccessTask)}> {`<<`} </button>
                            </div>
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '42%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {
                                        filteredAccessTasksList.length > 0 ? (
                                            <ul className="list-group">
                                                {
                                                    filteredAccessTasksList.map((accessTask) => (
                                                        <li
                                                            key={accessTask.hcode}
                                                            className={`list-group-item ${highlightAccessTask.includes(accessTask.hcode) ? `${styleInput['highlight']}` : ''}`}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => { handleAccessTaskClick(accessTask) }}
                                                        >
                                                            {accessTask.task_name}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        ) : (
                                                <p>{filteredAccessTasksList.length > 0 ? 'No matching records found.' : 'Tasks yet to be added.'}</p>
                                            )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save
                </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    )

};

export default ProjectTasksModal;
