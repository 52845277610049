import Layout from '../../components/layout/Layout';
import AddDesignationForm from '../../components/dashpage/AddDesignationForm';
import withAuth from '../../components/cookie/AuthenticatedComponent';

function DashAddDesignation() {
    return (
        <Layout>
            <AddDesignationForm></AddDesignationForm>
        </Layout>
    );
}

export default withAuth(DashAddDesignation);