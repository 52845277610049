import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import { useNavigate } from "react-router-dom";
import styles from './../../common/common.module.css';
import ConfirmModal from '../Modal/ConfirmModal';
import DatePicker from "react-datepicker";
import { startOfWeek, endOfWeek, format, subWeeks } from "date-fns";
import styleInput from '../Modal/style/projectAccessModal.module.css';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const AddPermissionForm = () => {

    const [selectedStartDate, setSelectedStartDate] = useState(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }));
    const [selectedEndDate, setSelectedEndDate] = useState();

    const maxSelectableDate = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);


    const start = startOfWeek(selectedStartDate, { weekStartsOn: 1 });
    const end = endOfWeek(selectedStartDate, { weekStartsOn: 1 });


    const [usersList, setUsersList] = useState([]);
    const [filteredUsersList, setFilteredUsersList] = useState([]);

    const [accessUsersList, setAccessUsersList] = useState([]);
    const [filteredAccessUsersList, setFilteredAccessUsersList] = useState([]);

    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedAccessUser, setSelectedAccessUser] = useState([]);
    const [highlightUser, setHighlightUser] = useState([]);
    const [highlightAccessUser, setHighlightAccessUser] = useState([]);


    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        for (const user of filteredAccessUsersList) {
            let formData = {
                userHCode: user.hcode,
                startDate: format(selectedStartDate, 'yyyy-MM-dd'),
                endDate: format(selectedEndDate, 'yyyy-MM-dd'),
            };

            fetch(`${SERVER_URL}/permission/provide`, {
                method: 'POST',
                credentials:"include",
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if(data.statusCode === 401){
                        LogoutIfNoHcode(dispatch, navigate)
                    }
                    setResponseData(data);
                    setShowAlert(true);
                    setTimeout(() => {
                        if (data.statusCode === 200) {
                            navigate(`/permissions`);
                        }
                    }, 1000);
                    setTimeout(() => setShowAlert(false), 2000);
                })
                .catch(error => console.error(error));
        }
    };

    useEffect(() => {
        setSelectedStartDate(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }));
        setSelectedEndDate(endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }));

        fetch(`${SERVER_URL}/user`,{
            method:"GET",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const users = data.users;
                setUsersList(users);
                setFilteredUsersList(users);
            })
            .catch((error) => console.error(error));
    }, []);

    const handleUserClick = (user) => {
        setSelectedAccessUser([]);
        setHighlightAccessUser('');

        if (selectedUser.some(selected => selected.hcode === user.hcode)) {
            // User is already selected, so remove them from the list
            setSelectedUser((prev) => prev.filter(selected => selected.hcode !== user.hcode));
            setHighlightUser((prev) => prev.filter(hcode => hcode !== user.hcode));
        } else {
            // User is not selected, so add them to the list
            setSelectedUser((prev) => [...prev, user]);
            setHighlightUser((prev) => [...prev, user.hcode]);
        }
    };


    const handleAccessUserClick = (accessUser) => {
        setSelectedUser([]);
        setHighlightUser('');

        if (selectedAccessUser.some(selected => selected.hcode === accessUser.hcode)) {
            // accessUser is already selected, so remove them from the list
            setSelectedAccessUser((prev) => prev.filter(selected => selected.hcode !== accessUser.hcode));
            setHighlightAccessUser((prev) => prev.filter(hcode => hcode !== accessUser.hcode));
        } else {
            // accessUser is not selected, so add them to the list
            setSelectedAccessUser((prev) => [...prev, accessUser]);
            setHighlightAccessUser((prev) => [...prev, accessUser.hcode]);
        }
    };


    const handleAddUserClick = (usersToAdd) => {
        if (!Array.isArray(usersToAdd)) {
            return; // Return early if usersToAdd is not an array
        }

        // Filter usersToAdd to exclude those already in filteredAccessUsersList
        const newUsersToAdd = usersToAdd.filter(user => !filteredAccessUsersList.includes(user));

        // Add the new users to filteredAccessUsersList
        if (newUsersToAdd.length > 0) {
            setFilteredAccessUsersList((prev) => [...prev, ...newUsersToAdd]);
        }

        // Filter filteredUsersList to exclude users with matching hcodes from usersToAdd
        const fArray = filteredUsersList.filter((obj) => !usersToAdd.some(user => user.hcode === obj.hcode));
        setFilteredUsersList(fArray);
    };

    const handleRemoveUserClick = (usersToRemove) => {
        if (!Array.isArray(usersToRemove)) {
            return; // Return early if usersToRemove is not an array
        }

        // Filter usersToRemove to exclude those already in filteredUsersList
        const newUsersToRemove = usersToRemove.filter(user => !filteredUsersList.includes(user));

        // Add the new users to filteredUsersList
        if (newUsersToRemove.length > 0) {
            setFilteredUsersList((prev) => [...prev, ...newUsersToRemove]);
        }

        // Filter filteredAccessUsersList to exclude users with matching hcodes from usersToRemove
        const fArray = filteredAccessUsersList.filter((obj) => !usersToRemove.some(user => user.hcode === obj.hcode));
        setFilteredAccessUsersList(fArray);
    };

    // Search for user 
    const handleUser = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredUsersList(usersList);
        }
        const fArray = usersList.filter((obj) => (obj.login_name + obj.emp_id).includes(value));
        setFilteredUsersList(fArray);
    };

    // Search for accessUser 
    const handleUserAccess = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredAccessUsersList(accessUsersList);
        }
        const fArray = accessUsersList.filter((obj) => (obj.login_name + obj.emp_id).includes(value));
        setFilteredAccessUsersList(fArray);
    };



    const handleDateChange = (date) => {
        setSelectedStartDate(startOfWeek(date, { weekStartsOn: 1 }));
        setSelectedEndDate(endOfWeek(date, { weekStartsOn: 1 }));
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };



    const navigate = useNavigate();



    const handleGoBack = () => {
        navigate('/permissions')
    }

    const handlePermission = () => {
        setConfirmModal(true);
        setSave(true);
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <div className="w-50">
                    <h1 className="h1 mt-3 mb-3 fw-normal text-center font-weight-bold">Provide Permissions</h1>
                    <br />
                    <div className="d-flex gap-4 justify-content-between">
                        <label className="d-flex flex-column">
                            Start Date
                            <DatePicker
                                selected={selectedStartDate}
                                useWeekdaysShort
                                onChange={handleDateChange}
                                startDate={start}
                                endDate={end}
                                selectsStart
                                dateFormat="dd-MM-yyyy"
                                // showWeekNumbers
                                // showIcon
                                maxDate={maxSelectableDate}
                            />
                        </label>
                        <label className="d-flex flex-column">
                            End Date
                            <DatePicker
                                selected={selectedEndDate}
                                useWeekdaysShort
                                onChange={handleEndDateChange}
                                endDate={end}
                                selectsStart
                                dateFormat="dd-MM-yyyy"
                                // showWeekNumbers
                                // showIcon
                                disabled
                            />
                        </label>
                    </div>
                    <br />
                    <div className="d-flex flex-column gap-3" style={{ margin: 'auto', maxHeight: '46vh', minHeight: '46vh' }}>
                        <div className="d-flex flex-row justify-content-between gap-4" >
                            <input type="text" className="form-control" name="userSearch" style={{ width: '40%' }} onChange={handleUser} placeholder="name" required />
                            <input type="text" className="form-control" name="userAccessSearch" style={{ width: '40%' }} onChange={handleUserAccess} placeholder="name" required />
                        </div>
                        <div className="d-flex flex-row justify-content-between gap-2" style={{ maxHeight: '40vh', minHeight: '40vh' }} >
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '40%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {filteredUsersList.length > 0 ? filteredUsersList.map((user) => (
                                        <li key={user.hcode} onClick={() => { handleUserClick(user) }}
                                            className={`list-group-item ${highlightUser.includes(user.hcode) ? `${styleInput['highlight']}` : ''}`}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {user.login_name + ' (' + user.emp_id + ')'}
                                        </li>
                                    )) : <p>No matching records found. </p>}
                                </ul>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '10%' }}>
                                <button className={`btn-secondary rounded btn my-4`} onClick={() => handleAddUserClick(selectedUser)}> {`>>`} </button>
                                <button className={`btn ${styles['btn-style']} rounded my-4`} onClick={() => handleRemoveUserClick(selectedAccessUser)}> {`<<`} </button>
                            </div>
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '40%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {
                                        filteredAccessUsersList.length > 0 ? (
                                            <ul className="list-group">
                                                {
                                                    filteredAccessUsersList.map((accessUser) => (
                                                        <li
                                                            key={accessUser.hcode}
                                                            className={`list-group-item ${highlightAccessUser.includes(accessUser.hcode) ? `${styleInput['highlight']}` : ''}`}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => { handleAccessUserClick(accessUser) }}
                                                        >
                                                            {accessUser.login_name + ' (' + accessUser.emp_id + ')'}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        ) : (
                                            <p>{filteredAccessUsersList.length > 0 ? 'No matching records found.' : 'Employees yet to be added.'}</p>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="mt-3 d-flex justify-content-between gap-4">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} onClick={handlePermission}>Provide Permission</button>
                    </div>
                    <br />
                </div>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};

export default AddPermissionForm;