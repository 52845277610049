import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import 'datatables.net-bs5';
import $ from 'jquery';
import UserAccessModal from "../Modal/UserAccessModal";
import ConfirmModal from "../Modal/ConfirmModal";
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const convertToIndianFormat = (utcDateTimeString) => {
    // If input is null, return an empty string or handle it based on your requirements
    if (!utcDateTimeString) {
        return '';
    }

    // Define the target time zone (India Standard Time)
    const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Parse the UTC date string to a Date object
    const utcDate = new Date(utcDateTimeString);

    // Convert UTC to Indian time zone
    const indianDate = utcToZonedTime(utcDate, targetTimeZone);

    // Format the date and time according to 'dd-MM-yyyy hh:mm:ss a'
    const formattedDateTime = format(indianDate, 'dd-MM-yyyy hh:mm:ss a', { timeZone: targetTimeZone });

    return formattedDateTime;
};

const EmployeeDetails = () => {
    const { employeeHCode } = useParams();

    const [user, setUser] = useState('');
    const [projects, setProjects] = useState([]);

    const [projectsAccess, setProjectsAccess] = useState([]);

    const [provideAccess, setProvideAccess] = useState([]);
    const [removeAccess, setRemoveAccess] = useState([]);
    const [navbarAccess, setNavBarAccess] = useState([])
    const [selectedNavbaroptions, setSelectedNavBaroptions] = useState();    
    const [confirmModal, setConfirmModal] = useState(false) 
    const [navbarChange, setNavbarChange] = useState(false)


    const [save, setSave] = useState(false);
    
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [change, setChange] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const tableRef = useRef(null);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleProvideAccess = async (data) => {
        const response = await fetch(`${SERVER_URL}/access/user/projects`, {
            method: 'POST',
            credentials:"include",
            mode: 'cors',
            body: JSON.stringify({
                projects: data.projects,
                employee_hcode: employeeHCode,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(response);
        return response;
    };

    const handleRemoveAccess = async (project) => {
         const response = await fetch(`${SERVER_URL}/access/removeAccess/${project.hcode}/${employeeHCode}`, {
            method: 'DELETE',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(response);
    };

    const handleremoveNavbarAccess =(navBarAccess) =>{
        setSelectedNavBaroptions(navBarAccess)
        setConfirmModal(true);
        setSave(true)

    }
    
    const handleAssignProjects = () => {
        setShowAccessModal(true);
    };

    const closeModal = () => {
        setShowAccessModal(false);
    };

    useEffect(() => {
        if (provideAccess.length > 0) {
            const data = {
                projects: provideAccess,
            }
            handleProvideAccess(data);
        }
        if (removeAccess.length > 0) {
            for (const project of removeAccess) {
                handleRemoveAccess(project);
            }
        }
    }, [provideAccess, removeAccess]);

    useEffect(() => {
        fetch(`${SERVER_URL}/user/details/${employeeHCode}`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setUser(data.user[0]);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            }
            )
            .catch(error => console.error(error));
            

            //fetch user access of navbar
            fetch(`${SERVER_URL}/navbar/options/access/${employeeHCode}`,{
                method:"GET",
                credentials:"include"
            })
                .then(response => response.json())
                .then(data => {
                    if (data.statusCode === 200) {
                        setNavBarAccess(data.navbarAccess);
                    }
                    if(data.statusCode === 401){
                        LogoutIfNoHcode(dispatch, navigate)
                    }
                }
                )
                .catch(error => console.error(error));

    }, []);

    //fetch navbar options after deleting
    useEffect(()=>{
        fetch(`${SERVER_URL}/navbar/options/access/${employeeHCode}`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setNavBarAccess(data.navbarAccess);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            }
            )
            .catch(error => console.error(error));

    },[navbarChange])
      
    //user details change
    useEffect(()=>{
        fetch(`${SERVER_URL}/user/details/${employeeHCode}`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setUser(data.user[0]);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            }
            )
            .catch(error => console.error(error));
    },[change])


    const confirm = (hcode) =>{

         fetch(`${SERVER_URL}/navbar/options/access/remove/${hcode}`,{
            method:"DELETE",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setNavbarChange(prev => !prev)
            }
            )
            .catch(error => console.error(error))
         
    }


    useEffect(() => {
        $(tableRef.current).DataTable().destroy();

        fetch(`${SERVER_URL}/user/projects/${employeeHCode}`,{
            method:"GET",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setProjects(data.projects);
                    setProjectsAccess(data.projects);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });



    }, [change]);

    useEffect(() => {
        if (projects.length > 0) {
            $(tableRef.current).DataTable({
                destroy: true,
                searching: true,
                lengthChange: false,
                paging: false,
            });
        }
    }, [projects]);


    const handleExportData = async () => {
        const fileURL = await fetch(`${SERVER_URL}/user/report/${employeeHCode}`,{
            method:"GET",
            credentials:"include"
        });
        const file = await fileURL.blob();
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = `EmployeeReport.xlsx`;
        link.click();
    };
    
    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };


    return (
        <>
            <div className="d-flex gap-3 px-3" style={{ minHeight: '79vh' }}>
                <div className="w-50">
                    <div className="card p-3" style={{ height: '75vh', overflow: "auto" }}>
                        <div className="card-title text-center">
                            <h2>Employee Details</h2>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Employee Name:</div>
                                <div className="col-8">{user.employee_name}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Employee ID:</div>
                                <div className="col-8">{user.emp_id}</div>
                            </div>
                            
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Navbar Options:</div>
                                <div className="col-8 d-flex gap-2 flex-wrap align-items-center">
                                    {navbarAccess && navbarAccess.map((options) => (
                                        <div key={options.hcode} className="d-flex gap-2 px-2 py-1 bg-primary" style={{ height: '33px', borderRadius: '16px' }}>
                                           <h6 style={{ color: 'White'}}>{options.navbar_module_name}</h6> 
                                            <p className="text-dark fw-bold" onClick={()=>handleremoveNavbarAccess(options.hcode)} style={{ cursor: 'pointer' }}>x</p>
                                        </div>
                                    ))}
                                    {/* <a style={{ cursor: 'pointer' }}><img src={process.env.PUBLIC_URL + '/images/plus.png'} alt="Add Discipline" height='20px' width='20px' /></a> */}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Email ID:</div>
                                <div className="col-8">{user.email_id}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Designation:</div>
                                <div className="col-8">{user.designation_name}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Discipline:</div>
                                <div className="col-8">{user.discipline_name}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Reporting Manager:</div>
                                <div className="col-8">{user.reporting_manager}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Created On:</div>
                                <div className="col-8">{convertToIndianFormat(user.created_on)}</div>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-secondary" onClick={() => handleExportData(user)}>Export Data</button>
                        </div>
                    </div>
                </div>
                <div className="w-50">
                    <div className="card p-3" style={{ height: '75vh' }}>
                        <div className="card-title text-center">
                            <h2>Assigned Projects</h2>
                        </div>
                        <div className="card-body" style={{ maxHeight: '63vh', overflow: 'auto' }}>
                            {projects.length > 0 ? (
                                <table id="table" className={`table table-bordered table-hover table-striped table-sm`} ref={tableRef} >
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Project ID</th>
                                            <th>Name</th>
                                            {/* <th>Discipline</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projects.map((project) => (
                                            <tr key={project.hcode}>
                                                <td>{project.project_id}</td>
                                                <td>{project.project_name}</td>
                                                {/* <td>{project.discipline_name}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center mt-3">No projects assigned yet.</p>
                            )}
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-secondary" onClick={() => handleAssignProjects(user)}>Assign Projects</button>
                        </div>
                        {showAccessModal && (
                            <UserAccessModal employee_name={user.employee_name} showAccessModal={showAccessModal} closeModal={closeModal} projectsAccess={projectsAccess} setProjectsAccess={setProjectsAccess} setProvideAccess={setProvideAccess} setRemoveAccess={setRemoveAccess} />
                        )}
                        {
                            save && (<ConfirmModal
                                    closeConfirmModal={closeConfirmModal}
                                    confirmModal={confirmModal}
                                    confirm={confirm}
                                    obj={selectedNavbaroptions}
                                    msg={msg}
                            />)
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeDetails;
