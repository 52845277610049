import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import Select from 'react-select';
import ConfirmModal from './ConfirmModal';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, disciplineHCode) => {
    const response = await fetch(`${SERVER_URL}/discipline/update/${disciplineHCode}`, {
        method: 'PUT',
        credentials:"include",
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const DisciplineModal = ({ discipline, showModal, closeModal, change }) => {
    const disciplineHCode = discipline.hcode;
    const [disciplineData, setDisciplineData] = useState(discipline);
    const [managersList, setManagersList] = useState([]);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(disciplineData, disciplineHCode);
        const data = await response.json();
        closeModal();
        change(prev => !prev);
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    const fetchManagers = async () => {
        // Fetch users from the server here
        fetch(`${SERVER_URL}/user`,{
            method:"GET",
            credentials:"include",
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.users.map((user) => ({
                    value: user.employee_name,
                    label: user.employee_name + ' (' + user.emp_id + ')',
                }));
                setManagersList(result);
            })
            .catch(error => console.error(error));
    };

    useEffect(() => {
        setDisciplineData(discipline);
        fetchManagers();
    }, [discipline]);


    const handleSelectChange = (selectedOption, fieldName) => {
        setDisciplineData((prevState) => ({
            ...prevState,
            [fieldName]: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDisciplineData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit Discipline</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        {/* <Form.Group controlId="discipline_code">
                            <Form.Label>Discipline Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="discipline_code"
                                value={disciplineData.discipline_code}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group> */}
                        <Form.Group controlId="discipline_name">
                            <Form.Label>Discipline Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="discipline_name"
                                value={disciplineData.discipline_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        {/* <Form.Group controlId="timesheet_code">
                            <Form.Label>Timesheet Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="timesheet_code"
                                value={disciplineData.timesheet_code}
                                onChange={handleInputChange}
                            />
                        </Form.Group> */}
                        <Form.Group controlId="manager_name">
                            <Form.Label>Discipline Manager</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="manager_name"
                                options={managersList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'manager_name')}
                                value={
                                    disciplineData.manager_name
                                        ? { value: disciplineData.manager_name, label: disciplineData.manager_name }
                                        : null
                                }
                                placeholder="Select Discipline Manager"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                                isClearable
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};


export default DisciplineModal;