import TableRow from "./tablerowNew";
import TableRowDates from "./tablerowDates";
import React, { useState, useEffect, useRef } from "react";
import { startOfWeek, endOfWeek, eachDayOfInterval, format, addDays, isWithinInterval, getISOWeek, getISOWeekYear, isSameDay } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApprovalStatusModal from "../Modal/ApprovalStatusModal";
import DownloadReportModal from "../Modal/DownloadReportModal";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const tableStyle = {
    border: "1px solid",
    width: "max-content",
    style: 'center',
    margin: 'auto',
    textAlign: "center",
};

const thStyle = {
    color: "#000000",
    border: "1px solid black",
    textAlign: "center",
    height: "62px",
    backgroundColor: '#e0e0e0'
};

const thDocStyle = {
    color: "#000000",
    border: "1px solid black",
    textAlign: "center",
    width: "max-content",
    height: "62px",
    backgroundColor: '#e0e0e0'
};

const tdTextStyle = {
    border: "1px solid black",
    textAlign: "center",
    width: "max-content",
    height: "33px",
    fontWeight: 'bold',
    backgroundColor: '#e0e0e0'
}

const tdStyle = {
    border: "1px solid black",
    textAlign: "center",
    width: "65px",
    height: "33px",
};

function isDateInCurrentWeek(dateToCheck) {
    const currentDate = new Date();
    const startOfCurrentWeek = startOfWeek(currentDate);
    const endOfCurrentWeek = endOfWeek(currentDate);
    return isWithinInterval(dateToCheck, { start: startOfCurrentWeek, end: endOfCurrentWeek });
}

function getNextDate(today, daysToAdd, dateType) {
    // Parse the input date (today) to make sure it's in the Date format
    const parsedToday = new Date(today);

    // Add the specified number of days to the current date
    const nextDate = addDays(parsedToday, daysToAdd);

    // Format the date as "yyyy-MM-dd"
    if (dateType) {
        return nextDate
    }
    const formattedDate = format(nextDate, 'yyyy-MM-dd');

    return formattedDate;
}

function convertTimeToMinutes(timeString) {
    if (timeString === '') {
        return 0;
    }
    const [hours, minutes] = timeString.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
}

function isInCurrentWeek(selectedDate) {
    const today = new Date();
    const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
    const endOfCurrentWeek = endOfWeek(today, { weekStartsOn: 1 });

    const currentWeekDates = eachDayOfInterval({ start: startOfCurrentWeek, end: endOfCurrentWeek });

    return currentWeekDates.some(date => isSameDay(date, selectedDate));
}




const EditableTable = () => {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dataSaved, setDataSaved] = useState(false);
    const [nextWeek, setNextWeek] = useState(true);
    const [advanceView, setAdvanceView] = useState(false);
    const [advanceViewRequired, setAdvanceViewRequired] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [checkHoliday, setCheckHoliday] = useState(false)
    const [statusModal, setStatusModal] = useState(false);
    const [statusData, setStatusData] = useState('');
    const [change, setChange] = useState(false);
    const maxSelectableDate = new Date();
    maxSelectableDate.setDate(maxSelectableDate.getDate() + 1);

    const [downloadReportModal, setDownloadReportModal] = useState(false);
    const [permission, setPermission] = useState(false);
    const [submitDay, setSubmitDay] = useState(false);

    const iconRef = useRef(null);

    const closeStatusModal = () => {
        setStatusModal(false);
    }

    const closeDownloadReportModal = () => {
        setDownloadReportModal(false);
    }

    const [projects, setProjects] = useState([
        {
            projectID: "",
            projectName: '',
            task: '',
            discipline: '',
            software: '',
            document_id: '',
            remarks: '',
            network: '',
            hours: [
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }
            ],
            phcode: '',
            thcode: '',
            shcode: '',
            nhcode: ''
        },
    ]);


    const handleProjectNameChange = (index, projectID, projectName) => {
        const updatedProjects = [...projects];
        updatedProjects[index].projectID = projectID;
        updatedProjects[index].projectName = projectName;
        setProjects(updatedProjects);
    };

    const handleTaskChange = (index, taskName) => {
        const updatedProjects = [...projects];
        updatedProjects[index].task = taskName;
        setProjects(updatedProjects);
    };

    const handleNetworkChange = (index, networkName) => {
        const updatedProjects = [...projects];
        updatedProjects[index].network = networkName;
        setProjects(updatedProjects);
    };

    const handleDisciplineChange = (index, disciplineName) => {
        const updatedProjects = [...projects];
        updatedProjects[index].discipline = disciplineName;
        setProjects(updatedProjects);
    };

    const handleSoftwareChange = (index, softwareName) => {
        const updatedProjects = [...projects];
        updatedProjects[index].software = softwareName;
        setProjects(updatedProjects);
    };

    const handleDocumentIDChange = (index, document_id) => {
        const updatedProjects = [...projects];
        updatedProjects[index].document_id = document_id;
        setProjects(updatedProjects);
    };

    const handleRemarksChange = (index, remarks) => {
        const updatedProjects = [...projects];
        updatedProjects[index].remarks = remarks;
        setProjects(updatedProjects);
    };

    const handleHourChange = (projectIndex, dayIndex, hour) => {
        const updatedProjects = [...projects];
        updatedProjects[projectIndex].hours[dayIndex]['hours'] = hour;
        setProjects(updatedProjects);
    };


    const handleOverTimeHourChange = (projectIndex, dayIndex, hour) => {
        const updatedProjects = [...projects];
        updatedProjects[projectIndex].hours[dayIndex]['ot_hours'] = hour;
        setProjects(updatedProjects);
    };


    const handleProjectHcodeChange = (index, phcode) => {
        const updatedProjects = [...projects];
        updatedProjects[index].phcode = phcode;
        setProjects(updatedProjects);
    };

    const handleSoftwareHcodeChange = (index, shcode) => {
        const updatedProjects = [...projects];
        updatedProjects[index].shcode = shcode;
        setProjects(updatedProjects);
    }

    const handleTaskHcodeChange = (index, thcode) => {
        const updatedProjects = [...projects];
        updatedProjects[index].thcode = thcode;
        setProjects(updatedProjects);
    };

    const handleNetworkHcodeChange = (index, nhcode) => {
        const updatedProjects = [...projects];
        updatedProjects[index].nhcode = nhcode;
        setProjects(updatedProjects);
    };

    const getDayIndex = (dateString) => {
        const date = new Date(dateString);
        const dayIndex = format(date, 'i'); // 'i' returns the day of the week index (0 for Sunday, 1 for Monday, etc.)

        return dayIndex === '0' ? 6 : parseInt(dayIndex) - 1;
    };

    const startDate = (date) => {
        const curDate = startOfWeek(date);
        const formDate = getNextDate(curDate, 1);
        return new Date(formDate).toLocaleDateString('en-GB');
    };

    const endDate = (date) => {
        const curDate = endOfWeek(date);
        const formDate = getNextDate(curDate, 1);
        return new Date(formDate).toLocaleDateString('en-GB');
    };

    const dateInReqForm = (date) => {
        const formDate = format(date, 'yyyy-MM-dd');
        return new Date(formDate).toLocaleDateString('en-GB');
    };


    const fetchSubmitDay = async (selectedDate) => {
        try {
            const response = await fetch(`${SERVER_URL}/workhours/submitDay?date=${selectedDate}`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            }); // Assuming the API is available at /submitDay
            const data = await response.json();
            setSubmitDay(data.result);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const fetchPermissionToSubmit = async (selectedDate) => {
        try {
            const response = await fetch(`${SERVER_URL}/workhours/permissionToSubmit?date=${selectedDate}`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            }); // Assuming the API is available at /submitDay
            const data = await response.json();
            setPermission(data.result);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setAdvanceView(false)
            try {
                const response = await fetch(`${SERVER_URL}/workhours/date/${start}`, {
                    method: 'GET',
                    credentials: "include",
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const projectData = await response.json();
                const result = []

                if (projectData.statusCode == 200) {
                    projectData.workhours.forEach(({ project_id, project_name, date, task_name, document_id, remarks, nt_minutes_worked, ot_minutes_worked, discipline_name, software_name, shcode, thcode, phcode, hcode, network_id, nhcode, is_submitted, status }) => {
                        const dayIndex = getDayIndex(date);
                        if (ot_minutes_worked > 0) {
                            setAdvanceViewRequired(true)
                            setAdvanceView(true)
                        }
                        const existingProjects = result.reduce((acc, item) => {
                            if (item.projectID === project_id && item.task === task_name && item.network === network_id && item.discipline === discipline_name && item.remarks === remarks) {
                                acc.push(item);
                            }
                            return acc;
                        }, []);

                        if (existingProjects.length !== 0) {
                            let used = false;
                            for (const existingProject of existingProjects) {
                                if (existingProject.hours[dayIndex].hours === "" &&
                                    existingProject.hours[dayIndex].hcode === "" &&
                                    existingProject.hours[dayIndex].ot_hours === "") {
                                    existingProject.hours[dayIndex] = { hours: `${String(Math.floor(nt_minutes_worked / 60)).padStart(2, "0")}:${String(nt_minutes_worked % 60).padStart(2, "0")}`, hcode: hcode, ot_hours: `${String(Math.floor(ot_minutes_worked / 60)).padStart(2, "0")}:${String(ot_minutes_worked % 60).padStart(2, "0")}` };
                                    existingProject.hours[dayIndex].submitted = is_submitted;
                                    existingProject.hours[dayIndex].status = status;
                                    used = true;
                                    break;
                                }
                            }
                            if (!used) {
                                const newProject = {
                                    projectID: project_id,
                                    projectName: project_name,
                                    task: task_name,
                                    discipline: discipline_name,
                                    software: software_name,
                                    document_id: document_id ? document_id : '',
                                    remarks: remarks ? remarks : '',
                                    network: network_id,
                                    hours: [{ hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }],
                                    phcode: phcode,
                                    thcode: thcode,
                                    shcode: shcode,
                                    nhcode: nhcode,
                                    pahcode: phcode,
                                    tahcode: thcode,
                                    nahcode: nhcode,
                                    is_submitted: is_submitted,
                                    status: status
                                };
                                newProject.hours[dayIndex] = { hours: `${String(Math.floor(nt_minutes_worked / 60)).padStart(2, "0")}:${String(nt_minutes_worked % 60).padStart(2, "0")}`, hcode: hcode, ot_hours: `${String(Math.floor(ot_minutes_worked / 60)).padStart(2, "0")}:${String(ot_minutes_worked % 60).padStart(2, "0")}`, submitted: is_submitted, status: status };
                                result.push(newProject);
                            }
                        } else {
                            const newProject = {
                                projectID: project_id,
                                projectName: project_name,
                                task: task_name,
                                discipline: discipline_name,
                                software: software_name,
                                document_id: document_id ? document_id : '',
                                remarks: remarks ? remarks : '',
                                network: network_id,
                                hours: [{ hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }],
                                phcode: phcode,
                                thcode: thcode,
                                shcode: shcode,
                                nhcode: nhcode,
                                pahcode: phcode,
                                tahcode: thcode,
                                nahcode: nhcode,
                                is_submitted: is_submitted,
                                status: status
                            };
                            newProject.hours[dayIndex] = { hours: `${String(Math.floor(nt_minutes_worked / 60)).padStart(2, "0")}:${String(nt_minutes_worked % 60).padStart(2, "0")}`, hcode: hcode, ot_hours: `${String(Math.floor(ot_minutes_worked / 60)).padStart(2, "0")}:${String(ot_minutes_worked % 60).padStart(2, "0")}`, submitted: is_submitted, status: status };
                            result.push(newProject);
                        }
                    });
                    setProjects(result);
                } else {
                    setProjects([
                        {
                            projectID: "",
                            projectName: '',
                            task: '',
                            network: '',
                            software: '',
                            document_id: '',
                            remarks: '',
                            discipline: '',
                            hours: [{ hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }, { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }],
                        },
                    ])
                    setCheckHoliday(prev => !prev)
                }

            } catch (error) {
                console.error("Error fetching and processing data:", error);
            }
        };

        fetchData();

    }, [selectedDate, dataSaved]);

    useEffect(() => {
        setAdvanceViewRequired(false)
        setCheckHoliday(false)
        fetchSubmitDay(selectedDate);
        fetchPermissionToSubmit(selectedDate);
    }, [selectedDate])

    useEffect(() => {
        // fetch holidays list
        const fetchData = async () => {
            let holidayList;
            const holidayListApi = await fetch(`${SERVER_URL}/holiday/check/${start}`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const holidayData = await holidayListApi.json();
            if (holidayData.statusCode === 200 && holidayData.holidays.length > 0) {
                holidayList = holidayData.holidays;
            } else {
                return;
            }
            if (projects.find((project) => project.task === 'Holiday')) {
                return;
            }

            const newProject = {
                projectID: "",
                projectName: '',
                network: '',
                nhcode: '',
                phcode: '',
                thcode: '',
                shcode: '',
                task: '',
                discipline: '',
                software: '',
                document_id: '',
                remarks: '',
                hours: [
                    { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                    { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                    { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                    { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                    { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                    { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                    { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }
                ],
            };
            try {
                // select project from list of project
                const projectListApi = await fetch(`${SERVER_URL}/workhours/projects`, {
                    method: 'GET',
                    credentials: "include",
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const projectlist = await projectListApi.json();
                if (projectlist.statusCode === 200) {
                    const pattern = /General/i;
                    const matchedProject = projectlist.projects.filter(project => pattern.test(project.project_name))[0];
                    if (matchedProject) {
                        newProject.projectID = matchedProject.project_id;
                        newProject.projectName = matchedProject.project_name;
                        newProject.phcode = matchedProject.hcode;
                    } else {
                        return;
                    }
                }

                // select task from project task list
                const taskListApi = await fetch(`${SERVER_URL}/workhours/tasks/${newProject.phcode}`, {
                    method: 'GET',
                    credentials: "include",
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const tasklist = await taskListApi.json();

                if (tasklist.statusCode === 200) {
                    const task = tasklist.tasks.find(task => task.task_name === 'Holiday');
                    if (task) {
                        newProject.task = task.task_name;
                        newProject.thcode = task.hcode;
                    } else {
                        return;
                    }
                }

                // select network from project network list
                const networkListApi = await fetch(`${SERVER_URL}/network/ids/${newProject.phcode}`, {
                    method: 'GET',
                    credentials: "include",
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const networkList = await networkListApi.json();
                if (networkList.statusCode === 200) {
                    newProject.network = networkList.networks[0].network_id;
                    newProject.nhcode = networkList.networks[0].hcode;
                }

                // select software from task software list
                const softwareListApi = await fetch(`${SERVER_URL}/workhours/software/${newProject.thcode}`, {
                    method: 'GET',
                    credentials: "include",
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const softwareList = await softwareListApi.json();
                if (softwareList.statusCode = 200) {
                    newProject.software = softwareList.software[0].software_name;
                    newProject.shcode = softwareList.software[0].hcode;
                }

                holidayList.forEach((holiday) => {
                    const index = getDayIndex(holiday.date);
                    if (index < 5) {
                        newProject.hours[index].hours = '09:00';
                    }
                })

                setProjects([...projects, newProject]);


            } catch (error) {
                console.error("Error fetching and processing data:", error);
            }
        };
        if (checkHoliday) {
            fetchData();
        }
    }, [checkHoliday])


    const toggleDatePicker = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        setSelectedDate(new Date());
    }, []);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setIsOpen(false);

    };
    const start = startOfWeek(selectedDate, { weekStartsOn: 1 });
    const end = endOfWeek(selectedDate, { weekStartsOn: 1 });
    const dates = eachDayOfInterval({ start, end });



    const handleAddProject = () => {
        const newProject = {
            projectID: "",
            projectName: '',
            network: '',
            nhcode: '',
            phcode: '',
            thcode: '',
            shcode: '',
            task: '',
            discipline: '',
            software: '',
            document_id: '',
            remarks: '',
            hours: [
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }
            ],
        };
        setProjects([newProject, ...projects]);
    };


    const handleDeleteProject = async (projectIndex) => {

        const deleteProject = await fetch(`${SERVER_URL}/workhours/delete`, {
            method: 'DELETE',
            credentials: "include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ workhours: projects[projectIndex].hours })
        });
        const deleteProjectResult = await deleteProject.json();
        setSelectedDate(new Date());
    };

    const handleDuplicateProject = (project) => {
        const newProject = {
            projectID: project.projectID,
            projectName: project.projectName,
            task: project.task,
            discipline: project.discipline,
            software: project.software,
            document_id: project.document_id,
            network: project.network,
            remarks: project.remarks,
            phcode: project.phcode,
            thcode: project.thcode,
            shcode: project.shcode,
            nhcode: project.nhcode,
            pahcode: project.phcode,
            tahcode: project.thcode,
            nahcode: project.nhcode,
            hours: [
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null },
                { hours: '', hcode: '', ot_hours: '', submitted: null, status: null }
            ],
        };


        setProjects((prevProjects) => {

            const updatedProjects = [...prevProjects, newProject];
            return updatedProjects;
        });
    }


    const dayTotal = (day) => {
        let total = 0;

        projects.forEach((project) => {
            const { hours } = project;

            hours.forEach((hoursObj, hourIndex) => {
                if (hoursObj['hours'] !== '' && hourIndex === day) {
                    const decimalHour = calculateDecimalHours(hoursObj['hours']);
                    const overtimedecimalHour = calculateDecimalHours(hoursObj['ot_hours']);
                    total += decimalHour + overtimedecimalHour;
                } else if (hoursObj['ot_hours'] !== '' && hourIndex === day) {
                    const decimalHour = calculateDecimalHours(hoursObj['hours']);
                    const overtimedecimalHour = calculateDecimalHours(hoursObj['ot_hours']);
                    total += decimalHour + overtimedecimalHour;
                }
            });
        });
        return formatToHHMM(total);
    };

    const CheckEachDayTotalNeedToLessThanTwoFourHours = () => {
        const checkEachDay = [];
        for (let day = 0; day < 5; day++) {
            let total = 0;

            projects.forEach((project) => {
                const { hours } = project;

                hours.forEach((hoursObj, hourIndex) => {
                    if (hoursObj['hours'] !== '' && hourIndex === day) {
                        const decimalHour = calculateDecimalHours(hoursObj['hours']);
                        const overtimedecimalHour = calculateDecimalHours(hoursObj['ot_hours']);
                        total += decimalHour + overtimedecimalHour;
                    }
                });
            });

            checkEachDay.push(total < 1440)
        };
        return checkEachDay.every(day => day === true)
    }

    const CheckEachDayTotal = () => {
        const checkEachDay = [];
        for (let day = 0; day < 5; day++) {
            let total = 0;

            projects.forEach((project) => {
                const { hours } = project;

                hours.forEach((hoursObj, hourIndex) => {
                    if (hoursObj['hours'] !== '' && hourIndex === day) {
                        const decimalHour = calculateDecimalHours(hoursObj['hours']);
                        const overtimedecimalHour = calculateDecimalHours(hoursObj['ot_hours']);
                        total += decimalHour + overtimedecimalHour;
                    }
                });
            });

            checkEachDay.push(total >= 540)
        };
        return checkEachDay.every(day => day === true)
    }

    const CheckIfEachDayTotalGreaterThanNine = () => {
        const checkEachDay = [];
        for (let day = 0; day < 5; day++) {
            let total = 0;

            projects.forEach((project) => {
                const { hours } = project;

                hours.forEach((hoursObj, hourIndex) => {
                    if (hoursObj['hours'] !== '' && hourIndex === day) {
                        const decimalHour = calculateDecimalHours(hoursObj['hours']);
                        total += decimalHour;
                    }
                });
            });

            checkEachDay.push(total > 540)
        };
        return checkEachDay.every(day => day === false)
    }

    const CheckOtIsonlyFilledAfterNT = () => {
        for (let day = 0; day < 5; day++) {
            let normalTime = 0;
            let overTime = 0;


            projects.forEach((project) => {
                const { hours } = project;

                hours.forEach((hoursObj, hourIndex) => {
                    if (hoursObj['hours'] !== '' && hourIndex === day) {
                        const decimalHour = calculateDecimalHours(hoursObj['hours']);
                        const overtimedecimalHour = calculateDecimalHours(hoursObj['ot_hours']);
                        normalTime += decimalHour;
                        overTime += overtimedecimalHour;
                    } else if (hoursObj['ot_hours'] !== '' && hourIndex === day) {
                        const overtimedecimalHour = calculateDecimalHours(hoursObj['ot_hours']);
                        overTime += overtimedecimalHour;
                    }
                });
            });

            if (overTime !== 0) {
                if (normalTime < 540) {
                    let date = getNextDate(start, day);
                    setStatusModal(true);
                    setStatusData({ statusCode: 404, msg: `Overtime Can be filled only when normal time is 9 hours (${format(new Date(date), 'EEEE')})` })
                    // alert(`Overtime Can be filled only when normal time is 9 hours (${format(new Date(date), 'EEEE')})`)
                    return false;
                }
            }
        };
        return true;
    }



    const checkWeekTotal = () => {
        let total = 0;
        projects.forEach((project) => {
            const { hours } = project;

            hours.forEach((hoursObj, hourIndex) => {
                const decimalHour = calculateDecimalHours(hoursObj['hours']);
                const overtimedecimalHour = calculateDecimalHours(hoursObj['ot_hours']);
                total += decimalHour + overtimedecimalHour;
            })
        });

        return total
    }


    const calculateDecimalHours = (hour) => {
        if (hour) {
            const [hours, minutes] = hour.split(":");
            const minutesWorked = parseInt(hours) * 60 + parseInt(minutes);
            return minutesWorked;
        }
        return 0;
    };

    const formatToHHMM = (minutesWorked) => {
        const hours = Math.floor(minutesWorked / 60);
        const minutes = minutesWorked % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    };

    const handleSaveData = () => {

        const filledCorrectly = CheckOtIsonlyFilledAfterNT();
        if (!filledCorrectly) {
            return;
        }

        if (!CheckEachDayTotalNeedToLessThanTwoFourHours()) {
            setStatusModal(true);
            setStatusData({ statusCode: 404, msg: `Total Workhours need to be less than 24 hours for each day` })
            // alert('Total Workhours need to be less than 24 hours for each day')
            return;
        }

        const CheckIfEachDayGreaterThanNine = CheckIfEachDayTotalGreaterThanNine();
        if (!CheckIfEachDayGreaterThanNine) {
            setStatusModal(true);
            setStatusData({ statusCode: 400, msg: `Normal hours greater than 9 hours` })
            // alert('Normal hours greater than 9 hours')
        }

        const convertedData = [];

        projects.forEach((project) => {
            const { projectID, hours, task, software, document_id, remarks, discipline, phcode, thcode, network, nhcode } = project;

            hours.forEach((hoursObj, hourIndex) => {
                if (hoursObj['hours'] !== '' || hoursObj['ot_hours'] !== '') {
                    let date = getNextDate(start, hourIndex);
                    const minutes = convertTimeToMinutes(hoursObj['hours'])
                    const ot_minutes = convertTimeToMinutes(hoursObj['ot_hours'])

                    convertedData.push({
                        project_id: projectID,
                        task_name: task,
                        softwares: software,
                        document_id: document_id,
                        remarks: remarks,
                        discipline: discipline,
                        network_id: network,
                        date: date,
                        nt_minutes_worked: minutes,
                        ot_minutes_worked: ot_minutes,
                        is_submitted: 0,
                        phcode: phcode,
                        thcode: thcode,
                        nhcode: nhcode,
                        hcode: hoursObj['hcode']
                    });
                }
            });
        });
        fetch(`${SERVER_URL}/workhours/add/${selectedDate}`, {

            method: 'POST',
            credentials: "include",
            mode: 'cors',
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify({ workhours: convertedData }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setDataSaved(prev => !prev)
                    setStatusModal(true);
                    setStatusData({ statusCode: 200, msg: `Timesheet have been saved` })
                    // alert('Timesheet have been saved')
                } else {
                    setStatusModal(true);
                    setStatusData({ statusCode: data.statusCode, msg: data.message })
                    // alert(data.message)
                }
            })
    };




    const handleSubmitData = () => {
        const filledCorrectly = CheckOtIsonlyFilledAfterNT();
        if (!filledCorrectly) {
            return;
        }

        if (!CheckEachDayTotalNeedToLessThanTwoFourHours()) {
            setStatusModal(true);
            setStatusData({ statusCode: 404, msg: `Total Workhours need to be less than 24 hours for each day` })
            // alert('Total Workhours need to be less than 24 hours for each day')
            return;
        }

        if (!CheckEachDayTotal()) {
            setStatusModal(true);
            setStatusData({ statusCode: 404, msg: `Total work hours for each day need to be atleast 9 hours` })
            // alert('Total work hours for each day need to be atleast 9 hours')
            return;
        }

        if (!(checkWeekTotal() >= 2700)) {
            setStatusModal(true);
            setStatusData({ statusCode: 404, msg: `Total work hours for week need to be atleast 45 hours` })
            // alert('Total work hours for week need to be atleast 45 hours')
            return;
        }

        const convertedData = [];


        projects.forEach((project) => {
            const { projectID, hours, task, software, document_id, remarks, discipline, phcode, thcode, network, nhcode } = project;

            hours.forEach((hoursObj, hourIndex) => {
                if (hoursObj['hours'] !== '') {
                    let date = getNextDate(start, hourIndex);
                    const minutes = convertTimeToMinutes(hoursObj['hours'])
                    const ot_minutes = convertTimeToMinutes(hoursObj['ot_hours'])
                    convertedData.push({
                        project_id: projectID,
                        task_name: task,
                        softwares: software,
                        document_id: document_id,
                        network_id: network,
                        remarks: remarks,
                        discipline: discipline,
                        date: date,
                        nt_minutes_worked: minutes,
                        ot_minutes_worked: ot_minutes,
                        is_submitted: 1,
                        phcode: phcode,
                        thcode: thcode,
                        nhcode: nhcode,
                        hcode: hoursObj['hcode']
                    });
                }
            });
        });


        if (convertedData.length < 5) {
            return;
        }

        fetch(`${SERVER_URL}/workhours/submit`, {

            method: 'POST',
            credentials: "include",
            mode: 'cors',
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify({ workhours: convertedData }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setDataSaved(prev => !prev)
                    setStatusModal(true)
                    setStatusData({ statusCode: 200, msg: data.message })

                } else {
                    setStatusModal(true)
                    setStatusData({ statusCode: data.statuCode, msg: data.message })
                }
            })
    };

    const handleDownloadReport = () => {
        setDownloadReportModal(true);
    };


    const renderTableHeader = () => {
        const headers = ["Project", 'Task', 'WBS No/Network ID', 'Inter Discipline', 'Software', 'Description'];
        return headers.map((header, index) => (
            <th key={index} style={thDocStyle}>
                {header}
            </th>
        ));
    };

    const renderTableHeaderDates = () => {
        const headers = [...dates.map((date) => format(date, "EE dd"))];
        return headers.map((header, index) => (
            <th key={index} style={thStyle}>
                {header}
            </th>
        ));
    };

    const renderTableRows = () => {
        return projects.map((project, projectIndex) => (
            <TableRow
                key={projectIndex}
                project={project}
                projectIndex={projectIndex}
                dates={dates}
                handleDocumentIDChange={handleDocumentIDChange}
                handleRemarksChange={handleRemarksChange}
                handleProjectNameChange={handleProjectNameChange}
                handleHourChange={handleHourChange}
                handleOverTimeHourChange={handleOverTimeHourChange}
                handleTaskChange={handleTaskChange}
                handleDisciplineChange={handleDisciplineChange}
                handleSoftwareChange={handleSoftwareChange}
                handleNetworkChange={handleNetworkChange}
                handleNetworkHcodeChange={handleNetworkHcodeChange}
                handleTaskHcodeChange={handleTaskHcodeChange}
                handleProjectHcodeChange={handleProjectHcodeChange}
                handleDuplicateProject={handleDuplicateProject}
                handleDeleteProject={handleDeleteProject}
                selectedDate={selectedDate}
                advanceView={advanceView}
                submitDay={submitDay}
                permissionToSubmit={permission}
            />
        ));
    };

    const renderTableRowsDates = () => {
        return projects.map((project, projectIndex) => (
            <TableRowDates
                key={projectIndex}
                project={project}
                projectIndex={projectIndex}
                dates={dates}
                handleDocumentIDChange={handleDocumentIDChange}
                handleRemarksChange={handleRemarksChange}
                handleProjectNameChange={handleProjectNameChange}
                handleHourChange={handleHourChange}
                handleOverTimeHourChange={handleOverTimeHourChange}
                handleTaskChange={handleTaskChange}
                handleDisciplineChange={handleDisciplineChange}
                handleSoftwareChange={handleSoftwareChange}
                handleNetworkChange={handleNetworkChange}
                handleNetworkHcodeChange={handleNetworkHcodeChange}
                handleTaskHcodeChange={handleTaskHcodeChange}
                handleProjectHcodeChange={handleProjectHcodeChange}
                handleDuplicateProject={handleDuplicateProject}
                handleDeleteProject={handleDeleteProject}
                selectedDate={selectedDate}
                advanceView={advanceView}
                submitDay={submitDay}
                permissionToSubmit={permission}
            />
        ));
    };

    const handleLastWeek = () => {
        const date = getNextDate(selectedDate, -7, true);
        setSelectedDate(date);
        const val = isDateInCurrentWeek(date);
        setNextWeek(val);
    }

    const handleNextWeek = () => {
        const date = getNextDate(selectedDate, 7, true);
        let val = isDateInCurrentWeek(selectedDate);
        setNextWeek(val);
        if (!val) {
            setSelectedDate(date);
        }
        val = isDateInCurrentWeek(date);
        setNextWeek(val);
    }

    useEffect(() => {
    }, [selectedDate])


    const calculatePosition = () => {
        if (iconRef.current) {
            const iconRect = iconRef.current.getBoundingClientRect();
            return {
                top: iconRect.bottom + window.scrollY + 'px',
                left: iconRect.left + window.scrollX + 'px',
            };
        }
        return {};
    };

    const datePickerPosition = calculatePosition();

    return (
        <div className="p-3 mb-5" style={{ minHeight: '79vh', minWidth: '100vw' }}>
            <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center border border-secondary rounded">
                    <button className="btn btn-success fw-bold" onClick={handleLastWeek}>{`<`}</button>
                    <div onClick={toggleDatePicker}>
                        <div style={{ display: 'inline' }} className="p-1" ref={iconRef} onClick={toggleDatePicker}>
                            <a style={{ cursor: 'pointer' }} ><img src={process.env.PUBLIC_URL + '/images/calendar.png'} alt="Edit" height='20px' width='20px' /></a>
                        </div>
                        <span className="ps-2"><span style={{ fontWeight: '500' }}>{format(start, 'dd/MM/yyyy')}</span> To <span style={{ fontWeight: '500', paddingRight: '5px' }}>{format(end, 'dd/MM/yyyy')}</span></span>
                    </div>
                    {isOpen &&
                        <div className="" style={{
                            position: 'absolute',
                            zIndex: '1000',
                            top: 'px', // Adjust the position from the icon
                            left: '0',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                            background: '#fff',
                            borderRadius: '4px',
                            padding: '10px',
                            ...datePickerPosition
                        }}> <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/dd/yyyy"
                                className="custom-datepicker"
                                withPortal
                                inline
                                maxDate={maxSelectableDate}
                            // open={isOpen}
                            /></div>
                    }
                    <button className="btn btn-success fw-bold" onClick={handleNextWeek} disabled={nextWeek}>{`>`}</button>
                </div>
                <br />
                <div className="d-flex flex-column justify-content-center align-items-center fs-5">
                    <strong>{` WK-${getISOWeek(selectedDate)} Of ${getISOWeekYear(selectedDate)}`} </strong>
                    {/* <span> {`${startDate(selectedDate)} to ${endDate(selectedDate)}`}</span> */}
                </div>
                <br />
            </div>
            <div className="d-flex justify-content-between mb-3">
                <div className="d-flex justify-content-start gap-2">
                    <button className="btn btn-warning" onClick={handleAddProject}>Add</button>
                </div>
                <div className="d-flex justify-content-end gap-2">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckCheckedDisabled" checked={advanceView} onChange={() => { setAdvanceView(prev => !prev) }} disabled={advanceViewRequired} style={{ cursor: 'pointer' }} />
                        <div className="d-flex gap-3">
                            <label className="form-check-label" for="flexSwitchCheckCheckedDisabled" style={{ cursor: 'pointer' }}>OT HOURS</label>
                            {/* <a style={{ cursor: 'pointer' }} onClick={handleDownloadReport}>
                                <img src={process.env.PUBLIC_URL + '/images/download_icon.png'} alt="Download" height='20px' width='20px' />
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mx-2 gap-1">
                <div style={{ maxWidth: '64vw' }}>
                    <div className="overflow-auto" style={{ overflowX: 'auto' }}>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    {renderTableHeader()}
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableRows()}
                                <tr>
                                    <td colSpan='6' style={tdTextStyle}>Total</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ maxWidth: '34vw' }}>
                    <div className="overflow-auto" style={{ overflowX: 'auto' }}>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    {renderTableHeaderDates()}
                                    <th style={thStyle}>Actions</th>
                                    <th style={thStyle}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableRowsDates()}
                                <tr>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{dayTotal(0)}</td>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{dayTotal(1)}</td>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{dayTotal(2)}</td>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{dayTotal(3)}</td>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{dayTotal(4)}</td>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{dayTotal(5)}</td>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{dayTotal(6)}</td>
                                    <td></td>
                                    <td style={{ height: '33px', fontWeight: "600" }}>{formatToHHMM(checkWeekTotal())}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end gap-2 mt-2">
                {/* {isInCurrentWeek(selectedDate) && <button className="btn btn-primary" onClick={handleSaveData}>Save</button>} */}
                <button className="btn btn-primary" onClick={handleSaveData}>Save</button>
                <button className="btn btn-success" onClick={handleSubmitData}>Submit</button>
            </div>
            {
                statusModal && (
                    <ApprovalStatusModal
                        statusModal={statusModal}
                        closeStatusModal={closeStatusModal}
                        data={statusData}
                        setChange={setChange}
                    />
                )
            }
            {
                downloadReportModal && (
                    <DownloadReportModal
                        reportModal={downloadReportModal}
                        closeModal={closeDownloadReportModal}
                        startDate={format(start, 'dd-MM-yyyy')}
                        endDate={format(end, 'dd-MM-yyyy')}
                    />
                )
            }
        </div>
    );
};

export default EditableTable;
