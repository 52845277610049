import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';
import styleInput from './style/projectAccessModal.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const SearchProjectModal = ({ list, showModal, closeModal, handleSelectedProject }) => {

    const [filteredList, setFilteredList] = useState(list);
    const [selectedObj, setSelectedObj] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredList(list);
        }
        const fArray = list.filter((obj) => {
            const pro = obj.project_id + ' - ' + obj.project_name;
            return pro.toLowerCase().includes(value.toLowerCase());
        });

        setFilteredList(fArray);
    };

    const handleObjClick = (obj) => {
        handleSelectedProject(obj);
        closeModal();
    }

    useEffect(() => {
        setFilteredList(list);
    }, [list])

    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Search</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <input type="text" onChange={handleInputChange} placeholder='search here ...' style={{ width: '100%' }} />
                    <br />
                    <br />
                    <div className="overflow-auto" style={{ maxHeight: '60vh' }}>
                        <ul className="list-group">
                            {filteredList.length > 0 ? filteredList.map((obj) => (
                                <li key={obj.hcode} onClick={() => { handleObjClick(obj) }}
                                    className={`list-group-item ${selectedObj.hcode === obj.hcode ? `${styleInput['highlight']}` : ''}`}
                                    style={{ backgroundColor: 'transparent', borderColor: 'blue', cursor: 'pointer' }}
                                >
                                    {obj.project_id + ' - ' + obj.project_name}
                                </li>
                            )) : <p>No matching records found. </p>}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};


export default SearchProjectModal;