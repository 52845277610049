import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ApprovalStatusModal = ({ statusModal, closeStatusModal, data, setChange }) => {

    const handleNo = () => {
        setChange(prev => !prev);
        closeStatusModal();
    }


    return (
        <Modal show={statusModal} onHide={closeStatusModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Timesheet</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <div className={"alert alert-dismissible fade show" + (data.statusCode === 200 ? ' alert-success' : data.statusCode === 400 ? '' : ' alert-danger')}>
                    <h6>{data.msg}</h6>
                </div>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={handleNo}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default ApprovalStatusModal;