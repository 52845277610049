import React, { useState, useEffect } from "react";
import ReactSelect, { components } from "react-select";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiSelect = ({ options, setSelectedOptions }) => {
    const [optionSelected, setOptionSelected] = useState([]);

    useEffect(() => {
        setSelectedOptions(optionSelected);
    }, [optionSelected]);

    const handleChange = (selected) => {
        setOptionSelected(selected);
    };

    return (
        <span
            className="d-inline-block"
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please select account(s)"
        >
            <ReactSelect
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        color: "#000000",
                    })
                }}
                options={options}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option
                }}
                onChange={handleChange}
                allowSelectAll={true}
                value={optionSelected}
                // isDisabled={isDisabled}
            />
        </span>
    );
};

export default MultiSelect;