import withAuth from "../../components/cookie/AuthenticatedComponent";  
import Layout from '../../components/layout/Layout';
import AddDisciplineForm from '../../components/dashpage/AddDisciplineForm';


function DashAddDiscipline() {
    return (
        <Layout>
            <AddDisciplineForm></AddDisciplineForm>
        </Layout>
    );
}

export default withAuth(DashAddDiscipline);