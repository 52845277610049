import React, { Fragment, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './pages/login-page/LoginPage';
import ForgotPasswordPage from './pages/forgot-pass-page/ForgotPasswordPage';
import SetPasswordPage from './pages/set-pass-page/SetPasswordPage';

import AppContext from './context/loginContext';

import DashUsers from './pages/dashPages/DashUsers';
import DashAddUser from './pages/dashPages/DashAddUser';

import DashProjects from './pages/dashPages/DashProjects';
import DashAddProject from './pages/dashPages/DashAddProject';

import DashClients from './pages/dashPages/DashClients';
import DashAddClient from './pages/dashPages/DashAddClient';

import DashTasks from './pages/dashPages/DashTasks';
import DashAddTask from './pages/dashPages/DashAddTask';

import DashSoftwares from './pages/dashPages/DashSoftwares';
import DashAddSoftware from './pages/dashPages/DashAddSoftware';

import DashDesignations from './pages/dashPages/DashDesignations';
import DashAddDesignation from './pages/dashPages/DashAddDesignation';

import DashDisciplines from './pages/dashPages/DashDisciplines';
import DashAddDiscipline from './pages/dashPages/DashAddDiscipline';
import DashDisciplineManage from './pages/dashPages/DashDisciplineManage'
import DashAddDisciplineAccess from './pages/dashPages/DashAddDisciplineAccess';

import DashApprovals from './pages/dashPages/DashApprovals';

import DashPermissions from './pages/dashPages/DashPermissions';
import DashAddPermission from './pages/dashPages/DashAddPermissions';

import DashAccess from './pages/dashPages/DashAccess';

import DashProjectTasks from './pages/dashPages/DashProjectTasks';

import DashProjectNetworkIDS from './pages/dashPages/DashProjectNetworkIDS';

import DashUserPage from './pages/dashPages/DashUserPage';
import DashReports from './pages/dashPages/DashReports';

import DashHoliday from './pages/dashPages/DashHoliday';
import DashAddHoliday from './pages/dashPages/DashAddHoliday';

import DashBusinessLine from './pages/dashPages/DashBusinessLine';
import DashAddBusinessLine from './pages/dashPages/DashAddBusinessLine';
import DashEmployeeDetails from './pages/dashPages/DashEmployeeDetails';
import DashProjectDetails from './pages/dashPages/DashProjectDetails';
import DashMobileUserPage from './pages/dashPages/DashMobileUserPage';

import fetchHcode from './redux/hcodeFetch';

function App() {

    const checkUser = fetchHcode()

    const login = (checkUser.hcode !== "" ? true : false)
    const admin = (checkUser.isAdmin === 1 ? true : false);

    const [isLogin, setIsLogin] = useState(login);
    const [isAdmin, setIsAdmin] = useState(admin);
    const [theme, setTheme] = useState();

    return (
        <Fragment>
            <AppContext.Provider value={{ isLogin, setIsLogin, isAdmin, setIsAdmin, theme, setTheme }}>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
                    <Route path="/resetpwd" element={<SetPasswordPage />} />

                    <Route path="/" Component={DashUserPage} />
                    <Route path="/projects" Component={DashProjects} />
                    <Route path="/addProjects" Component={DashAddProject} />
                    <Route path="/project/details/:projectHCode" Component={DashProjectDetails} />

                    <Route path="/:project_name/access/:projectHCode/:discipline_name" Component={DashAccess} />
                    <Route path="/:project_name/tasks/:projectHCode/:discipline_name" Component={DashProjectTasks} />
                    <Route path="/:project_name/network/ids/:projectHCode/:project_id" Component={DashProjectNetworkIDS} />

                    <Route path="/employees" Component={DashUsers} />
                    <Route path="/addEmployee" Component={DashAddUser} />
                    <Route path="/employee/details/:employeeHCode" Component={DashEmployeeDetails} />
                         
                    <Route path="/clients" Component={DashClients} />
                    <Route path="/clients/add" Component={DashAddClient} />

                    <Route path="/tasks" Component={DashTasks} />
                    <Route path="/tasks/add" Component={DashAddTask} />

                    <Route path="/holidays" Component={DashHoliday} />
                    <Route path="/holidays/add" Component={DashAddHoliday} />

                    <Route path="/approvals" Component={DashApprovals} />

                    <Route path="/permissions" Component={DashPermissions} />
                    <Route path="/addPermissions" Component={DashAddPermission} />

                    <Route path="/softwares" Component={DashSoftwares} />
                    <Route path="/softwares/add" Component={DashAddSoftware} />

                    <Route path="/designations" Component={DashDesignations} />
                    <Route path="/designations/add" Component={DashAddDesignation} />

                    <Route path="/disciplines" Component={DashDisciplines} />
                    <Route path="/disciplines/add" Component={DashAddDiscipline} />
                    <Route path='/discipline/manager/access/add' Component={DashAddDisciplineAccess}/>
                    <Route path='/discipline/manager/access' Component={DashDisciplineManage}/>

                    <Route path="/business" Component={DashBusinessLine} />
                    <Route path="/business/add" Component={DashAddBusinessLine} />

                    <Route path="/reports" Component={DashReports} />

                    <Route path="/timesheet" Component={DashUserPage} />

                    {/* Timesheet Page for Mobile Application */}
                    <Route path="/mobile/view/timelog" element={<DashMobileUserPage />} />

                </Routes>
            </AppContext.Provider>
        </Fragment>
    );
}


export default App;
