import Layout from '../../components/layout/Layout';
import ListOfHolidays from '../../components/tables/holidayTable/ListOfHoliday';
// import fetchHcode from '../../redux/hcodeFetch';
// import { useNavigate } from 'react-router';
import withAuth from '../../components/cookie/AuthenticatedComponent';

function DashHoliday() {
    // const getUser = fetchHcode() 
    //  const navigate = useNavigate()
    //  if(getUser.hcode === ""){
    //     navigate("/login")
    //  }
    return (
        <Layout>
            <ListOfHolidays></ListOfHolidays>
        </Layout>
    );
}

export default  withAuth(DashHoliday);