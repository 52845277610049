import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfPermissions from '../../components/tables/PermissionTable/ListOfPermissions';

function DashPermissions() {
   
    return (
        <Layout>
            <ListOfPermissions></ListOfPermissions>
        </Layout>
    );
}

export default withAuth(DashPermissions);