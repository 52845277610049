import React, { useState, useContext, useEffect } from "react";
import Alert from '../alert/Alert';
import { Link } from "react-router-dom";
import AppContext from "../../context/loginContext";
import CryptoJS from 'crypto-js';
import { signInFailure, signInSuccess, signInStart } from "../../redux/Slice/userSlice";
import { useDispatch } from "react-redux";
import OtpVerify from "../../pages/login-page/OtpVerify";
import styles from "./Form.module.css"
import moment from "moment/moment";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Form() {
    const { setIsLogin } = useContext(AppContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [login, setLogin] = useState(false)
    const [currentTime, setCurrentTime] = useState()
    const [timefromBackend, setTimeFromBackend] = useState()
    const [time, showTime] = useState(false)
    const [seconds, setSeconds] = useState(0)
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)

    const handleUserName = (event) => {
        setUsername(event.target.value.trim())
    }

    const handlePassword = (event) => {
        setPassword(event.target.value.trim())
    }


    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const encryptedPassword = CryptoJS.SHA256(password).toString();

        dispatch(signInStart())

        const response1 = await fetch(`${SERVER_URL}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                uName: username,
                uPwd: encryptedPassword
            })
        });

        const response = await response1.json()

        if (response.time) {
            setCurrentTime(new Date())
            setTimeFromBackend(response.time)
            showTime(true)
        }

        // Handle the response based on the status code
        if (response.statusCode === 200) {
            try {
                await fetch(`${SERVER_URL}/otp/api/generate-otp?email=${response.email}`)
            } catch (error) {
                console.log(error)
            }
            setShowModal(true)
            setResponseData(response);
            dispatch(signInSuccess(response));
            setLogin(true);
            setTimeout(() => setIsLogin(true), 1000);
        } else {

            dispatch(signInFailure(response.data));
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
        }
        setResponseData(response);

    }
 
 const closeModal = ()=>{
    setShowModal(false)
 }   

    //calculating the difference time
    const parseTime = (timeStr) => {
        if (timeStr) {
            const date = new Date(timeStr); // Assuming timeStr is in ISO format (UTC)
            return date;
        }
        return null;
    }

    useEffect(() => {
            const timeBackend = parseTime(timefromBackend);
            const currentAfterParse = parseTime(currentTime);
     
            if (timeBackend && currentAfterParse) {
                const differenceSeconds = Math.abs(moment().diff(moment(timeBackend), 'seconds'));
                setSeconds(differenceSeconds);
            }
        }, [timefromBackend, currentTime]);
     

    //time
    useEffect(() => {
        let intervalId;

        if (seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else {
            showTime(false)
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [seconds]);

    return (
        <>

            <div className="w-100 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '86vh' }}>
                <div id="msg" className="w-25">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                <div className="w-25">
                    <form method="post" className="" onSubmit={handleFormSubmit}>
                        <h1 className="h1 fw-normal text-center" >Login</h1>
                        <div className="py-2">
                            <label htmlFor="number" className="h4">Employee ID</label>
                            <input type="number" className={`form-control ${styles["no-arrow"]}`} name="number" placeholder="Employee ID" onChange={handleUserName} autoComplete="off" required />
                        </div>
                        <div className="py-2">
                            <label htmlFor="password" className="h4" >Password</label>
                            <input type="password" className="form-control" placeholder="Password" name="password" onChange={handlePassword} autoComplete="off" required />
                        </div>
                        <div className="py-2 d-flex justify-content-between">
                            <Link to="/forgotpassword" className={`btn btn-outline-secondary`} >Forgot Password?</Link>
                            <button className={`btn btn-secondary`} type="submit">Sign in</button>
                        </div>
                    </form>
                    {
                        time && (<p style={{ backgroundColor: "#494847", width: '44%', color: "white" }}>Please try again in {seconds} seconds</p>)
                    }
                </div>

            </div>
            {
                showModal && (
                    <OtpVerify
                        show={showModal}
                        closeModal={closeModal} />
                )
            }
        </>

    );
}


export default Form;