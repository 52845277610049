import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import AddSoftwareForm from '../../components/dashpage/AddSoftwareForm';

function DashAddSoftware() { 
    return (
        <Layout>            
            <AddSoftwareForm></AddSoftwareForm>
        </Layout>
    );
}

export default withAuth(DashAddSoftware);