import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchData = async (data, projectHCode) => {
    const response = await fetch(`${SERVER_URL}/project/update/pohours/${projectHCode}`, {
        method: 'PUT',
        credentials:"include",
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};

const UpdateIndividualPoHours = ({ project, showModal, closeModal, projectDiscipline, change }) => {
    const projectHCode = project.hcode;
    const [projectData, setProjectData] = useState(project);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');
     
    const [updateHoursData, setUpdateHoursData] = useState(projectDiscipline)

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(updateHoursData, projectHCode);
        const data = await response.json();
        change((prevState)=> !prevState);
        closeModal();
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };
   

    const handleInputChange = (event)=>{
        const {name, value} = event.target;
        setUpdateHoursData((prevState)=>({
            ...prevState,
            [name] : value,
        }))
    }
   

    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered size=''>
                <Modal.Header closeButton className={`${style['modal-header']}`} >
                    <Modal.Title className={`${style['modal-tittle']}`}>Add Project Discipline Hours</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="projectName">
                            <Form.Label style={{ fontWeight: 'bold' }}>Project Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="project_name"
                                value={projectData.project_name}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group controlId="discipline_name">
                            <Form.Label style={{ fontWeight: 'bold' }}>Discipline</Form.Label>
                            <Form.Control
                              type='text'
                              name="discipline_name"
                              key={updateHoursData.hcode}
                              value={updateHoursData.discipline_name}
                              disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="po_hours">
                            <Form.Label style={{ fontWeight: 'bold' }}>po hours</Form.Label>
                            <Form.Control
                              type='text'
                              name="po_hours"
                              value={updateHoursData.po_hours}
                              onChange={handleInputChange}
                              
                            />
                        </Form.Group>
                        <Form.Group controlId="variation_hour">
                            <Form.Label style={{ fontWeight: 'bold' }}>variation hours</Form.Label>
                            <Form.Control
                              type='text'
                              name="variation_hour"
                              value={updateHoursData.variation_hour}
                              onChange={handleInputChange}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};


export default UpdateIndividualPoHours;