import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import Select from 'react-select';
import ConfirmModal from '../Modal/ConfirmModal';
import CryptoJS from 'crypto-js';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AddUserForm() {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('Wrench@123');
    const [empID, setEmpID] = useState('');
    const [isAdmin, setIsAdmin] = useState('0');
    const [designation, setDesignation] = useState('');

    const [managersList, setManagersList] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');

    const [disciplinesList, setDisciplinesList] = useState([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState(null);

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };
    
    const hashedPassword = CryptoJS.SHA256(password).toString();

    const confirm = async () => {
        let formData = {
            email_id: email,
            pwd: hashedPassword,
            login_name: email.split('@')[0],
            emp_id: empID,
            isAdmin: isAdmin,
            designation: designation,
            managerName: selectedManager,
            discipline: selectedDiscipline,
            employee_name: userName
        };

        fetch(`${SERVER_URL}/user/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/employees`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {

        // Fetch users from the server here
        fetch(`${SERVER_URL}/user`,{
            method:"get",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.users.map((manager) => ({
                    value: manager.employee_name,
                    label: manager.employee_name + ' (' + manager.emp_id + ')',
                }));
                setManagersList(result);
            })
            .catch(error => console.error(error));

        // Fetch disciplines from the server here
        fetch(`${SERVER_URL}/discipline`,{
            method:"get",
            credentials:'include'
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.disciplines.map((discipline) => ({
                    value: discipline.discipline_name,
                    label: discipline.discipline_name,
                }));
                setDisciplinesList(result);
            })
            .catch(error => console.error(error));

    }, []);

    useEffect(() => {
        // Fetch manager from the server here
        fetch(`${SERVER_URL}/manager/${selectedDiscipline}`,{
            method:'get',
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.manager.map((manager) => ({
                    value: manager.employee_name,
                    label: manager.employee_name + ' (' + manager.emp_id + ')',
                }));
                setSelectedManager(result[0].value);
            })
            .catch(error => console.error(error));
    }, [selectedDiscipline]);

    const handleUserName = (event) => {
        setUserName(event.target.value.trim())
    }

    const handleUserEmail = (event) => {
        setEmail(event.target.value.trim())
    }

    const handlePassword = (event) => {
        setPassword(event.target.value.trim())
    }

    const handleUserEmpID = (event) => {
        setEmpID(event.target.value.trim())
    }

    const handleIsAdminChange = (event) => {
        setIsAdmin(event.target.value);
    }

    const handleDesignationChange = (event) => {
        setDesignation(event.target.value.trim())
    }

    const handleGoBack = () => {
        navigate('/employees')
    }


    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!email || !password || !userName || !empID || !selectedManager || !isAdmin || !designation) {
            return;
        }

        setConfirmModal(true);
        setSave(true);
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                <div id="msg" className="w-100 d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <form className="w-50 p-3" onSubmit={handleFormSubmit} style={{ lineHeight: '33px' }}>
                    <h1 className="h1 mb-3 fw-normal text-center font-weight-bold">Create Employee</h1>
                    <div className="d-flex gap-3">
                        <label className="d-flex flex-column w-50">
                            Employee ID
                            <input className="rounded" type="text" name="emp_id" onChange={handleUserEmpID} placeholder="123456" required />
                        </label>
                        <label className="d-flex flex-column w-50">
                            Email
                            <input className="rounded" type="email" name="email" onChange={handleUserEmail} placeholder="name@example.com" required />
                        </label>
                    </div>
                    <br />
                    <div className="d-flex gap-3">
                        <label className="d-flex flex-column w-50">
                            Employee Name
                            <input className="rounded" type="text" name="user_name" onChange={handleUserName} placeholder="Name" required />
                        </label>
                        <label className="d-flex flex-column w-50">
                            Password
                            <input className="rounded" type="password" name="password" value={password} onChange={handlePassword} placeholder="Pas#swo$rd@123" required />
                        </label>
                    </div>
                    <br />
                    <div className="d-flex gap-3 justify-content-between align-items-center">
                        <div className="w-50">
                            <label>
                                Is Admin:
                                <input
                                    className="ms-2"
                                    type="radio"
                                    name="isAdmin"
                                    value="1"
                                    checked={isAdmin === '1'}
                                    onChange={handleIsAdminChange}
                                /> Yes
                            </label>
                            <label>
                                <input
                                    className="ms-2"
                                    type="radio"
                                    name="isAdmin"
                                    value="0"
                                    checked={isAdmin === '0'}
                                    onChange={handleIsAdminChange}
                                /> No
                            </label>
                        </div>
                        <label className="d-flex flex-column w-50">
                            Designation
                            <input className="rounded" type="text" name="designation" onChange={handleDesignationChange} placeholder="Engineer" required />
                        </label>
                    </div>
                    <br />
                    <div className="d-flex gap-3">
                        <label className="w-50">
                            Discipline
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="discipline"
                                options={disciplinesList}
                                onChange={(selectedOption) => {
                                    // Handle the case when the selection is cleared
                                    const value = selectedOption ? selectedOption.value : null;
                                    setSelectedDiscipline(value);
                                }}
                                value={
                                    selectedDiscipline
                                        ? { value: selectedDiscipline, label: selectedDiscipline }
                                        : null
                                }
                                placeholder="Select Discipline"
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                isSearchable
                                isClearable
                            />
                        </label>
                        <label className="w-50">
                            Manager
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="manager"
                                options={managersList}
                                onChange={(selectedOption) => {
                                    // Handle the case when the selection is cleared
                                    const value = selectedOption ? selectedOption.value : null;
                                    setSelectedManager(value);
                                }}
                                value={
                                    selectedManager
                                        ? { value: selectedManager, label: selectedManager }
                                        : null
                                }
                                placeholder="Select Reporting Manager"
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                isSearchable
                                required
                                isClearable
                            />
                            <br />
                        </label>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} type="submit">Create Employee</button>
                    </div>
                    <br />
                </form>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default AddUserForm;
