import React, { useState, useEffect, useRef } from 'react';
import { startOfWeek, endOfWeek, eachDayOfInterval, format, isSameDay } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './timetable.module.css';


const getDayIndex = (dateString) => {
    const date = new Date(dateString);
    const dayIndex = format(date, 'i'); // 'i' returns the day of the week index (0 for Sunday, 1 for Monday, etc.)

    return dayIndex === '0' ? 6 : parseInt(dayIndex) - 1;
};

function isInCurrentWeek(selectedDate) {
    const today = new Date();
    const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
    const endOfCurrentWeek = endOfWeek(today, { weekStartsOn: 1 });

    const currentWeekDates = eachDayOfInterval({ start: startOfCurrentWeek, end: endOfCurrentWeek });

    return currentWeekDates.some(date => isSameDay(date, selectedDate));
}


function isInPreviousDaysOfCurrentWeek(selectedDate) {
    const today = new Date();
    const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
    const currentWeekDates = eachDayOfInterval({ start: startOfCurrentWeek, end: today });
    return currentWeekDates.some(date => isSameDay(date, selectedDate));
}


const Input = ({ date, value, projectIndex, dayIndex, handleHourChange, project, selectedDate, setHourChange, submitDay, permissionToSubmit }) => {
    const [canEdit, setCanEdit] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [showValue, setShowValue] = useState('');
    const [submitted, setSubmitted] = useState();
    const [status, setStatus] = useState();
    const [editValue, setEditValue] = useState(value);
    const inputRef = useRef(null);

    useEffect(() => {
        setShowValue(project.hours[dayIndex]['hours'])
        setSubmitted(project.hours[dayIndex]['submitted'])
        setStatus(project.hours[dayIndex]['status'])
    }, [project, dayIndex])


    useEffect(() => {
        if (isInCurrentWeek(date)) {
            const today = new Date();
            const currday = getDayIndex(today);
            setCanEdit(false);
            const givenDate = new Date(date);
            const givenDateIndex = getDayIndex(givenDate);

            if (currday === 4) {
                if (givenDateIndex <= currday) {
                    setCanEdit(true)
                }

            } else if (currday === 5) {
                if (givenDateIndex <= currday) {
                    setCanEdit(true)
                }

            } else if (currday === 6) {
                if (givenDateIndex <= currday) {
                    setCanEdit(true)
                }
            } else {
                if (isInPreviousDaysOfCurrentWeek(date)) {
                    setCanEdit(true)
                } else {
                    setCanEdit(false)
                }
            }
        } else {
            if (submitDay || permissionToSubmit) {
                if (submitted === 1 && status === 1) {
                    setCanEdit(false);
                } else {
                    setCanEdit(true);
                }
            } else {
                setCanEdit(false);
            }
        }
    }, [date, submitDay, permissionToSubmit, status, submitted])

    useEffect(() => {
        if (showValue) { setEditValue(showValue.replace(':', '')) }
        else {
            setEditValue('')
        }
    }, [showValue, selectedDate])

    const handleDoubleClick = (event) => {
        setIsEditable(prev => !prev);
    };



    useEffect(() => {
        setHourChange(prev => !prev)
    }, [showValue]);

    const handleKeyPress = (event) => {
        const keyCode = event.which || event.keyCode;
        const isValidInput = /^[0-9\b]+$/.test(String.fromCharCode(keyCode)) || (keyCode >= 96 && keyCode <= 105);

        if (!isValidInput) {
            event.preventDefault();
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            inputRef.current.blur();
        }
        console.log(inputRef);
    };

    const handleInput = (event) => {
        const value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (value.length === 3) {
            // If the input is 3 characters (e.g., 930), assume the first digit is the hour and the rest are minutes
            const hours = value.slice(0, 1);
            const minutes = value.slice(1);

            let numericHours = parseInt(hours, 10);
            let numericMinutes = parseInt(minutes, 10);

            // Ensure the hours and minutes are within the valid range
            numericHours = Math.min(23, Math.max(0, isNaN(numericHours) ? 0 : numericHours));
            numericMinutes = Math.min(59, Math.max(0, isNaN(numericMinutes) ? 0 : numericMinutes));

            // Add leading zeros if needed
            const formattedHours = String(numericHours).padStart(2, '0');
            const formattedMinutes = String(numericMinutes).padStart(2, '0');

            // Set the value with the formatted hours and minutes
            setShowValue(formattedHours + ':' + formattedMinutes); // Also update the actual value state
            setEditValue(formattedHours + formattedMinutes); // Update the editValue to reflect the change
            setIsEditable(prev => !prev);
            handleHourChange(projectIndex, dayIndex, formattedHours + ':' + formattedMinutes)

        } else if (value.length <= 4) {
            // If the input is 4 characters or more, assume the first two digits are hours and the last two are minutes
            const hours = value.slice(0, 2);
            const minutes = value.slice(2);

            let numericHours = parseInt(hours, 10);
            let numericMinutes = parseInt(minutes, 10);

            // Ensure the hours and minutes are within the valid range
            numericHours = Math.min(23, Math.max(0, isNaN(numericHours) ? 0 : numericHours));
            numericMinutes = Math.min(59, Math.max(0, isNaN(numericMinutes) ? 0 : numericMinutes));

            // Add leading zeros if needed
            const formattedHours = String(numericHours).padStart(2, '0');
            const formattedMinutes = String(numericMinutes).padStart(2, '0');

            // Set the value with the formatted hours and minutes
            setShowValue(formattedHours + ':' + formattedMinutes); // Also update the actual value state
            setEditValue(formattedHours + formattedMinutes); // Update the editValue to reflect the change
            setIsEditable(prev => !prev);
            handleHourChange(projectIndex, dayIndex, formattedHours + ':' + formattedMinutes)
        }
    };


    const determineColor = (submitted, status) => {
        if (status === 1) {
            return 'green';
        } else if (status === 0 && submitted === 0) {
            return 'red';
        } else {
            if (submitted) {
                return 'blue';
            } else {
                return 'black';
            }
        }
    };

    return (
        <>{
            canEdit ? <div onClick={handleDoubleClick} className={`input-container ${isEditable ? 'editable' : ''}`} style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                {isEditable ? (
                    <input
                        className={`${styles['db']}`}
                        type="number"
                        autoFocus
                        onBlur={handleInput}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={handleKeyPress}
                        maxLength={4}
                        value={editValue}
                        ref={inputRef}
                        onChange={(e) => {
                            setShowValue(e.target.value)
                        }}
                        style={{
                            width: '54px',
                            height: '25px'
                        }}
                    />
                ) : (
                    <div style={{ color: determineColor(submitted, status) }}>{showValue}</div>
                )}
            </div>
                : <div className={`input-container ${isEditable ? 'editable' : ''}`} style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                    <div style={{ color: determineColor(submitted, status) }}>{showValue}</div>
                </div>
        }
        </>
    );
};

export default Input;
