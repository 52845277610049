import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, clientHCode) => {
    const response = await fetch(`${SERVER_URL}/client/update/${clientHCode}`, {
        method: 'PUT',
        mode: 'cors',
        credentials:"include",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const ClientModal = ({ client, showModal, closeModal, change }) => {
    const clientHCode = client.hcode;
    const [clientData, setClientData] = useState(client);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(clientData, clientHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    useEffect(() => {
        setClientData(client);
    }, [client]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setClientData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };



    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit Client</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="client_code">
                            <Form.Label>Client Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="client_code"
                                value={clientData.client_code}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="client_name">
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="client_name"
                                value={clientData.client_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="client_city">
                            <Form.Label>Client City</Form.Label>
                            <Form.Control
                                type="text"
                                name="client_city"
                                value={clientData.client_city}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};


export default ClientModal;