import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import ConfirmModal from '../Modal/ConfirmModal';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AddHolidayForm = () => {
    const [date, setDate] = useState('');
    const [hdesc, setHDesc] = useState('');

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch  = useDispatch()
    const navigate  = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        let formData = {
            date: date,
            hdesc: hdesc,
        };

        fetch(`${SERVER_URL}/holiday/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/holidays`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    };

    const handleHdesc = (event) => {
        setHDesc(event.target.value.trim())
    };

    const handleDate = (event) => {
        setDate(event.target.value)
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!date || !hdesc) {
            return;
        }

        setConfirmModal(true);
        setSave(true);
    }

    const handleGoBack = () => {
        navigate('/holidays')
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <form className="w-25" onSubmit={handleFormSubmit}>
                    <h1 className="h1 mb-3 fw-normal text-center font-weight-bold">Add Holiday</h1>
                    <div className="d-flex flex-column gap-3">
                        <label className="d-flex flex-column w-100">
                            Date
                            <input className="rounded" style={{ lineHeight: '33px' }} type="date" name="name" onChange={handleDate} required />
                        </label>
                        <label className="d-flex flex-column w-100">
                            Description
                            <input className="rounded" style={{ lineHeight: '33px' }} type="text" name="name" onChange={handleHdesc} placeholder="official" required />
                        </label>
                    </div>
                    <br />
                    <div className="d-flex justify-content-between">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} type="submit">Add Holiday</button>
                    </div>
                    <br />
                </form>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};

export default AddHolidayForm;