import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import MultiSelect from "../sample/ReactSelect";
import ConfirmModal from '../Modal/ConfirmModal';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const arrayOfValues = (arrayOfObjects) => {
    const result = arrayOfObjects.map((obj) => {
        return obj.value;
    })
    return result;
}

function AddProjectForm() {
    const [projectID, setProjectID] = useState('');
    const [projectName, setProjectName] = useState('');
    const [desc, setDesc] = useState('');

    const [poNumber, setPONumber] = useState(0);
    const [poHours, setPOHours] = useState(0);
    const [poDate, setPODate] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [currentDate, setCurrentDate] = useState('');

    const [clientsList, setClientsList] = useState([]);
    const [businessList, setBusinessList] = useState([]);

    const [disciplinesList, setDisciplinesList] = useState([]);

    const [selectedBusinessLine, setSelectedBusinessLine] = useState('');

    const [selectedClient, setSelectedClient] = useState('');

    const [selectedDiscipline, setSelectedDiscipline] = useState([]);

    const [isBillable, setIsBillable] = useState('1');

    const [addingNewClient, setAddingNewClient] = useState(false);
    const [newClientName, setNewClientName] = useState('');
    const [newClientCode, setNewClientCode] = useState('');

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        let formData = {
            project_id: projectID,
            project_name: projectName,
            project_desc: desc,

            business_line: selectedBusinessLine.value || '',

            po_number: poNumber,
            po_hours: poHours,
            po_date: poDate,

            start_date: startDate,
            end_date: endDate,
            is_billable: isBillable,

            discipline: arrayOfValues(selectedDiscipline)
        };

        if (selectedClient === 'Add New Client') {
            formData = {
                ...formData,
                client: newClientName,
                clientCode: newClientCode,
            };
        } else {
            formData = {
                ...formData,
                client: selectedClient.label,
            };
        }


        fetch(`${SERVER_URL}/project/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/projects`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const currentDate = `${year}-${month}-${day}`;
        setCurrentDate(currentDate);
        setStartDate(currentDate);

        // Fetch clients from the server here
        fetch(`${SERVER_URL}/client`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.clients.map((client) => ({
                    value: client.hcode,
                    label: client.client_name,
                }));

                result.push({
                    value: 'Add New Client',
                    label: 'Add New Client',
                });
                setClientsList(result);
            })
            .catch(error => console.error(error));

        // Fetch Business Lines from the server here
        fetch(`${SERVER_URL}/business`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.business.map((business_line) => ({
                    value: business_line.hcode,
                    label: business_line.business_line_name,
                }));
                setBusinessList(result);
            })
            .catch(error => console.error(error));


        // Fetch disciplines from the server here
        fetch(`${SERVER_URL}/discipline`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.disciplines.map((discipline) => ({
                    value: discipline.discipline_name,
                    label: discipline.discipline_name,
                }));
                setDisciplinesList(result);
            })
            .catch(error => console.error(error));
    }, []);

    const navigate = useNavigate();

    const handleProjectName = (event) => {
        setProjectName(event.target.value.trim())
    };

    const handleProjectID = (event) => {
        setProjectID(event.target.value.trim())
    };

    const handlePONumber = (event) => {
        setPONumber(event.target.value)
    };

    const handlePOHours = (event) => {
        setPOHours(event.target.value.trim())
    };

    const handlePODate = (event) => {
        setPODate(event.target.value.trim())
    };

    const handleStartDate = (event) => {
        setStartDate(event.target.value.trim())
    };

    const handleEndDate = (event) => {
        setEndDate(event.target.value.trim())
    };

    const handleProjectDesc = (event) => {
        setDesc(event.target.value.trim())
    };

    const handleIsBillableChange = (event) => {
        setIsBillable(event.target.value);
    }

    const handleGoBack = () => {
        navigate('/projects')
    }


    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!projectID || !projectName || !selectedClient || selectedDiscipline.length === 0 || !isBillable) {
            return;
        }

        setConfirmModal(true);
        setSave(true);
    };


    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                <form className="w-50" onSubmit={handleFormSubmit}>
                    <h1 className="h1 mb-3 fw-normal text-center font-weight-bold">Create Project</h1>
                    <p style={{ color: 'red' }}>Required fields are marked *</p>
                    <div className="d-flex gap-3" >
                        <label className="w-100 d-flex flex-column" >
                            Project ID *
                            <input type="text" name="project_ID" onChange={handleProjectID} placeholder="1234" required className="rounded" style={{ lineHeight: '33px' }} />
                        </label>
                        <label className="w-100 d-flex flex-column" >
                            Project Name *
                            <input type="text" name="project_name" onChange={handleProjectName} placeholder="Name" required className="rounded" style={{ lineHeight: '33px' }} />
                        </label>
                    </div>
                    <br />
                    <label className="w-100 d-flex flex-column">
                        Project Description
                        <textarea
                            className="rounded"
                            // style={{ lineHeight: '10px', borderColor: 'transparent' }}
                            name="desc"
                            onChange={handleProjectDesc}
                            placeholder="Description"
                        // rows="4"
                        />
                    </label>
                    <br />
                    <div className="d-flex gap-3" >
                        <label className="w-100 d-flex flex-column" >
                            Purchase Order Number
                            <input type="number" name="po_number" onChange={handlePONumber} placeholder="Enter PO Number" className="rounded" style={{ lineHeight: '33px' }} />
                        </label>
                        <label className="w-100 d-flex flex-column" >
                            Purchase Order Hours
                            <input type="number" name="po_hours" onChange={handlePOHours} placeholder="Enter PO Hours" className="rounded" style={{ lineHeight: '33px' }} />
                        </label>
                    </div>
                    <br />
                    <label className="w-100 d-flex flex-column">
                        Purchase Order Date
                        <input className="rounded" style={{ lineHeight: '33px' }} type="date" name="po_date" onChange={handlePODate} />
                    </label>
                    <br />
                    <div className="d-flex gap-3" >

                        <label className="w-100">
                            Business Line
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="business_line"
                                options={businessList}
                                onChange={(selectedOption) => {
                                    setSelectedBusinessLine(selectedOption);
                                }}
                                value={
                                    selectedBusinessLine
                                        ? { value: selectedBusinessLine.value, label: selectedBusinessLine.label }
                                        : null
                                }
                                placeholder="Select Business Line"
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                isSearchable
                                isClearable
                            />
                            <br />
                        </label>
                        <label className="w-100">
                            Client *
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="client"
                                options={clientsList}
                                onChange={(selectedOption) => {
                                    setSelectedClient(selectedOption);
                                    if (selectedOption && selectedOption.value === "Add New Client") {
                                        setAddingNewClient(true);
                                    } else {
                                        setAddingNewClient(false);
                                    }
                                }}
                                value={
                                    selectedClient
                                        ? { value: selectedClient.value, label: selectedClient.label }
                                        : null
                                }
                                placeholder="Select Client"
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                isSearchable
                                required
                                isClearable
                            />
                            <br />
                        </label>
                    </div>
                    {addingNewClient && (
                        <div className="d-flex justify-content-between gap-3 w-100">
                            <label className="d-flex flex-column w-50">
                                New Client Name
                                <input className="rounded" style={{ lineHeight: '33px' }} type="text" name="newClient" placeholder="New Client Name" value={newClientName} onChange={(e) => setNewClientName(e.target.value.trim())} required />
                            </label>
                            <label className="d-flex flex-column w-50">
                                New Client Code
                                <input className="rounded" style={{ lineHeight: '33px' }} type="text" name="newClientCode" placeholder="New Client Code" value={newClientCode} onChange={(e) => setNewClientCode(e.target.value.trim())} required />
                            </label>
                        </div>
                    )}
                    <br />
                    <label className="w-100 d-flex flex-column">
                        Discipline *
                        <MultiSelect options={disciplinesList} setSelectedOptions={setSelectedDiscipline} />
                    </label>
                    <br />
                    <div className="w-100 d-flex gap-3">
                        <label className="w-100 d-flex flex-column">
                            Start Date *
                            <input className="rounded" style={{ lineHeight: '33px' }} type="date" name="start_date" onChange={handleStartDate} defaultValue={currentDate} required />
                        </label>
                        <label className="w-100 d-flex flex-column">
                            End Date
                            <input className="rounded" style={{ lineHeight: '33px' }} type="date" name="end_date" onChange={handleEndDate} />
                        </label>
                    </div>
                    <br />
                    <div className="w-50">
                        <label>
                            Is Billable:
                            <input
                                className="ms-2"
                                type="radio"
                                name="is_billable"
                                value="1"
                                checked={isBillable === '1'}
                                onChange={handleIsBillableChange}
                            /> Yes
                        </label>
                        <label>
                            <input
                                className="ms-2"
                                type="radio"
                                name="is_billable"
                                value="0"
                                checked={isBillable === '0'}
                                onChange={handleIsBillableChange}
                            /> No
                        </label>
                    </div>
                    <br />
                    <div className="d-flex justify-content-between mb-3">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} type="submit">Create Project</button>
                    </div>
                </form>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default AddProjectForm;
