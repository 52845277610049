import React, { useEffect } from "react";
import Form from '../../components/loginpage/Form';
import Layout from "../../components/layout/Layout";
import { useNavigate } from 'react-router-dom';
import fetchHcode from "../../redux/hcodeFetch";

function LoginPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const userDetails = fetchHcode()
        if(userDetails.hcode !== ""){
            navigate("/timesheet")
        }
    }, []);

    return (
        <Layout>
            <Form />
        </Layout>
    );
}

export default LoginPage;