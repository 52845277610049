import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfProjectTasks from '../../components/tables/TaskTable/ListOfProjectTasks';

function DashProjectTasks() {
   
    return (
        <Layout>
            <ListOfProjectTasks></ListOfProjectTasks>
        </Layout>
    );
}

export default withAuth(DashProjectTasks);