import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import 'datatables.net-bs5';
import $ from 'jquery';
import ProjectAccessModal from "../Modal/projectAccessModal";
import ProjectTasksModal from "../Modal/projectTasksModal";
import ConfirmModal from "../Modal/ConfirmModal";
import ProjectDisciplineModal from "../Modal/ProjectDisciplineModal";
import styles from '../../common/common.module.css';
import UpdateIndividualPoHours from "../Modal/IndividualPoHoursUpdate";
import Card from 'react-bootstrap/Card';
import { LogoutIfNoHcode } from "../../common/LogoutIfNoHcode";
import { useDispatch } from "react-redux";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const convertToIndianFormat = (utcDateTimeString) => {
    // If input is null, return an empty string or handle it based on your requirements
    if (!utcDateTimeString) {
        return '';
    }

    // Define the target time zone (India Standard Time)
    const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Parse the UTC date string to a Date object
    const utcDate = new Date(utcDateTimeString);

    // Convert UTC to Indian time zone
    const indianDate = utcToZonedTime(utcDate, targetTimeZone);

    // Format the date and time according to 'dd-MM-yyyy hh:mm:ss a'
    const formattedDateTime = format(indianDate, 'dd-MM-yyyy', { timeZone: targetTimeZone });

    return formattedDateTime;
};


const ProjectDetails = () => {
    const { projectHCode } = useParams();

    const [project, setProject] = useState('');
    const [projectDiscipline, setProjectDiscipline] = useState();

    const [users, setUsers] = useState([]);
    const [tasks, setTasks] = useState([]);

    const [activeTab, setActiveTab] = useState('Employees');

    const [provideAccess, setProvideAccess] = useState([]);
    const [removeAccess, setRemoveAccess] = useState([]);

    const [showAccessModal, setShowAccessModal] = useState(false);
    const [empChange, setEmpChange] = useState(false);

    const [provideTaskAccess, setProvideTaskAccess] = useState([]);
    const [removeTaskAccess, setRemoveTaskAccess] = useState([]);

    const [showTaskModal, setShowTaskModal] = useState(false);

    const [change, setChange] = useState('');

    const [selectedDiscipline, setSelectedDiscipline] = useState();
    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const [showModal, setShowModal] = useState(false);
    const [detailsChange, setDetailsChange] = useState('');

    const [showDisciplinePoHoursModal, setshowDisciplinePoHoursModal] = useState(false)
    const [hoursUpdateDiscipline, setHoursUpdateDiscipline] = useState([])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const tableRefEmployees = useRef(null);
    const tableRefTasks = useRef(null);

    const handleProvideEmployeeAccess = async (data) => {
        const response = await fetch(`${SERVER_URL}/access/provideAccess/`, {
            method: 'POST',
            mode: 'cors',
            credentials:"include",
            body: JSON.stringify({
                users: data.users,
                project_name: project.project_name,
                project_hcode: projectHCode,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    };

    const handleProvideTaskAccess = async (data) => {
        const response = await fetch(`${SERVER_URL}/project/task/add`, {
            method: 'POST',
            credentials:"include",
            mode: 'cors',
            body: JSON.stringify({
                tasks: data.tasks,
                projectHCode: data.projectHCode
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(prev => !prev);
        return response;
    };

    const handleRemoveEmployeeAccess = async (user) => {
        const response = await fetch(`${SERVER_URL}/access/removeAccess/${projectHCode}/${user.hcode}`, {
            method: 'DELETE',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    const handleRemoveTaskAccess = async (task) => {
        const response = await fetch(`${SERVER_URL}/project/task/delete/${task.pthcode}`, {
            method: 'DELETE',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(prev => !prev);
    };

    const handleAssignEmployees = () => {
        setShowAccessModal(true);
    };

    const handleAssignTasks = () => {
        setShowTaskModal(true);
    };

    const closeModal = () => {
        setShowAccessModal(false);
    };

    const closeTaskModal = () => {
        setShowTaskModal(false);
    };

    // const handleReportingManager = (user) => {
    //     setSelectedUser(user);
    //     setShowApproverModal(true);
    // };

    // const closeApproverModal = () => {
    //     setShowApproverModal(false);
    // };

    useEffect(() => {
        if (provideAccess.length > 0) {
            const data = {
                users: provideAccess,
            }
            handleProvideEmployeeAccess(data);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        if (removeAccess.length > 0) {
            for (const user of removeAccess) {
                handleRemoveEmployeeAccess(user);
            }
            window.location.reload();
        }
    }, [provideAccess, removeAccess]);

    useEffect(() => {
        if (provideTaskAccess.length > 0) {
            const data = {
                tasks: provideTaskAccess,
                projectHCode: projectHCode
            }
            handleProvideTaskAccess(data);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        if (removeTaskAccess.length > 0) {
            for (const task of removeTaskAccess) {
                handleRemoveTaskAccess(task);
            }
            window.location.reload();
        }
    }, [provideTaskAccess, removeTaskAccess]);

    useEffect(() => {
        fetch(`${SERVER_URL}/project/details/${projectHCode}`,{
            method:"get",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setProject(data.project[0]);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            }
            )
            .catch(error => console.error(error));

        fetch(`${SERVER_URL}/project/discipline/${projectHCode}`,{
            method:"get",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setProjectDiscipline(data.disciplines);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch(error => console.error(error));

    }, [detailsChange]);

    useEffect(() => {
        $(tableRefTasks.current).DataTable().destroy();

        fetch(`${SERVER_URL}/project/task/${projectHCode}`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setTasks(data.tasks);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);

    useEffect(() => {
        $(tableRefEmployees.current).DataTable().destroy();

        fetch(`${SERVER_URL}/access/${projectHCode}`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setUsers(data.access);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }, [empChange]);

    useEffect(() => {
        if (users.length > 0) {
            $(tableRefEmployees.current).DataTable({
                destroy: true,
                searching: true,
                paging: false,
            });
        }
    }, [users, activeTab]);

    useEffect(() => {
        if (tasks.length > 0) {
            $(tableRefTasks.current).DataTable({
                destroy: true,
                searching: true,
                paging: false,
            });
        }
    }, [tasks, activeTab]);


    const handleExportData = async () => {
        const fileURL = await fetch(`${SERVER_URL}/project/report/${projectHCode}`,{
            method:"get",
            credentials:"include"
        });
        const file = await fileURL.blob();
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = `ProjectReport.xlsx`;
        link.click();
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (obj, HCode) => {
        const response = await fetch(`${SERVER_URL}/project/discipline/${HCode}/${obj.discipline_name}`, {
            method: 'DELETE',
            credentials:"include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setDetailsChange(response);
        closeConfirmModal();
    };

    const handleDisciplineRemove = async (discipline) => {
        setSelectedDiscipline(discipline);
        setConfirmModal(true);
        setSave(true);
    };

    const handleAddDiscipline = () => {
        setShowModal(true);
    }

    const closeDisciplineModal = () => {
        setShowModal(false);
    }

    const handleDisciplinePoHours =(disciplineHcode) =>{
        setshowDisciplinePoHoursModal(true);
        setHoursUpdateDiscipline(disciplineHcode)

    }
    const closeDisciplinePoHours = () => {
        setshowDisciplinePoHoursModal(false);
    }

    return (
        <>
            <div className="d-flex gap-3 px-3" style={{ minHeight: '79vh' }}>
                <div className="w-50">
                    <div className="card" style={{ height: '75vh', overflow: "auto" }}>
                        <div className="card-title text-center">
                            <h2>Project Details</h2>
                        </div>
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Project Name:</div>
                                <div className="col-8">{project.project_name}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Project ID:</div>
                                <div className="col-8">{project.project_id}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Client:</div>
                                <div className="col-8">{project.client_name}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Discipline:</div>
                                <div className="col-8 d-flex gap-2 flex-wrap align-items-center">
                                    {projectDiscipline && projectDiscipline.map((discipline) => (
                                        <div key={discipline.hcode} className="d-flex gap-2 px-2 py-1 bg-primary" style={{ height: '33px', borderRadius: '16px' }}>
                                           <a className="text-uppercase" onClick={()=> handleDisciplinePoHours(discipline)} style={{ color: 'White', cursor:"pointer" }}>{discipline.discipline_name}</a> 
                                            <p className="text-dark fw-bold" style={{ cursor: 'pointer' }} onClick={() => handleDisciplineRemove(discipline)}>x</p>
                                        </div>
                                    ))}
                                    <a style={{ cursor: 'pointer' }} onClick={handleAddDiscipline}><img src={process.env.PUBLIC_URL + '/images/plus.png'} alt="Add Discipline" height='20px' width='20px' /></a>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Business Line:</div>
                                <div className="col-8">{project.business_line_name}</div>
                            </div>
                            {/* <div className="row mb-2">
                                <div className="col-4 fw-bold">Manager:</div>
                                <div className="col-8">{project.employee_name}</div>
                            </div> */}
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Start Date:</div>
                                <div className="col-8">{convertToIndianFormat(project.start_date)}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">End Date:</div>
                                <div className="col-8">{convertToIndianFormat(project.end_date) || 'NA'}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">PO Number:</div>
                                <div className="col-8">{project.po_number}</div>
                            </div>
                            {/* <div className="row mb-2">
                                <div className="col-4 fw-bold">PO Hours:</div>
                                <div className="col-8">{project.po_hours}</div>
                            </div> */}
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">PO Date:</div>
                                <div className="col-8">{convertToIndianFormat(project.po_date) || 'NA'}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4 fw-bold">Billable:</div>
                                <div className="col-8">{project.is_billable ? 'Yes' : 'No'}</div>
                            </div>
                                <div className="row">
                                {projectDiscipline && projectDiscipline.map((discipline) => (
                                    <div className="col-md-3 mb-3 d-flex gap-3 align-items-center" key={discipline.hcode}>
                                        <Card style={{ width: "200px" }}>
                                            <Card.Body>
                                                <Card.Title>{discipline.discipline_name}</Card.Title>
                                                <Card.Text>Po hours: {discipline.po_hours}</Card.Text>
                                                <Card.Text>Variation hours: {discipline.variation_hour}</Card.Text>
                                                <Card.Text>Expended hours: {discipline.hours_booked}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                                            
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-secondary" onClick={() => handleExportData(project)}>Export Data</button>
                        </div>
                    </div>
                </div>
                <div className="w-50">
                    <div className="card p-2" style={{ height: '75vh' }}>
                        <ul className='d-flex flex-row flex-wrap nav nav-tabs'>
                            <li className='nav-item'>
                                <a className={`nav-link`}
                                    onClick={() => toggleTab('Employees')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Employees
                                </a>
                            </li>
                            <li className={`nav-item`}>
                                <a
                                    className={`nav-link`}
                                    onClick={() => toggleTab('Tasks')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Tasks
                                </a>
                            </li>
                        </ul>
                        {activeTab === 'Employees' &&
                            <>
                                <div className="card-body" style={{ maxHeight: '63vh', overflow: 'auto' }}>
                                    {users.length > 0 ? (
                                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped table-sm ${styles.table}`} ref={tableRefEmployees} >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Emp ID</th>
                                                    <th>Name</th>
                                                    <th>Discipline</th>
                                                    {/* <th>Approver</th>
                                                    <th></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user) => (
                                                    <tr key={user.hcode}>
                                                        <td>{user.emp_id}</td>
                                                        <td>{user.employee_name}</td>
                                                        <td>{user.discipline_name}</td>
                                                        {/* <td>{user.approver}</td>
                                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleReportingManager(user)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td> */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="text-center mt-3">No Employees assigned yet.</p>
                                    )}
                                </div>
                                <div className="card-footer text-center">
                                    <button className="btn btn-secondary" onClick={() => handleAssignEmployees()}>Assign Employees</button>
                                </div>
                            </>
                        }
                        {activeTab === 'Tasks' &&
                            <>
                                <div className="card-body" style={{ maxHeight: '63vh', overflow: 'auto' }}>
                                    {tasks.length > 0 ? (
                                        <table id="tableTask" className={`mt-4 table table-bordered table-hover table-striped table-sm ${styles.table}`} ref={tableRefTasks} >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tasks.map((task) => (
                                                    <tr key={task.hcode}>
                                                        <td>{task.task_name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="text-center mt-3">No Tasks assigned yet.</p>
                                    )}
                                </div>
                                <div className="card-footer text-center">
                                    <button className="btn btn-secondary" onClick={() => handleAssignTasks()}>Assign Tasks</button>
                                </div>
                            </>
                        }
                        {showAccessModal && (
                            <ProjectAccessModal project_name={project.project_name} showAccessModal={showAccessModal} closeModal={closeModal} usersAccess={users} setUsersAccess={setUsers} setProvideAccess={setProvideAccess} setRemoveAccess={setRemoveAccess} />
                        )}
                        {showTaskModal && (
                            <ProjectTasksModal project_name={project.project_name} showModal={showTaskModal} closeModal={closeTaskModal} tasksAccess={tasks} setTasksAccess={setTasks} setProvideAccess={setProvideTaskAccess} setRemoveAccess={setRemoveTaskAccess} />
                        )}
                        {/* {
                            selectedUser && (
                                <ApproverModal
                                    user={selectedUser}
                                    showApproverModal={showApproverModal}
                                    closeApproverModal={closeApproverModal}
                                    change={setEmpChange}
                                />
                            )
                        } */}
                        {
                            save && (
                                <ConfirmModal
                                    confirmModal={confirmModal}
                                    closeConfirmModal={closeConfirmModal}
                                    confirm={confirm}
                                    HCode={projectHCode}
                                    obj={selectedDiscipline}
                                    msg={msg}
                                    
                                />
                            )
                        }
                        {
                            showModal && (
                                <ProjectDisciplineModal
                                    showModal={showModal}
                                    closeModal={closeDisciplineModal}
                                    project={project}
                                    proDis={projectDiscipline}
                                    change={setDetailsChange}
                                />
                            )
                        }
                        {
                            showDisciplinePoHoursModal && (
                                <UpdateIndividualPoHours
                                showModal={showDisciplinePoHoursModal}
                                closeModal={closeDisciplinePoHours}
                                project={project}
                                projectDiscipline={hoursUpdateDiscipline}
                                change={setDetailsChange}
                              />
                            ) 
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectDetails;
