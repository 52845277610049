import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfApprovals from '../../components/tables/ApprovalTable/ListOfApprovals';



function DashApprovals() {
   

    return (
        <Layout>
            <ListOfApprovals></ListOfApprovals>
        </Layout>
    );
}

export default withAuth(DashApprovals);