import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfProjectNetworkIDS from '../../components/tables/NetworkIDSTable/ListOfProjectNetworkIDS';

function DashProjectNetworkIDS() {

    return (
        <Layout>
            <ListOfProjectNetworkIDS></ListOfProjectNetworkIDS>
        </Layout>
    );
}

export default withAuth(DashProjectNetworkIDS);