import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';
import Select from 'react-select';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, taskHCode) => {
    const response = await fetch(`${SERVER_URL}/task/update/${taskHCode}`, {
        method: 'PUT',
        credentials: "include",
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const TaskModal = ({ task, showModal, closeModal, change }) => {
    const taskHCode = task.hcode;
    const [taskData, setTaskData] = useState(task);
    const [softwaresList, setSoftwaresList] = useState([]);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(taskData, taskHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    const fetchSoftwares = async () => {
        // Fetch softwares from the server here
        fetch(`${SERVER_URL}/software`, {
            method: "GET",
            credentials: "include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.softwares.map((software) => ({
                    value: software.software_name,
                    label: software.software_name,
                }));
                setSoftwaresList(result);
            })
            .catch(error => console.error(error));
    };

    useEffect(() => {
        setTaskData(task);
        fetchSoftwares();
    }, [task]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTaskData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSelectChange = (selectedOption, fieldName) => {
        setTaskData((prevState) => ({
            ...prevState,
            [fieldName]: selectedOption ? selectedOption.value : null,
        }));
    };


    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit Task</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="task_name">
                            <Form.Label>Task Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="task_name"
                                value={taskData.task_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="software_name">
                            <Form.Label>Software Name</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="software_name"
                                options={softwaresList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'software_name')}
                                value={
                                    taskData.software_name
                                        ? { value: taskData.software_name, label: taskData.software_name }
                                        : null
                                }
                                placeholder="Select Software"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                                isClearable
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};


export default TaskModal;