import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import Select from 'react-select';
import MultiSelect from '../sample/ReactSelect';
import { parse, format, eachDayOfInterval, parseISO } from 'date-fns';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const arrayOfValues = (arrayOfObjects) => {
    const result = arrayOfObjects.map((obj) => {
        return obj.value;
    });
    return result;
};

const fetchData = async (data) => {
    const response = await fetch(`${SERVER_URL}/report/weekly`, {
        method: 'POST',
        credentials: "include",
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};

// Function to generate a range of dates
const generateDateRange = (startDateStr, endDateStr) => {
    const startDate = parse(startDateStr, 'dd-MM-yyyy', new Date());
    const endDate = parse(endDateStr, 'dd-MM-yyyy', new Date());

    const dates = eachDayOfInterval({ start: startDate, end: endDate });

    // Format the dates to dd-MM-yyyy
    const formattedDates = dates.map(date => format(date, 'dd-MM-yyyy'));

    return formattedDates;
};

const transformData = (data) => {
    const result = {};

    data.forEach(item => {
        const project = item.project_name;
        const date = format(parseISO(item.date), 'dd-MM-yyyy');
        const [network, activity] = item.network_id.split('-');

        const totalHours = (parseFloat(item.nt_minutes_worked) + parseFloat(item.ot_minutes_worked)) / 60;

        if (!result[project]) {
            result[project] = { total: 0 };
        }

        result[project][date] = totalHours;
        result[project]['network'] = network;
        result[project]['activity'] = activity || '';
        result[project].total += totalHours;
    });

    return result;
};

const getTotals = (data, key) => {
    let value = 0;
    Object.keys(data).map((project, index) => (
        value += data[project][key] || 0
    ))
    return value;
}

const DownloadReportModal = ({ reportModal, closeModal, startDate, endDate, weekNumber, year }) => {

    const [projectsList, setProjectsList] = useState([]);
    // Metso Approver Department
    const [departmentList, setDepartmentList] = useState([]);

    const [responseData, setResponseData] = useState('');

    const [selectedProject, setSelectedProject] = useState([]);
    const [sapNumber, setSapNumber] = useState();
    const [approverName, setApproverName] = useState();
    const [departmentName, setDepartmentName] = useState();

    const [dates, setDates] = useState([]);
    const [tableData, setTableData] = useState({});

   const navigate = useNavigate()
   const dispatch = useDispatch()

    const fetchProjects = async () => {
        try {
            const response = await fetch(`${SERVER_URL}/workhours/projects`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const projectData = await response.json();
            if (projectData.statusCode === 200) {
                const activeProjects = projectData.projects.filter((item) => item.is_active !== 0 && item.is_billable !== 0);
                const projects = await activeProjects.map((project) => ({
                    value: project.project_name,
                    label: project.project_name + ' (' + project.project_id + ')',
                }));
                setProjectsList(projects);
            }
            if(projectData.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    };

    const fetchDepartmentsData = async () => {
        const response = await fetch(`${SERVER_URL}/metso/approver/department`, {
            method: 'GET',
            mode: 'cors',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const departmentData = await response.json();
        if (departmentData.statusCode === 200) {
            const departments = await departmentData.departments.map((department) => ({
                value: department.department_name,
                label: department.department_name,
            }));
            setDepartmentList(departments);
        }
    };

    useEffect(() => {
        fetchProjects();
        const result = generateDateRange(startDate, endDate);
        setDates(result);
    }, [startDate, endDate]);

    useEffect(() => {
        fetchDepartmentsData();
    }, [])

    const handleYes = async (event) => {
        event.preventDefault();

        if (!sapNumber || !approverName || selectedProject.length === 0) {
            return;
        }

        let formData = {
            sap_number: sapNumber,
            approver_name: approverName,
            start_date: startDate,
            end_date: endDate,
            department: departmentName.label,
            type: 'excel',
            projects: arrayOfValues(selectedProject)
        };

        try {
            const response = await fetchData(formData);

            if (response.status === 404) {
                const data = await response.json();
                setResponseData(data);
            } else {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `Timesheet_Week${weekNumber}_${year}.xlsm`;
                document.body.appendChild(link); // Append link to the body
                link.click();
                document.body.removeChild(link); // Remove link from the body
                closeModal();
            }
        } catch (error) {
            console.error("Error downloading report:", error);
            setResponseData({ message: "Error downloading report", statusCode: 500 });
        }
    };

    const handleView = async (event) => {
        event.preventDefault();

        if (!sapNumber || !approverName || selectedProject.length === 0) {
            return;
        }

        let formData = {
            sap_number: sapNumber,
            approver_name: approverName,
            start_date: startDate,
            end_date: endDate,
            type: 'View',
            projects: arrayOfValues(selectedProject)
        };

        try {
            const response = await fetchData(formData);

            if (response.status === 200) {
                const data = await response.json();
                setResponseData(data.result);
                setTableData(transformData(data.result));
            }
            if(response.status === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        } catch (error) {
            console.error("Error downloading report:", error);
        }
    };

    const handleNo = () => {
        closeModal();
    }

    const handleSAP = (event) => {
        setSapNumber(event.target.value.trim())
    };

    const handleApproverName = (event) => {
        setApproverName(event.target.value.trim())
    };

    const handleSelectChange = (selectedOption) => {
        setDepartmentName(selectedOption);
    };

    return (
        <Modal show={reportModal} onHide={closeModal} centered size='xl'>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Weekly Report Download</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <Form className='d-flex flex-column gap-3'>
                    <div className='d-flex gap-3'>
                        <Form.Group controlId="project_name" className='d-flex flex-column' style={{ width: '50%' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>Project</Form.Label>
                            <MultiSelect options={projectsList} setSelectedOptions={setSelectedProject} />
                        </Form.Group>
                        <Form.Group controlId="department_name" style={{ width: '50%' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>Metso Approver Department</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="department_name"
                                options={departmentList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'department_name')}
                                value={
                                    departmentName
                                        ? { value: departmentName.value, label: departmentName.label }
                                        : null
                                }
                                placeholder="Select Department"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                                isClearable
                            />
                        </Form.Group>
                    </div>
                    <div className='d-flex gap-3'>
                        <Form.Group controlId="sap_number" style={{ width: '50%' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>SAP Person Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="sap_number"
                                onChange={handleSAP}
                                placeholder='Please Enter SAP Number'
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="approver_name" style={{ width: '50%' }}>
                            <Form.Label style={{ fontWeight: 'bold' }}>Approver Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="approver_name"
                                onChange={handleApproverName}
                                placeholder='Please Enter Approver Name'
                                required
                            />
                        </Form.Group>
                    </div>
                </Form>
                {
                    responseData &&
                    <>
                        <table className='mt-3 table table-bordered border-primary-subtle'>
                            <tbody>
                                <tr>
                                    <td className="fw-bold">Company</td>
                                    <td>Sidvin Outotec Engineering</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">SAP Person Number</td>
                                    <td>{sapNumber}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Employee Name</td>
                                    <td>{responseData && responseData[0] && responseData[0].employee_name}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Approver</td>
                                    <td>{approverName}</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">Department</td>
                                    <td>{departmentName && departmentName.value}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='overflow-auto'>
                            <table className='mt-3 table table-bordered border-primary-subtle table-sm'>
                                <thead>
                                    <tr>
                                        <th>Project/Order Description</th>
                                        <th>Network</th>
                                        <th>Activity</th>
                                        <th>Total</th>
                                        {dates.map((date, index) => (
                                            <th key={index} style={{ minWidth: '100px' }}>{date}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key='total'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='text-center fw-bold'>{getTotals(tableData, 'total')}</td>
                                        {dates.map((date, dateIndex) => (
                                            <td key={dateIndex} className='text-center fw-bold'>{getTotals(tableData, date)}</td>
                                        ))}
                                    </tr>
                                    {Object.keys(tableData).map((project, index) => (
                                        <tr key={index}>
                                            <td>{project}</td>
                                            <td className='text-center'>{tableData[project].network}</td>
                                            <td className='text-center'>{tableData[project].activity}</td>
                                            <td className='text-center fw-bold'>{tableData[project].total}</td>
                                            {dates.map((date, dateIndex) => (
                                                <td key={dateIndex} className='text-center'>{tableData[project][date] || ''}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={handleNo}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleView}>
                    View
                </Button>
                <Button variant="primary" onClick={handleYes}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default DownloadReportModal;
