import React, { useState, useEffect } from "react";
import Alert from '../alert/Alert';
import ConfirmModal from '../Modal/ConfirmModal';
import Select from 'react-select';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AddTaskForm = () => {
    const [name, setName] = useState('');

    const [softwaresList, setSoftwaresList] = useState([]);
    const [selectedSoftware, setSelectedSoftware] = useState('');

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');
 
    const dispatch = useDispatch()
    const navigate = useNavigate()  

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        let formData = {
            task_name: name,
            software_name: selectedSoftware,
        };

        fetch(`${SERVER_URL}/task/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/tasks`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    };

    useEffect(() => {
        fetch(`${SERVER_URL}/software`,{
            method:"get",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.softwares.map((software) => ({
                    value: software.software_name,
                    label: software.software_name,
                }));
                setSoftwaresList(result);
            })
            .catch(error => console.error(error));
    }, [])

    const handleName = (event) => {
        setName(event.target.value.trim())
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!name || !selectedSoftware) {
            return;
        }

        setConfirmModal(true);
        setSave(true);
    }

    const handleGoBack = () => {
        navigate('/tasks')
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <form className="w-25" onSubmit={handleFormSubmit}>
                    <h1 className="h1 mb-3 fw-normal text-center font-weight-bold">Add Task</h1>
                    <div className="d-flex flex-column gap-3">
                        <label className="d-flex flex-column w-100">
                            Task
                            <input className="rounded" style={{ lineHeight: '33px' }} type="text" name="name" onChange={handleName} placeholder="Coding" required />
                        </label>
                        <label className="w-100">
                            Software
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="software"
                                options={softwaresList}
                                onChange={(selectedOption) => {
                                    // Handle the case when the selection is cleared
                                    const value = selectedOption ? selectedOption.value : null;
                                    setSelectedSoftware(value);
                                }}
                                value={
                                    selectedSoftware
                                        ? { value: selectedSoftware, label: selectedSoftware }
                                        : null
                                }
                                placeholder="Select Software"
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                isSearchable
                                isClearable
                            />
                        </label>
                    </div>
                    <br />
                    <div className="d-flex justify-content-between">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} type="submit">Add Task</button>
                    </div>
                    <br />
                </form>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};

export default AddTaskForm;