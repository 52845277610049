import React from "react";

function Footer() {

    return (
        <footer className="d-flex justify-content-between text-white" style={{ backgroundColor: "#202428", minHeight: '7vh' }}>
            <div>
            </div>
            <div className="p-3">
                <ul className="list-group" >
                    <li className="" style={{ backgroundColor: "#1717171", listStyleType: "none", color: '#ffffff' }}>
                        <span className="copy">Copyright © 2024 <span style={{ color: " #0092D1" }}>Sidvin </span> <span style={{ color: "#d35a53" }}>Outotec</span></span>
                    </li>
                </ul>
            </div>
            <div className="p-3">
                <ul className="list-group" >
                    <li className="" style={{ backgroundColor: "#1717171", listStyleType: "none", color: '#ffffff' }}>
                        <span className="version">v1.0</span>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;



