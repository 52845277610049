import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import Select from 'react-select';
import ConfirmModal from './ConfirmModal';
import { format } from "date-fns";
import MultiSelect from '../sample/ReactSelect';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, userHCode) => {
    const response = await fetch(`${SERVER_URL}/user/update/${userHCode}`, {
        method: 'PUT',
        credentials:"include",
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const UserModal = ({ user, showModal, closeModal, change }) => {
    const userHCode = user.hcode;
    const [userData, setUserData] = useState(user);
    const [disciplinesList, setDisciplinesList] = useState([]);
    const [designationsList, setDesignationsList] = useState([]);
    const [managersList, setManagersList] = useState([]);
    const [navbarOptions, setNavbarOptions] = useState([])  
    const [selectedNavbarOptions, setSelectedNavbarOptions] = useState([])
    const [navbarProvidedAccess, setNavbarProvidedAccess] = useState([])
    const [filteredNavbarOptions, setFilteredOptions] = useState([])

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(userData, userHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    const arrayOfValues = (arrayOfObjects) => {
        const result = arrayOfObjects.map((obj) => {
            return obj.value;
        })
        return result;
    }
 

    useEffect(()=>{
        if (selectedNavbarOptions) {
            setUserData((prevState) => ({
                 ...prevState,
                ["accessModule"]: arrayOfValues(selectedNavbarOptions),
            }));
        }
    },[selectedNavbarOptions])
   
    const fetchDisciplines = async () => {
        // Fetch disciplines from the server here
        fetch(`${SERVER_URL}/discipline`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.disciplines.map((discipline) => ({
                    value: discipline.discipline_name,
                    label: discipline.discipline_name,
                }));
                setDisciplinesList(result);
            })
            .catch(error => console.error(error));
    };

    const fetchDesignations = async () => {
        // Fetch designations from the server here
        fetch(`${SERVER_URL}/designation`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.designations.map((designation) => ({
                    value: designation.designation_name,
                    label: designation.designation_name,
                }));
                setDesignationsList(result);
            })
            .catch(error => console.error(error));
    };

    const fetchManagers = async () => {
        // Fetch users from the server here
        fetch(`${SERVER_URL}/user`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.users.map((user) => ({
                    value: user.login_name,
                    label: user.login_name + ' (' + user.emp_id + ')',
                }));
                setManagersList(result);
            })
            .catch(error => console.error(error));
    };

    // useEffect(()=>{
    //     fetch(`${SERVER_URL}/navbar/options/access/${userHCode}`,{
    //         method:"GET",
    //         credentials:"include"
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.statusCode === 200) {
    //                 setNavbarProvidedAccess(data.navbarAccess);
    //             }
    //         }
    //         )
    //         .catch(error => console.error(error));
    // },[])


    const fetchNavbarAccess = async()=>{
        fetch(`${SERVER_URL}/navbar/options/access/${userHCode}`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setNavbarProvidedAccess(data.navbarAccess);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            }
            )
            .catch(error => console.error(error));  
        }

   const fetchNavbarOptions = async()=>{
        fetch(`${SERVER_URL}/navbar/options`,{
            method:"GET",
            credentials:"include"
        })
       .then(response => response.json())
       .then(async data=>{
        if(data.statusCode === 401){
            LogoutIfNoHcode(dispatch, navigate)
        }

        // const result = await data.navbarOptions.map((navbar)=>({
        //     value : navbar.hcode,
        //     label : navbar.navbar_module_name
        // }))
        setNavbarOptions(data.navbarOptions)
       }).catch(error => console.error(error));
    }

    //filtering 
    useEffect(() => {
        const accessModuleNames = navbarProvidedAccess.map(item => item.navbar_module_name);
        const filteredOptions = navbarOptions.filter(option => !accessModuleNames.includes(option.navbar_module_name));
        const filteredOptionesResult = filteredOptions.map(navbar => ({
            value: navbar.hcode,
            label: navbar.navbar_module_name
        }));
        setFilteredOptions(filteredOptionesResult);
        }, [navbarProvidedAccess, navbarOptions]);


    useEffect(() => {
        setUserData(user);
        fetchDisciplines();
        fetchDesignations();
        fetchManagers();
        fetchNavbarOptions()
        fetchNavbarAccess()
    }, [user]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSelectChange = (selectedOption, fieldName) => {
        setUserData((prevState) => ({
            ...prevState,
            [fieldName]: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleRadioInputChange = (event) => {
        const { name, value, checked } = event.target;
        setUserData((prevState) => ({
            ...prevState,
            [name]: checked ? parseInt(value) : 0,
        }));
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'yyyy-MM-dd');
        return formattedDate;
    };

    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered size="xl">
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit Employee Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <div className='d-flex gap-3'>
                        <Form.Group controlId="emp_id" style={{ width: '30%' }}>
                            <Form.Label>Employee ID</Form.Label>
                            <Form.Control
                                type="text"
                                name="emp_id"
                                value={userData.emp_id}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="email_id" style={{ width: '70%' }}>
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control
                                type="email"
                                name="email_id"
                                value={userData.email_id}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="employee_name" style={{ width: '35%' }}>
                            <Form.Label>Employee Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="employee_name"
                                value={userData.employee_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        </div>
                        <br/>
                       <div className='d-flex gap-4'>
                        
                        <Form.Group controlId="created_on" style={{ width: '35%' }}>
                            <Form.Label>Joined Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="created_on"
                                value={formatDate(userData.created_on)}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="is_Admin" style={{ width: '35%', marginLeft:"50px" }}>
                            <Form.Label>Is Admin</Form.Label>
                            <div className="d-flex gap-3">
                                <Form.Check
                                    type="radio"
                                    label="Yes"
                                    name="is_Admin"
                                    value={1}
                                    checked={userData.is_Admin === 1}
                                    onChange={handleRadioInputChange}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="is_Admin"
                                    value={0}
                                    checked={userData.is_Admin === 0}
                                    onChange={handleRadioInputChange}
                                />
                            
                            </div>
                        </Form.Group>
                        <div style={{ width: '50%' }}>
                            <label>Module access</label>
                                <label className="w-100 d-flex flex-column">
                                 <MultiSelect options={filteredNavbarOptions} setSelectedOptions={setSelectedNavbarOptions} />
                            </label>
                            </div>
                        </div>
                        <br/>
                        <div className='d-flex gap-3' >
                        <Form.Group controlId="discipline_name" style={{ width: '60%' }}>
                            <Form.Label>Discipline Name</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="discipline_name"
                                options={disciplinesList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'discipline_name')}
                                value={
                                    userData.discipline_name
                                        ? { value: userData.discipline_name, label: userData.discipline_name }
                                        : null
                                }
                                placeholder="Select Discipline"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                                isClearable
                            />
                        </Form.Group>
                        <Form.Group controlId="designation_name" style={{ width: '60%' }}>
                            <Form.Label>Designation Name</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="designation_name"
                                options={designationsList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'designation_name')}
                                value={
                                    userData.designation_name
                                        ? { value: userData.designation_name, label: userData.designation_name }
                                        : null
                                }
                                placeholder="Select Designation"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                                isClearable
                            />
                        </Form.Group>
                        <Form.Group controlId="reporting_manager" style={{ width: '75%' }}>
                            <Form.Label>Reporting Manager</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="reporting_manager"
                                options={managersList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'reporting_manager')}
                                value={
                                    userData.reporting_manager
                                        ? { value: userData.reporting_manager, label: userData.reporting_manager }
                                        : null
                                }
                                placeholder="Select Reporting Manager"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                                isClearable
                            />
                        </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                </Button>
                </Modal.Footer>
            </Modal >
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};


export default UserModal;