import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';

const ConfirmDeleteModal = ({confirm, projectIndex, statusModal, closeStatusModal}) => {
    const handleYes = () => {
        confirm(projectIndex);
        closeStatusModal();
    }
     
    const handleNo=()=>{
        closeStatusModal()
    }

    return (
        <Modal show={statusModal} onHide={closeStatusModal} centered>
            <Modal.Header closeButton className={`${style['modal-header']}`}>
                <Modal.Title className={`${style['modal-tittle']}`}>Delete row</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${style['modal-body']}`}>
                <div className={"alert alert-dismissible fade show"} style={{backgroundColor:"yellow"}}>
                    <h5>Please confirm to delete the row</h5>
                </div>
            </Modal.Body>
            <Modal.Footer className={`${style['modal-footer']}`}>
                <Button variant="secondary" onClick={handleYes}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={handleNo}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default ConfirmDeleteModal;