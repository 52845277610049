import withAuth from "../../components/cookie/AuthenticatedComponent";
import Layout from '../../components/layout/Layout';
import AddProjectForm from '../../components/dashpage/AddProjectForm';


function DashAddProject() {
      
    return (
        <Layout>            
            <AddProjectForm></AddProjectForm>           
        </Layout>
    );
}

export default withAuth(DashAddProject);