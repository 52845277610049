import React, { useState } from "react";
import Alert from '../alert/Alert';
// import styles from './../../common/common.module.css';
import ConfirmModal from '../Modal/ConfirmModal';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AddClientForm = () => {
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [city, setCity] = useState('');

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        let formData = {
            client_code: code,
            client_name: name,
            client_city: city
        };

        fetch(`${SERVER_URL}/client/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if(data.statusCode === 401){
                        LogoutIfNoHcode(dispatch, navigate)
                    }
                    if (data.statusCode === 200) {
                        navigate(`/clients`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    }

    const navigate = useNavigate();

    const handleCode = (event) => {
        setCode(event.target.value.trim())
    };

    const handleName = (event) => {
        setName(event.target.value.trim())
    };

    const handleCity = (event) => {
        setCity(event.target.value.trim())
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!code || !name) {
            return;
        }

        setConfirmModal(true);
        setSave(true);
    };

    const handleGoBack = () => {
        navigate('/clients')
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <form className="w-25 p-3" onSubmit={handleFormSubmit}>
                    <h1 className="h1 mb-3 fw-normal text-center font-weight-bold">Add Client</h1>
                    <div className="form-floating">
                        <input className="form-control" type="text" name="code" onChange={handleCode} placeholder="" required />
                        <label htmlFor="code">Client Code</label>
                    </div>
                    <br />
                    <div className="form-floating">
                        <input className="form-control" type="text" name="name" onChange={handleName} placeholder="Metso" required />
                        <label htmlFor="name">Client Name</label>
                    </div>
                    <br />
                    <div className="form-floating">
                        <input type="text" className="form-control" name="city" onChange={handleCity} placeholder="Finland" />
                        <label htmlFor="city">Client City</label>
                    </div>
                    <br />
                    <div className="d-flex justify-content-between">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} type="submit">Add Client</button>
                    </div>
                    <br />
                </form>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};

export default AddClientForm;