import React, { useState, useEffect} from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import TaskModal from '../../Modal/TaskModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import DataTable from 'react-data-table-component';
import Alert from "../../alert/Alert"
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfTasks() {
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');
    const [searchText, setSearchText] = useState('');  
    const [filteredData, setFilteredData] = useState([]);
    const [responseData, setResponseData] = useState({})
    const [showAlert, setShowAlert] = useState(false)

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (task) => {
        await fetch(`${SERVER_URL}/task/delete/${task.hcode}`, {
            method: 'DELETE',
            mode: 'cors',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then(data =>{
            if(data.statusCode == 200){
                setResponseData(data)
                const updatedSw = tasks.filter(item => item.hcode !== task.hcode)
                setTasks(updatedSw)
                setFilteredData([])
                setSearchText("")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000);
            }
            if(data.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        })
    }

    useEffect(() => {
        fetch(`${SERVER_URL}/task`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.statusCode === 200) {
                    setTasks(data.tasks);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);


    const closeModal = () => {
        setShowModal(false);
    };

    const handleTaskEdit = (task) => {
        setSelectedTask(task);
        setShowModal(true);
    };

    const handleTaskDelete = (task) => {
        setSelectedTask(task);
        setConfirmModal(true);
        setSave(true);
    };

    const handleDownloadTasks = async () => {
        const fileURL = await fetch(`${SERVER_URL}/task/generate/report`,{
            method:'get',
            credentials:"include",
        });
        const file = await fileURL.blob();
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Tasks.xlsx`;
        link.click();
    };

   //react table column
   const columns = [
    {
        name: <span style={{ fontWeight: '800', marginLeft:"30%" }}>TASK</span>,
        selector: 'task_name',
        cell: row => <div style={{fontWeight:"500"}}>{row.task_name}</div>,
    },
    {
        name: <span style={{ fontWeight: '800' }}>SOFTWARE</span>,
        selector: 'software_name',
        cell: row => <div style={{fontWeight:"500"}}>{row.software_name}</div>,
        width: '150px'
    },
    {
        name :<span style={{ fontWeight: '800' }}>EDIT</span>,
        cell: row =>(<a style={{ cursor: 'pointer' }} onClick={() => handleTaskEdit(row)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a>),
        width: '100px'
    },
    {
        name :<span style={{ fontWeight: "800" }}>DELETE</span>,
        cell: row=>(<a style={{ cursor: 'pointer' }} onClick={() => handleTaskDelete(row)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a>),
        width: '100px'
    }

];

const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    // Filter the data based on the search text
    
    const filteredItems = tasks.filter(item =>
      item.task_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filteredItems);
  };

  const dataToDisplay = searchText.trim() === '' ? tasks : filteredData;

    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
                <h2 className="text-center w-100 pt-3">List of Tasks </h2>
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                <div className="p-3">
                    <div className="d-flex justify-content-between" style={{marginBottom:"15px"}}>
                        <button className={`rounded btn btn-secondary`} onClick={handleDownloadTasks}>
                            Download Tasks List
                        </button>
                        <button className={`rounded btn btn-secondary`} onClick={() => window.location.href = `/tasks/add`}>
                            Add Task
                        </button>
                    </div>

                    {/* <div className="w-100 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Software</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task) => (
                                    <tr key={task.hcode}>
                                        <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{task.task_name}</td>
                                        <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{task.software_name}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleTaskEdit(task)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleTaskDelete(task)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}
                    
                    <div className='w-100 flex align-items-end justify-content-between'>
                        <input
                         style={{ marginBottom: '10px', height:"40px" , width:"13.375rem"}}
                         type='text'
                         placeholder='search task'
                         value={searchText}
                         onChange={handleSearch}

                        /> 
                           
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={dataToDisplay}
                         />

                    </div>
                      
                </div>
            </div>
            {
                selectedTask && (
                    <TaskModal
                        task={selectedTask}
                        showModal={showModal}
                        closeModal={closeModal}
                        change={setChange}
                    />
                )
            }
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={selectedTask}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default ListOfTasks;
