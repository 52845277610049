
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import SearchProjectModal from "../Modal/SearchProjectModal";
import SearchTaskModal from "../Modal/SearchTaskModal";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const tdTextStyle = {
    border: "1px solid black",
    textAlign: "center",
    color: "black",
    width: "max-content",
    height: '63px',
    backgroundColor: 'transparent'
};


const TableRow = ({ project, projectIndex, dates,
    handleProjectNameChange, handleHourChange, handleTaskChange,
    handleSoftwareChange, handleProjectHcodeChange, handleTaskHcodeChange, handleDisciplineChange,
    handleRemarksChange, handleDocumentIDChange, handleOverTimeHourChange, handleNetworkHcodeChange, handleNetworkChange,
    handleDuplicateProject, selectedDate, handleDeleteProject, advanceView }) => {

    const [selectedTask, setSelectedTask] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [document_id, setDocument_id] = useState('');
    const [remarks, setRemarks] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [projectList, setProjectList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [networkList, setNetworkList] = useState([]);
    const [softwaresList, setSoftwaresList] = useState([]);
    const [selectedSoftware, setSelectedSoftware] = useState('');

    const [selectedDiscipline, setSelectedDiscipline] = useState('');
    const [disciplinesList, setDisciplinesList] = useState([]);

    const [searchProject, setSearchProject] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [employeeProjects, setEmployeeProjects] = useState([]);
    const [searchSelectedProject, setSearchSelectedProject] = useState('');

    const [searchTask, setSearchTask] = useState(false);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [employeeProjectTasks, setEmployeeProjectTasks] = useState([]);
    const [searchSelectedTask, setSearchSelectedTask] = useState('');


    useEffect(() => {
        if (project.document_id) {
            setDocument_id(project.document_id)
        } else {
            setDocument_id('')
        }
        if (project.remarks) {
            setRemarks(project.remarks)
        } else {
            setRemarks('')
        }
    }, [project.document_id, project.remarks, selectedDate]);



    useEffect(() => {
        if (project.phcode) {
            const obj = {
                hcode: project.phcode,
                project_id: project.projectID,
                project_name: project.projectName,
            }
            setSelectedProject(obj);
            handleProjectHcodeChange(projectIndex, project.phcode)
        }
        else if (project.pahcode) {
            const obj = {
                hcode: project.phcode,
                project_id: project.projectID,
                project_name: project.projectName,
            }
            setSelectedProject(obj);
            handleProjectHcodeChange(projectIndex, project.pahcode)
        } else {
            setSelectedProject('')
        }

        if (project.shcode) {
            setSelectedSoftware(project.software)
        }
        else {
            setSelectedSoftware('')
        }

        if (project.discipline) {
            setSelectedDiscipline(project.discipline);
        } else {
            setSelectedDiscipline('');
        }

    }, [])

    useEffect(() => {
        if (project.nhcode) {
            setSelectedNetwork(project.nhcode)
        } else if (project.nahcode) {
            setSelectedNetwork(project.nahcode)
        } else {
            setSelectedNetwork('')
        }
    }, [])

    useEffect(() => {
        if (project.thcode) {
            const foundObject = taskList.find(obj => obj.hcode === project.thcode);
            setSelectedTask(foundObject);
            handleTaskHcodeChange(projectIndex, project.thcode)
        } else if (project.tahcode) {
            const foundObject = taskList.find(obj => obj.hcode === project.thcode);
            setSelectedTask(foundObject)
            handleTaskHcodeChange(projectIndex, project.tahcode)
        } else {
            setSelectedTask('')
        }
    }, [])

    useEffect(() => {
        if (project.phcode) {
            const obj = {
                hcode: project.phcode,
                project_id: project.projectID,
                project_name: project.projectName,
            }
            setSelectedProject(obj);
        } else {
            setSelectedProject('')
        }

        if (project.shcode) {
            setSelectedSoftware(project.software)
        } else {
            setSelectedSoftware('')
        }

        if (project.discipline) {
            setSelectedDiscipline(project.discipline);
        } else {
            setSelectedDiscipline('');
        }

    }, [selectedDate, project, projectList])

    useEffect(() => {
        if (project.nhcode) {
            setSelectedNetwork(project.nhcode)
        } else if (project.nahcode) {
            setSelectedNetwork(project.nahcode)
        } else {
            setSelectedNetwork('')
        }
    }, [selectedDate, project, selectedProject, networkList]);

    useEffect(() => {
        if (project.thcode) {
            const foundObject = taskList.find(obj => obj.hcode === project.thcode);
            setSelectedTask(foundObject)
        } else {
            setSelectedTask('')
        }
    }, [selectedProject, project, selectedDate, taskList])

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${SERVER_URL}/workhours/projects`, {
                    method: 'GET',
                    credentials: "include",
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const projectData = await response.json();
                if (projectData.statusCode === 200) {
                    setProjectList(projectData.projects);
                    const activeProjects = projectData.projects.filter((item) => item.is_active !== 0);
                    setEmployeeProjects(activeProjects);
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetch(`${SERVER_URL}/software`, {
            method: 'GET',
            credentials: "include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(async data => {
                const result = await data.softwares.map((software) => ({
                    value: software.software_name,
                    label: software.software_name,
                }));
                setSoftwaresList(result);
            })
            .catch(error => console.error(error));

        fetchProjects();
    }, []);

    useEffect(() => {
        // Fetch tasks whenever the selected project changes
        const fetchTasks = async () => {
            try {
                if (selectedProject) {
                    const response = await fetch(`${SERVER_URL}/workhours/tasks/${selectedProject.hcode}`, {
                        method: 'GET',
                        credentials: "include",
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const taskData = await response.json();
                    if (taskData.statusCode === 200) {
                        setEmployeeProjectTasks(taskData.tasks);
                        setTaskList(taskData.tasks);
                    }
                }
            } catch (error) {
                console.error("Error fetching tasks:", error);
            }
        };

        const fetchProjectDiscipline = async () => {
            try {
                if (selectedProject) {
                    const response = await fetch(`${SERVER_URL}/project/discipline/${selectedProject.hcode}`, {
                        method: 'GET',
                        credentials: "include",
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const disiplineData = await response.json();
                    if (disiplineData.statusCode === 200) {
                        const result = await disiplineData.disciplines.map((discipline) => ({
                            value: discipline.discipline_name,
                            label: discipline.discipline_name,
                        }));
                        setDisciplinesList(result);
                    }
                }
            } catch (error) {
                console.error("Error fetching tasks:", error);
            }
        };


        fetchProjectDiscipline();
        fetchTasks();
    }, [selectedProject, searchSelectedProject]);

    useEffect(() => {
        const fetchNetwork = async () => {
            try {
                if (selectedProject) {
                    const response = await fetch(`${SERVER_URL}/network/ids/${selectedProject.hcode}`, {
                        method: 'GET',
                        credentials: "include",
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    if (data.statusCode === 200) {
                        setNetworkList(data.networks);
                        if (selectedNetwork === '') {
                            setSelectedNetwork(data.networks[0].hcode);
                            handleNetworkHcodeChange(projectIndex, data.networks[0].hcode)
                            handleNetworkChange(projectIndex, data.networks[0].network_id);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching Network:", error);
            }
        };

        fetchNetwork();
    }, [selectedProject])

    useEffect(() => {
        const fetchSoftware = async () => {
            try {
                if (selectedTask) {
                    const response = await fetch(`${SERVER_URL}/workhours/software/${selectedTask.hcode}`, {
                        method: 'GET',
                        credentials: "include",
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const softwareData = await response.json();
                    if (softwareData.statusCode === 200) {
                        if (selectedSoftware === '') {
                            setSelectedSoftware(softwareData.software[0].software_name);
                            handleSoftwareChange(projectIndex, softwareData.software[0].software_name);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching software:", error);
            }
        };

        fetchSoftware();
    }, [selectedTask]);


    useEffect(() => {
        if (selectedTask) {
            handleTaskChange(projectIndex, selectedTask.task_name)
            handleTaskHcodeChange(projectIndex, selectedTask.hcode)
        }
    }, [selectedTask])


    useEffect(() => {
        handleNetworkHcodeChange(projectIndex, selectedNetwork)
    }, [selectedNetwork])

    useEffect(() => {
        if (selectedProject) {
            handleProjectNameChange(projectIndex, selectedProject.project_id, selectedProject.project_name)
            handleProjectHcodeChange(projectIndex, selectedProject.hcode)
        }
    }, [selectedProject])

    const handleSearch = () => {
        setSearchProject(true);
        setShowModal(true);
    }

    const handleSelectedProject = (project) => {
        setSearchSelectedProject(project);
        handleProjectNameChange(projectIndex, project.project_id, project.project_name);
        setSelectedProject(project);
    };

    const closeModal = () => {
        setShowModal(false);
    }

    const handleTaskSearch = () => {
        setSearchTask(true);
        setShowTaskModal(true);
    }

    const handleSelectedTask = (task) => {
        setSearchSelectedTask(task);
        handleTaskChange(projectIndex, project.task_name);
        setSelectedTask(task);
    };

    const closeTaskModal = () => {
        setShowTaskModal(false);
    }



    return (
        <>
            <tr key={projectIndex}>
                <td style={tdTextStyle}>
                    <div className="d-flex">
                        <input
                            type="text"
                            value={selectedProject ? selectedProject.project_id + ' - ' + selectedProject.project_name : ''}
                            onChange={(e) => {
                                handleProjectNameChange(projectIndex, searchSelectedProject && searchSelectedProject.project_id ? searchSelectedProject.project_id : '', searchSelectedProject && searchSelectedProject.project_name ? searchSelectedProject.project_name : '');
                                setSelectedProject(searchSelectedProject ? searchSelectedProject : null);
                            }}
                            placeholder='search here ...'
                            style={{ width: '100%' }}
                        />
                        <button className="rounded" onClick={() => handleSearch()}>...</button>
                    </div>
                </td>
                <td style={tdTextStyle}>
                    <div className="d-flex">
                        <input
                            type="text"
                            value={selectedTask ? selectedTask.task_name : ''}
                            onChange={(e) => {
                                handleTaskChange(projectIndex, searchSelectedTask && searchSelectedTask.task_name ? searchSelectedTask.task_name : '');
                                setSelectedTask(searchSelectedTask ? searchSelectedTask : null);
                            }}
                            placeholder='search here ...'
                            style={{ width: '100%' }}
                        />
                        <button className="rounded" onClick={() => handleTaskSearch()}>...</button>
                    </div>
                </td>
                <td style={tdTextStyle}>
                    <select
                        className="rounded"
                        value={selectedNetwork}
                        onChange={(e) => {
                            handleNetworkChange(projectIndex,
                                networkList.find(network => network.hcode === e.target.value).network_id);
                            setSelectedNetwork(e.target.value);
                        }}
                        placeholder='Select a Project'
                        style={{ width: '100%', padding: '7px', backgroundColor: 'transparent', color: '#000000' }}
                    >
                        <option value="" disabled style={{ color: 'black' }}>Select Network</option>
                        {networkList.map((network) => (
                            <option key={network.hcode} value={network.hcode} style={{ color: 'black' }}>
                                {network.network_id}
                            </option>
                        ))}
                    </select>
                </td>
                <td style={tdTextStyle}>
                    <Select
                        className="rounded"
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                                color: "#000000",
                            })
                        }}
                        name="discipline"
                        options={disciplinesList}
                        onChange={(selectedOption) => {
                            // Handle the case when the selection is cleared
                            const value = selectedOption ? selectedOption.value : null;
                            handleDisciplineChange(projectIndex, value);
                            setSelectedDiscipline(value);
                        }}
                        value={
                            selectedDiscipline
                                ? { value: selectedDiscipline, label: selectedDiscipline }
                                : null
                        }
                        placeholder="Select Discipline"
                        menuPosition="fixed"
                        menuPlacement="bottom"
                        isSearchable
                        isClearable
                    />
                </td>
                <td style={tdTextStyle}>
                    <Select
                        className="rounded"
                        styles={{
                            option: (provided, state) => ({
                                ...provided,
                                color: "#000000",
                            })
                        }}
                        name="software"
                        options={softwaresList}
                        onChange={(selectedOption) => {
                            // Handle the case when the selection is cleared
                            const value = selectedOption ? selectedOption.value : null;
                            handleSoftwareChange(projectIndex, value);
                            setSelectedSoftware(value);
                        }}
                        value={
                            selectedSoftware
                                ? { value: selectedSoftware, label: selectedSoftware }
                                : null
                        }
                        placeholder="Select Software"
                        menuPosition="fixed"
                        menuPlacement="bottom"
                        isSearchable
                        required
                        isClearable
                    />
                </td>
                <td style={tdTextStyle}>
                    <input type="text" name="remarks"
                        className="rounded"
                        style={{ width: '100%', padding: '7px', backgroundColor: 'transparent', color: '#000000' }}
                        value={remarks}
                        onChange={(e) => {
                            handleRemarksChange(projectIndex, e.target.value);
                            setRemarks(e.target.value);
                        }}
                        placeholder="Document Description" />
                </td>
            </tr >
            {
                searchProject && (
                    <SearchProjectModal
                        handleSelectedProject={handleSelectedProject}
                        list={employeeProjects}
                        showModal={showModal}
                        closeModal={closeModal}
                    />
                )
            }
            {
                searchTask && (
                    <SearchTaskModal
                        handleSelectedTask={handleSelectedTask}
                        list={employeeProjectTasks}
                        showTaskModal={showTaskModal}
                        closeTaskModal={closeTaskModal}
                    />
                )
            }
        </>
    );
};

export default TableRow;
