import Layout from '../../components/layout/Layout';
import ListOfTasks from '../../components/tables/TaskTable/ListOfTasks';
// import { useNavigate } from 'react-router';
// import fetchHcode from "../../redux/hcodeFetch";
import withAuth from '../../components/cookie/AuthenticatedComponent';

function DashTasks() {

    // const getUser = fetchHcode() 
    //  const navigate = useNavigate()
    //  if(getUser.hcode === ""){
    //     navigate("/login")
    //  }

    return (
        <Layout>            
            <ListOfTasks></ListOfTasks>
        </Layout>
    );
}

export default withAuth(DashTasks) 