import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfBusinessLines from '../../components/tables/BusinessLineTable/ListOfBusinessLines';
// import fetchHcode from '../../redux/hcodeFetch';
// import { useNavigate } from 'react-router';

function DashBusinessLine() {
    // const getUser = fetchHcode() 
    //  const navigate = useNavigate()
    //  if(getUser.hcode === ""){
    //     navigate("/login")
    //  }
    return (
        <Layout>            
            <ListOfBusinessLines></ListOfBusinessLines>        
        </Layout>
    );
}

export default withAuth(DashBusinessLine);