import withAuth from '../../components/cookie/AuthenticatedComponent';
import ListOfProjects from '../../components/tables/ProjectTable/ListOfProjects';
import Layout from '../../components/layout/Layout';

function DashProjects() {
   
    return (
        <Layout>
            <ListOfProjects></ListOfProjects>
        </Layout>
    );
}

export default withAuth(DashProjects);