import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import ProjectAccessModal from '../../Modal/projectAccessModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function ListOfAccess() {
    const [projectAccess, setProjectAccess] = useState([]);

    const [showAccessModal, setShowAccessModal] = useState(false);
    const [usersAccess, setUsersAccess] = useState([]);

    const [provideAccess, setProvideAccess] = useState([]);
    const [removeAccess, setRemoveAccess] = useState([]);

    const [change, setChange] = useState('');
    const tableRef = useRef(null);

    const [selectedUser, setSelectedUser] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (user, projectHCode) => {
        const response = await fetch(`${SERVER_URL}/access/removeAccess/${projectHCode}/${user.hcode}`, {
            method: 'DELETE',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            window.location.reload();
        }
    };

    const handleRemoveAccess = async (user) => {
        setSelectedUser(user);
        setConfirmModal(true);
        setSave(true);
    };

    const { project_name, projectHCode, discipline_name } = useParams();

 
    // Function to open the modal when the "Provide Project Access" button is clicked
    const handleProvideAccess = () => {
        setShowAccessModal(true);
    };


    // Function to close the modal
    const closeModal = () => {
        setShowAccessModal(false);
    };


    useEffect(() => {
        fetch(`${SERVER_URL}/access/${projectHCode}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setProjectAccess(data.access);
                    setUsersAccess(data.access);
                } if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);


    useEffect(() => {
        if (projectAccess.length > 0) {
            $(tableRef.current).DataTable(
                {
                    destroy: true,
                    paging: false,
                }
            );
        }
    }, [projectAccess]);


    useEffect(() => {
        if (provideAccess.length > 0) {
            const data = {
                users: provideAccess,
                project_name: project_name
            }
            fetchData(data);
        }
        if (removeAccess.length > 0) {
            for (const user of removeAccess) {
                handleRemoveAccess(user);
            }
        }
    }, [provideAccess, removeAccess]);


    const fetchData = async (data) => {
        const response = await fetch(`${SERVER_URL}/access/provideAccess`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({
                users: data.users,
                project_name: data.project_name,
                project_hcode: projectHCode,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(response);
        return response;
    };


    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
                <h2 className="pt-3 text-center w-75 fw-normal" >Employee Access for <strong>{project_name}</strong> ( {discipline_name} )</h2>
                <div className="p-3 w-100 d-flex flex-column align-items-center">
                    <div className="d-flex justify-content-end w-75">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={handleProvideAccess}
                        >
                            Add Project Access
                        </button>
                        {showAccessModal && (
                            <ProjectAccessModal project_name={project_name} showAccessModal={showAccessModal} closeModal={closeModal} usersAccess={usersAccess} setUsersAccess={setUsersAccess} setProvideAccess={setProvideAccess} setRemoveAccess={setRemoveAccess} />
                        )}
                    </div>
                    <div className="w-75 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Employee ID</th>
                                    <th>Name</th>
                                    <th>Discipline</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {projectAccess.map((access) => (
                                    <tr key={access.hcode}>
                                        <td>{access.emp_id}</td>
                                        <td>{access.login_name}</td>
                                        <td>{access.discipline_name}</td>
                                        <td>
                                            <button className={`rounded btn btn-danger`} onClick={() => { handleRemoveAccess(access) }} > Remove Access </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        HCode={projectHCode}
                        obj={selectedUser}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default ListOfAccess;
