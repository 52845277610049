import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import style from './style/modal.module.css';
import styles from '../../common/common.module.css';
import styleInput from './style/projectAccessModal.module.css';
import ConfirmModal from './ConfirmModal';
import { LogoutIfNoHcode } from "../../common/LogoutIfNoHcode";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ProjectAccessModal = ({ showAccessModal, closeModal, usersAccess, setUsersAccess, setProvideAccess, setRemoveAccess }) => {
    const [usersList, setUsersList] = useState([]);
    const [filteredUsersList, setFilteredUsersList] = useState([]);

    const [accessUsersList, setAccessUsersList] = useState(usersAccess);
    const [filteredAccessUsersList, setFilteredAccessUsersList] = useState(usersAccess);

    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedAccessUser, setSelectedAccessUser] = useState([]);
    const [highlightUser, setHighlightUser] = useState([]);
    const [highlightAccessUser, setHighlightAccessUser] = useState([]);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const result = findMissingAndExtraObjects(accessUsersList, filteredAccessUsersList);
        setProvideAccess(result.extra);
        setRemoveAccess(result.missing);
        setUsersAccess(filteredAccessUsersList);
        closeModal();
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    useEffect(() => {
        fetch(`${SERVER_URL}/user`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const users = data.users;
                const filteredList = users.filter(obj1 =>
                    usersAccess.length === 0 || !usersAccess.some(obj2 => obj1.hcode === obj2.hcode)
                );
                setUsersList(filteredList);
                setFilteredUsersList(filteredList);
            })
            .catch((error) => console.error(error));
    }, []);

    const handleUserClick = (user) => {
        setSelectedAccessUser([]);
        setHighlightAccessUser('');

        if (selectedUser.some(selected => selected.hcode === user.hcode)) {
            // User is already selected, so remove them from the list
            setSelectedUser((prev) => prev.filter(selected => selected.hcode !== user.hcode));
            setHighlightUser((prev) => prev.filter(hcode => hcode !== user.hcode));
        } else {
            // User is not selected, so add them to the list
            setSelectedUser((prev) => [...prev, user]);
            setHighlightUser((prev) => [...prev, user.hcode]);
        }
    };


    const handleAccessUserClick = (accessUser) => {
        setSelectedUser([]);
        setHighlightUser('');

        if (selectedAccessUser.some(selected => selected.hcode === accessUser.hcode)) {
            // accessUser is already selected, so remove them from the list
            setSelectedAccessUser((prev) => prev.filter(selected => selected.hcode !== accessUser.hcode));
            setHighlightAccessUser((prev) => prev.filter(hcode => hcode !== accessUser.hcode));
        } else {
            // accessUser is not selected, so add them to the list
            setSelectedAccessUser((prev) => [...prev, accessUser]);
            setHighlightAccessUser((prev) => [...prev, accessUser.hcode]);
        }
    };


    const handleAddUserClick = (usersToAdd) => {
        if (!Array.isArray(usersToAdd)) {
            return; // Return early if usersToAdd is not an array
        }

        // Filter usersToAdd to exclude those already in filteredAccessUsersList
        const newUsersToAdd = usersToAdd.filter(user => !filteredAccessUsersList.includes(user));

        // Add the new users to filteredAccessUsersList
        if (newUsersToAdd.length > 0) {
            setFilteredAccessUsersList((prev) => [...prev, ...newUsersToAdd]);
        }

        // Filter filteredUsersList to exclude users with matching hcodes from usersToAdd
        const fArray = filteredUsersList.filter((obj) => !usersToAdd.some(user => user.hcode === obj.hcode));
        setFilteredUsersList(fArray);
    };

    const handleRemoveUserClick = (usersToRemove) => {
        if (!Array.isArray(usersToRemove)) {
            return; // Return early if usersToRemove is not an array
        }

        // Filter usersToRemove to exclude those already in filteredUsersList
        const newUsersToRemove = usersToRemove.filter(user => !filteredUsersList.includes(user));

        // Add the new users to filteredUsersList
        if (newUsersToRemove.length > 0) {
            setFilteredUsersList((prev) => [...prev, ...newUsersToRemove]);
        }

        // Filter filteredAccessUsersList to exclude users with matching hcodes from usersToRemove
        const fArray = filteredAccessUsersList.filter((obj) => !usersToRemove.some(user => user.hcode === obj.hcode));
        setFilteredAccessUsersList(fArray);
    };

    function findMissingAndExtraObjects(accessUsersList, filteredAccessUsersList) {
        const missingObjects = [];
        const extraObjects = [];

        for (const objX of accessUsersList) {
            if (!filteredAccessUsersList.some(objY => objY.hcode === objX.hcode)) {
                missingObjects.push(objX);
            }
        }

        for (const objY of filteredAccessUsersList) {
            if (!accessUsersList.some(objX => objX.hcode === objY.hcode)) {
                extraObjects.push(objY);
            }
        }

        return {
            missing: missingObjects,
            extra: extraObjects
        };
    }

    // Search for user 
    const handleUser = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredUsersList(usersList);
        }
        
        const fArray = usersList.filter((obj) => (
            (obj.employee_name + obj.emp_id + obj.discipline_name).toLowerCase().includes(value.toLowerCase())
        ));

        setFilteredUsersList(fArray);
    };

    // Search for accessUser 
    const handleUserAccess = (event) => {
        const value = event.target.value.trim();
        if (!value) {
            setFilteredAccessUsersList(accessUsersList);
        }
        const fArray = accessUsersList.filter((obj) => (obj.employee_name + obj.emp_id).includes(value));
        setFilteredAccessUsersList(fArray);
    };

    return (
        <>
            <Modal show={showAccessModal} onHide={closeModal} centered size="xl">
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Provide Access to the users</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <div className="d-flex flex-column gap-3" style={{ margin: 'auto', maxHeight: '65vh' }}>
                        <div className="d-flex flex-row justify-content-between mx-2" >
                            <input type="text" className="form-control" id="search" name="userSearch" style={{ width: '40%' }} onChange={handleUser} placeholder="Add Employee" autoComplete="off" required />
                            <input type="text" className="form-control" id="search2" name="userAccessSearch" style={{ width: '40%' }} onChange={handleUserAccess} placeholder="Existing Employee" autoComplete="off" required />
                        </div>
                        <div className="overflow-auto d-flex flex-row justify-content-between gap-2" style={{ minHeight: '50vh', maxHeight: '60vh' }} >
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '42%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {filteredUsersList.length > 0 ? filteredUsersList.map((user) => (
                                        <li key={user.hcode} onClick={() => { handleUserClick(user) }}
                                            className={`list-group-item ${highlightUser.includes(user.hcode) ? `${styleInput['highlight']}` : ''}`}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {user.employee_name + ' (' + user.emp_id + ')' + ' (' + user.discipline_name + ')'}
                                        </li>
                                    )) : <p>No matching records found. </p>}
                                </ul>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '6%' }}>
                                <button className={`btn-secondary rounded btn my-4`} onClick={() => handleAddUserClick(selectedUser)}> {`>>`} </button>
                                <button className={`btn ${styles['btn-style']} rounded my-4`} onClick={() => handleRemoveUserClick(selectedAccessUser)}> {`<<`} </button>
                            </div>
                            <div className='overflow-auto bg-transparent rounded p-1' style={{ width: '42%', boxShadow: '2px 2px 5px 1px rgba(0, 0, 0, 0.2)' }}>
                                <ul className="list-group">
                                    {
                                        filteredAccessUsersList.length > 0 ? (
                                            <ul className="list-group">
                                                {
                                                    filteredAccessUsersList.map((accessUser) => (
                                                        <li
                                                            key={accessUser.hcode}
                                                            className={`list-group-item ${highlightAccessUser.includes(accessUser.hcode) ? `${styleInput['highlight']}` : ''}`}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => { handleAccessUserClick(accessUser) }}
                                                        >
                                                            {accessUser.employee_name + ' (' + accessUser.emp_id + ')'}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        ) : (
                                            <p>{filteredAccessUsersList.length > 0 ? 'No matching records found.' : 'Users yet to be added.'}</p>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={handleSaveChanges}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    )
};

export default ProjectAccessModal;
