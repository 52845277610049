import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListofDisciplineManage from '../../components/tables/DisciplineManageTable/ListofDisciplineManage';

function DashDisciplineManage() {

    return (
        <Layout>
            <ListofDisciplineManage></ListofDisciplineManage>           
        </Layout>
    );
}

export default withAuth(DashDisciplineManage);