import React, { useState, useEffect, useContext } from 'react';
import AppContext from "../../../context/loginContext";
import 'datatables.net-bs5';
import 'datatables.net-colresize-unofficial';
import { format, startOfWeek, endOfWeek } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import styles from './button.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const formatDate = (dateString) => {
    if (!dateString) {
        return "N/A";
    }
    const date = new Date(dateString);
    const formattedDate = format(date, 'yyyy-MM-dd');
    return formattedDate;
};

const fetchDisciplinesAndUsers = async (setDisciplinesList, setSelectedDiscipline, setEmployeeList, selectedReport, isAdmin) => {
    try {
        let disciplinesList = [];
        if (isAdmin) {
            // Fetch all disciplines for admin
            const disciplinesResponse = await fetch(`${SERVER_URL}/discipline`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!disciplinesResponse.ok) {
                throw new Error(`API request failed with status: ${disciplinesResponse.status}`);
            }

            const disciplinesData = await disciplinesResponse.json();
            disciplinesList = disciplinesData.disciplines.map((discipline) => ({
                value: discipline.id,
                label: discipline.discipline_name,
            }));

            disciplinesList.unshift({ value: 'All', label: 'All' });
            setDisciplinesList(disciplinesList);
            setSelectedDiscipline(disciplinesList[0]);
        } else {
            // Fetch disciplines managed by the manager
            const disciplinesResponse = await fetch(`${SERVER_URL}/user/disciplines`, {
                method: "GET",
                credentials: "include"
            });

            if (!disciplinesResponse.ok) {
                throw new Error(`API request failed with status: ${disciplinesResponse.status}`);
            }

            const disciplinesData = await disciplinesResponse.json();

            if (disciplinesData.statusCode === 200) {
                disciplinesList = disciplinesData.disciplinesData.map((discipline) => ({
                    value: discipline.discipline_id_fk,
                    label: discipline.discipline_name,
                }));

                if (disciplinesList.length > 1 && selectedReport !== 'yearly-performance') {
                    disciplinesList.unshift({ value: 'All', label: 'All' });
                }

                setDisciplinesList(disciplinesList);
                setSelectedDiscipline(disciplinesList[0]);
            }
        }
        const usersResponse = await fetch(`${SERVER_URL}/user`, {
            method: "GET",
            credentials: "include"
        });

        if (!usersResponse.ok) {
            throw new Error(`API request failed with status: ${usersResponse.status}`);
        }

        const usersData = await usersResponse.json();

        const filteredUsers = isAdmin
            ? usersData.users
            : usersData.users.filter((user) =>
                disciplinesList.some((discipline) => discipline.label === user.discipline_name)
            );

        const employeeList = filteredUsers.map((employee) => ({
            value: employee.hcode,
            label: employee.employee_name,
        }));

        setEmployeeList(employeeList);
    } catch (error) {
        console.error(error);
    }
};

const ListOfRecords = () => {
    const { isAdmin } = useContext(AppContext);

    const previousDate = (days) => {
        const previous = new Date();
        previous.setDate(previous.getDate() - days);
        return previous;
    };

    const [selectedStartDate, setSelectedStartDate] = useState(previousDate(30));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());

    const [startOfWeekDate, setStartOfWeekDate] = useState(previousDate(7));
    const [endOfWeekDate, setEndOfWeekDate] = useState(new Date());

    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(new Date().getFullYear());
    const [disciplinesList, setDisciplinesList] = useState([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState(null);

    const [selectedReport, setSelectedReport] = useState('custom-date-range');
    const [selectedSubReport, setSelectedSubReport] = useState('weekly');
    const [isLoading, setIsLoading] = useState(false);
    const [isDiscipline, setIsDiscipline] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const generateYears = () => {
        const years = [];
        for (let i = 2022; i <= currentYear; i++) {
            years.push(i);
        }
        return years;
    };

    const handleYearChange = (event) => {
        const year = event.target.value;
        setSelectedYear(year);
    };

    const handleFinancialYearChange = (event) => {
        const year = event.target.value;
        setSelectedFinancialYear(year);
    };

    const handleDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const handleStartOfWeekDateChange = (date) => {
        setSelectedYear(new Date(date).getFullYear());
        setStartOfWeekDate(startOfWeek(date, { weekStartsOn: 1 }));
        setEndOfWeekDate(endOfWeek(date, { weekStartsOn: 1 }));
    };

    const handleDownload = async () => {
        setIsLoading(true); // Start loading
        try {
            const fileURL = await fetch(`${SERVER_URL}/report/extract/${formatDate(selectedStartDate)}/${formatDate(selectedEndDate)}?userHcode=${selectedEmployee ? selectedEmployee.value : ""}`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const file = await fileURL.blob();
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = `TimeSheetReport.xlsx`;
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleEmployeeReportDownload = async () => {
        if (!selectedDiscipline) {
            setIsDiscipline(true);
            return;
        }
        setIsDiscipline(false);
        setIsLoading(true); // Start loading
        try {
            const fileURL = await fetch(`${SERVER_URL}/report/employee/${formatDate(selectedStartDate)}/${formatDate(selectedEndDate)}/${selectedDiscipline ? selectedDiscipline.value : 'None'}`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const file = await fileURL.blob();
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Employee Man-Hours Report.xlsx`;
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleYearlyReportDownload = async () => {
        if (!selectedDiscipline) {
            setIsDiscipline(true);
            return;
        }
        setIsDiscipline(false);
        setIsLoading(true); // Start loading
        try {
            const fileURL = await fetch(`${SERVER_URL}/report/piping/${selectedYear}/${selectedDiscipline ? selectedDiscipline.value : `${isAdmin ? 'All' : 'One'}`}${selectedSubReport === 'weekly' ? `?startDate=${formatDate(startOfWeekDate)}&endDate=${formatDate(endOfWeekDate)}` : ``}`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const file = await fileURL.blob();
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Weekly project hours for ${selectedYear}.xlsx`;
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleFinancialYearReportDownload = async () => {
        if (!selectedDiscipline) {
            setIsDiscipline(true);
            return;
        }
        setIsDiscipline(false);
        setIsLoading(true); // Start loading
        try {
            const fileURL = await fetch(`${SERVER_URL}/report/mech/${selectedFinancialYear}/${selectedDiscipline ? selectedDiscipline.value : 'None'}`, {
                method: 'GET',
                credentials: "include",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const file = await fileURL.blob();
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Employee utilization report for ${selectedFinancialYear}.xlsx`;
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleReportTypeChange = (type) => {
        setSelectedReport(type);
        setIsDiscipline(false);
    };

    const handleCloseWarning = () => {
        setIsDiscipline(false);
    }

    useEffect(() => {
        fetchDisciplinesAndUsers(setDisciplinesList, setSelectedDiscipline, setEmployeeList, selectedReport, isAdmin);
    }, [isAdmin, selectedReport]);

    return (
        <div id="content" className='w-100 d-flex flex-column' style={{ minHeight: '79vh' }}>
            {isLoading && (
                <div class="p-3 d-flex align-items-center">
                    <strong role="status">Generating report, please wait...</strong>
                    <div class="spinner-border ms-auto" aria-hidden="true"></div>
                </div>
            )}
            <div className="p-3">
                <h3>Report Type</h3>
                <div className="d-flex flex-wrap gap-3">
                    <input
                        className='btn-check'
                        id='custom-date-range'
                        type="radio"
                        value="custom-date-range"
                        checked={selectedReport === 'custom-date-range'}
                        onChange={() => handleReportTypeChange('custom-date-range')}
                    />
                    <label className={`btn ${styles['btn-bd-primary']}`} for='custom-date-range'>
                        Timesheet report
                    </label>
                    <input
                        className='btn-check'
                        id='employee-man-hours'
                        type="radio"
                        value="employee-man-hours"
                        checked={selectedReport === 'employee-man-hours'}
                        onChange={() => handleReportTypeChange('employee-man-hours')}
                    />
                    <label className={`btn ${styles['btn-bd-primary']}`} for='employee-man-hours'>
                        Employee man-hours report
                    </label>
                    <input
                        className='btn-check'
                        id='yearly-performance'
                        type="radio"
                        value="yearly-performance"
                        checked={selectedReport === 'yearly-performance'}
                        onChange={() => handleReportTypeChange('yearly-performance')}
                    />
                    <label className={`btn ${styles['btn-bd-primary']}`} for='yearly-performance'>
                        Weekly project hours
                    </label>
                    <input
                        className='btn-check'
                        id='financial-year'
                        type="radio"
                        value="financial-year"
                        checked={selectedReport === 'financial-year'}
                        onChange={() => handleReportTypeChange('financial-year')}
                    />
                    <label className={`btn ${styles['btn-bd-primary']}`} for='financial-year'>
                        Employee utilization report
                    </label>
                </div>
                {isDiscipline && (
                    <div class="mt-3 p-3 d-flex align-items-center justify-content-between bg-warning w-25">
                        <strong role="status">Please select a Discipline</strong>
                        <strong role="status" style={{ cursor: "pointer" }} onClick={handleCloseWarning}>X</strong>
                    </div>
                )}
            </div>
            {selectedReport === 'custom-date-range' && (
                <div className='d-flex flex-column'>
                    <div className="p-3 w-100 d-flex flex-column">
                        <h3>Timesheet report</h3>
                        <div className="py-3 d-flex gap-3 align-items-end justify-content-between">
                            <div className='d-flex gap-3'>
                                <label className="d-flex flex-column">
                                    Start Date
                                    <DatePicker
                                        selected={selectedStartDate}
                                        useWeekdaysShort
                                        onChange={handleDateChange}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </label>
                                <label className="d-flex flex-column">
                                    End Date
                                    <DatePicker
                                        selected={selectedEndDate}
                                        useWeekdaysShort
                                        onChange={handleEndDateChange}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </label>
                                <div style={{ marginTop: "18px" }}>
                                    <Select
                                        className="d-flex flex-column"
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                color: "black",
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                width: 250,
                                            }),
                                        }}
                                        name="EmployeeName"
                                        options={employeeList}
                                        onChange={(selectedOption) => {
                                            setSelectedEmployee(selectedOption);
                                        }}
                                        value={
                                            selectedEmployee
                                                ? { value: selectedEmployee.value, label: selectedEmployee.label }
                                                : null
                                        }
                                        placeholder="Select Employee Name"
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        isSearchable
                                        isClearable
                                    />
                                </div>
                                <button className='btn btn-secondary align-self-end' onClick={handleDownload}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedReport === 'employee-man-hours' && (
                <div className='d-flex flex-column'>
                    <div className="p-3 w-100 d-flex flex-column">
                        <h3>Employee Man-Hours Report</h3>
                        <div className="py-3 d-flex gap-3 align-items-end justify-content-between">
                            <div className='d-flex gap-3'>
                                <label className="d-flex flex-column">
                                    Start Date
                                    <DatePicker
                                        selected={selectedStartDate}
                                        useWeekdaysShort
                                        onChange={handleDateChange}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </label>
                                <label className="d-flex flex-column">
                                    End Date
                                    <DatePicker
                                        selected={selectedEndDate}
                                        useWeekdaysShort
                                        onChange={handleEndDateChange}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </label>
                                <div style={{ marginTop: "18px" }}>
                                    <Select
                                        className="d-flex flex-column"
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                color: "black",
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                width: 250,
                                            }),
                                        }}
                                        name="DisciplineName"
                                        options={disciplinesList}
                                        onChange={(selectedOption) => {
                                            setSelectedDiscipline(selectedOption);
                                            setIsDiscipline(false);
                                        }}
                                        value={
                                            selectedDiscipline
                                                ? { value: selectedDiscipline.value, label: selectedDiscipline.label }
                                                : null
                                        }
                                        placeholder="Select Discipline Name"
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        isSearchable
                                        isClearable
                                    />
                                </div>
                                <button className='btn btn-secondary align-self-end' onClick={handleEmployeeReportDownload}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedReport === 'yearly-performance' && (
                <div className='d-flex flex-column'>
                    <div className="p-3 w-100 d-flex flex-column">
                        <h3>Weekly project hours</h3>
                        <div className="d-flex flex-wrap gap-3">
                            <input
                                className='btn-check'
                                id='weekly'
                                type="radio"
                                value="weekly"
                                checked={selectedSubReport === 'weekly'}
                                onChange={() => setSelectedSubReport('weekly')}
                            />
                            <label className={`btn ${styles['btn-bd-primary']}`} for='weekly'>
                                Weekly report
                            </label>
                            <input
                                className='btn-check'
                                id='yearly'
                                type="radio"
                                value="yearly"
                                checked={selectedSubReport === 'yearly'}
                                onChange={() => setSelectedSubReport('yearly')}
                            />
                            <label className={`btn ${styles['btn-bd-primary']}`} for='yearly'>
                                Yearly report
                            </label>
                        </div>
                        {selectedSubReport === 'yearly' &&
                            <div className="py-3 d-flex gap-3 align-items-end justify-content-between">
                                <div className='d-flex gap-3'>
                                    <label className="d-flex flex-column">
                                        Select Year
                                        <select
                                            id="year-selector"
                                            value={selectedYear}
                                            onChange={handleYearChange}
                                            style={{ height: '32px' }}
                                        >
                                            {generateYears().map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    <div style={{ marginTop: "18px" }}>
                                        <Select
                                            className="d-flex flex-column"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: "black",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: 250,
                                                }),
                                            }}
                                            name="DisciplineName"
                                            options={disciplinesList}
                                            onChange={(selectedOption) => {
                                                setSelectedDiscipline(selectedOption);
                                                setIsDiscipline(false);
                                            }}
                                            value={
                                                selectedDiscipline
                                                    ? { value: selectedDiscipline.value, label: selectedDiscipline.label }
                                                    : null
                                            }
                                            placeholder="Select Discipline Name"
                                            menuPosition="fixed"
                                            menuPlacement="bottom"
                                            isSearchable
                                            isClearable
                                        />
                                    </div>
                                    <button className='btn btn-secondary align-self-end' onClick={handleYearlyReportDownload}>Download</button>
                                </div>
                            </div>
                        }
                        {selectedSubReport === 'weekly' &&
                            <div className="py-3 d-flex gap-3 align-items-end justify-content-between">
                                <div className='d-flex gap-3'>
                                    <label className="d-flex flex-column">
                                        Start Date
                                        <DatePicker
                                            selected={startOfWeekDate}
                                            useWeekdaysShort
                                            onChange={handleStartOfWeekDateChange}
                                            dateFormat="dd-MM-yyyy"
                                            maxDate={new Date()}
                                        />
                                    </label>
                                    <label className="d-flex flex-column">
                                        End Date
                                        <DatePicker
                                            selected={endOfWeekDate}
                                            useWeekdaysShort
                                            // onChange={}
                                            dateFormat="dd-MM-yyyy"
                                            disabled
                                        />
                                    </label>
                                    <div style={{ marginTop: "18px" }}>
                                        <Select
                                            className="d-flex flex-column"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: "black",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: 250,
                                                }),
                                            }}
                                            name="DisciplineName"
                                            options={disciplinesList}
                                            onChange={(selectedOption) => {
                                                setSelectedDiscipline(selectedOption);
                                                setIsDiscipline(false);
                                            }}
                                            value={
                                                selectedDiscipline
                                                    ? { value: selectedDiscipline.value, label: selectedDiscipline.label }
                                                    : null
                                            }
                                            placeholder="Select Discipline Name"
                                            menuPosition="fixed"
                                            menuPlacement="bottom"
                                            isSearchable
                                            isClearable
                                        />
                                    </div>
                                    <button className='btn btn-secondary align-self-end' onClick={handleYearlyReportDownload}>Download</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )}
            {selectedReport === 'financial-year' && (
                <div className='d-flex flex-column'>
                    <div className="p-3 w-100 d-flex flex-column">
                        <h3>Employee utilization report</h3>
                        <div className="py-3 d-flex gap-3 align-items-end justify-content-between">
                            <div className='d-flex gap-3'>
                                <label className="d-flex flex-column">
                                    Select Financial Year
                                    <select
                                        id="financial-year-selector"
                                        value={selectedFinancialYear}
                                        onChange={handleFinancialYearChange}
                                    >
                                        {generateYears().map((year) => (
                                            <option key={year} value={year}>
                                                FY {year} - {year + 1}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <div style={{ marginTop: "18px" }}>
                                    <Select
                                        className="d-flex flex-column"
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                color: "black",
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                width: 250,
                                            }),
                                        }}
                                        name="DisciplineName"
                                        options={disciplinesList}
                                        onChange={(selectedOption) => {
                                            setSelectedDiscipline(selectedOption);
                                            setIsDiscipline(false);
                                        }}
                                        value={
                                            selectedDiscipline
                                                ? { value: selectedDiscipline.value, label: selectedDiscipline.label }
                                                : null
                                        }
                                        placeholder="Select Discipline Name"
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        isSearchable
                                        isClearable
                                    />
                                </div>
                                <button className='btn btn-secondary align-self-end' onClick={handleFinancialYearReportDownload}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListOfRecords;
