import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import AddHolidayForm from "../../components/dashpage/AddHolidayForm";

function DashAddHoliday() {
    
    return (
        <Layout>
            <AddHolidayForm></AddHolidayForm>
        </Layout>
    );
}

export default withAuth(DashAddHoliday);