
import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import AddTaskForm from '../../components/dashpage/AddTaskForm';

function DashAddTask() {
     
    return (
        <Layout>
            <AddTaskForm></AddTaskForm>            
        </Layout>
    );
}

export default withAuth(DashAddTask);