import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import styles from './SetPasswordForm.module.css';
import Alert from '../alert/Alert';
import CryptoJS from 'crypto-js';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function validatePassword(password) {
    const MIN_LENGTH = 8;
    const UPPERCASE_REGEX = /[A-Z]/;
    const LOWERCASE_REGEX = /[a-z]/;
    const SPECIAL_CHAR_REGEX = /[@$!%*?&]/;

    if (password.length < MIN_LENGTH) {
        return false;
    }

    if (!UPPERCASE_REGEX.test(password)) {
        return false;
    }

    if (!LOWERCASE_REGEX.test(password)) {
        return false;
    }

    if (!SPECIAL_CHAR_REGEX.test(password)) {
        return false;
    }

    return true;
}



function SetPasswordsForm() {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkLink, setCheckLink] = useState(null);
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`${SERVER_URL}/resetpwd${window.location.search}`, { method: 'GET' });
            const data = await response.json();
            if (data.statusCode !== 200) {
                setCheckLink(false);
                setResponseData({
                    statuscode: 200,
                    message: 'The link might not be correct or may have expired.'
                })
                setShowAlert(true);
            } else {
                setCheckLink(true)
            }
        }
        fetchData();
    }, [])

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    }

    const navigate = useNavigate();

    const handleFormSubmit = (event) => {
        event.preventDefault();
        
        if (validatePassword(password) && (confirmPassword === password)) {
            const encryptedPassword = CryptoJS.SHA256(password).toString();
            fetch(`${SERVER_URL}/resetpwd${window.location.search}`, {

                method: 'POST',
                // Convert the form data object to a JSON string and include it in the request body
                body: JSON.stringify({
                    password: encryptedPassword
                }),
                headers: {
                    // Set the Content-Type header to application/json
                    // Tells the server that the content type of the request body is JSON.
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json()) // Parse the response body as JSON
                .then(data => {
                    setResponseData(data);
                    setShowAlert(true);

                    setTimeout(() => {
                        navigate('/login', { replace: true });
                    }, 2000)
                    setTimeout(() => setShowAlert(false), 1000);
                })
                .catch(error => console.error(error)); // Log any errors to the console
        } else {
            setShowAlert(true);
            setResponseData({
                statuscode: 200,
                message: 'Invalid password'
            })
            setTimeout(() => setShowAlert(false), 2000);
        }
    }

    return (
        <div className={styles['form-signin']}>
            <form method="post" onSubmit={checkLink ? handleFormSubmit : undefined}>
                <div id="msg" className="d-flex justify-content-center align-items-center" >
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <h1 className="text-center">Set Password</h1>
                <p style={{ color: '#8a9198' }}>
                    Password should contain Uppercase, Lowercase and Special Character with minimum length of 8.
                    Password and Confirm Password should be same.
                </p>
                <div className="py-2">
                    <label htmlFor="password" className="h4" >Password</label>
                    <input type="password" className="form-control" placeholder="Password" name="password" onChange={handlePassword} disabled={!checkLink} required />
                </div>
                <div className="py-2">
                    <label htmlFor="cpassword" className="h4" >Confirm Password</label>
                    <input type="password" className="form-control " name='cpassword' placeholder="Password" onChange={handleConfirmPassword} disabled={!checkLink} required />
                </div>
                <div className="py-2 d-flex justify-content-between align-items-center">
                    <Link to="/forgotpassword" className={`btn btn-outline-secondary`}>Forgot Password?</Link>
                    <button className={`btn btn-secondary`} type="submit" disabled={!checkLink}>Change Password</button>
                </div>
            </form>
        </div>
    );
}


export default SetPasswordsForm;