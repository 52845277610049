import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfUsers from '../../components/tables/UserTable/ListOfUsers';

function DashUsers() {

    return (
        <Layout>
            <ListOfUsers></ListOfUsers>
        </Layout>
    );
}

export default withAuth(DashUsers);