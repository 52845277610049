
import React from "react";
import AppContext from "../../context/loginContext";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import fetchHcode from "../../redux/hcodeFetch";
import Layout from '../../components/layout/Layout';

import styles from './SetPassword.module.css';
import SetPasswordForm from '../../components/set-pass/SetPasswordForm';


function SetPasswordPage() {
    const { isLogin} = useContext(AppContext);
    const navigate = useNavigate();
    const userDetails = fetchHcode()  

    useEffect(() => {
        if (userDetails.hcode !== "") {
            navigate('/timesheet')
        }
    }, []);

    return (
        <Layout>          
            <div className={styles['div-style']} style={{minHeight: '86vh'}}>
                <SetPasswordForm />
            </div>
        </Layout>
    );
}
export default SetPasswordPage;