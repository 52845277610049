import React, { useState, useEffect, useRef } from 'react';
import 'datatables.net-bs5';
import SoftwareModal from '../../Modal/SoftwareModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import DataTable from 'react-data-table-component';
import Alert from "../../alert/Alert"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfSoftwares() {
    const [softwares, setSoftwares] = useState([]);
    const [selectedSoftware, setSelectedSoftware] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');
    const [searchText, setSearchText] = useState('');  
    const [filteredData, setFilteredData] = useState([]);
    const [showAlert, setShowAlert] = useState(false)
    const [responseData, setResponseData] = useState({})

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (software) => {
         await fetch(`${SERVER_URL}/software/delete/${software.hcode}`, {
            method: 'DELETE',
            credentials:"include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then(data =>{
            if(data.statusCode == 200){
                setResponseData(data)
                const updatedSw = softwares.filter(item => item.hcode !== software.hcode)
                setSoftwares(updatedSw)
                setFilteredData([])
                setSearchText("")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000);
            }
            if(data.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        })
      
    }

    useEffect(() => {
        fetch(`${SERVER_URL}/software`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setSoftwares(data.softwares);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);
    
    const closeModal = () => {
        setShowModal(false);
    };
    
    const handleSoftwareEdit = (software) => {
        setSelectedSoftware(software);
        setShowModal(true);
    };

    const handleSoftwareDelete = (software) => {
        setSelectedSoftware(software);
        setConfirmModal(true);
        setSave(true);
    };

   ///react table testing
   const columns = [
    {
        name: <span style={{ fontWeight: '800' }}>SOFTWARE NAME</span>,
        selector: 'software_name',
        cell: row => <div style={{fontWeight:"500"}}>{row.software_name}</div>,
    },
    {
        name :<span style={{ fontWeight: '800' }}>EDIT</span>,
        cell: row =>(<a style={{ cursor: 'pointer' }} onClick={() => handleSoftwareEdit(row)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a>),
        width: '200px'
    },
    {
        name :<span style={{ fontWeight: "800" }}>DELETE</span>,
        cell: row=>(<a style={{ cursor: 'pointer' }} onClick={() => handleSoftwareDelete(row)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a>),
        width: '200px'
    }

];

const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    // Filter the data based on the search text
    
    const filteredItems = softwares.filter(item =>
      item.software_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filteredItems);
  };

  const dataToDisplay = searchText.trim() === '' ? softwares : filteredData;

    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
                <h2 className="text-center w-75 pt-3">List of Softwares </h2>
                <div className="p-3 w-75 d-flex flex-column align-items-center">
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                    <div className="d-flex justify-content-end w-75">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={() => window.location.href = `/softwares/add`}
                        >
                            Add Software
                        </button>
                    </div>
                    
                   {/* <div className="w-75 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Software Name</th>
                                
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {softwares.map((software) => (
                                    <tr key={software.hcode}>
                                        <td>{software.software_name}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleSoftwareEdit(software)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleSoftwareDelete(software)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>  */}
                    <div className='w-75 flex align-items-end justify-content-between'>
                        <input
                         style={{ marginBottom: '10px'}}
                         type='text'
                         placeholder='search software'
                         value={searchText}
                         onChange={handleSearch}

                        /> 
                           
                        
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={dataToDisplay}
                         />

                    </div>

                </div>
            </div>
            {
                selectedSoftware && (
                    <SoftwareModal
                        software={selectedSoftware}
                        showModal={showModal}
                        closeModal={closeModal}
                        change={setChange}
                    />
                )
            }
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={selectedSoftware}
                        msg={msg}
                    />
                )
            }
        </>
    );
}


export default ListOfSoftwares;
