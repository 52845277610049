import React, { useState, useEffect, useRef } from 'react';
import { useParams} from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import ProjectTasksModal from '../../Modal/projectTasksModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';
import DataTable from 'react-data-table-component';
import Alert from '../../alert/Alert';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function ListOfProjectTasks() {
    const [tasks, setTasks] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [tasksAccess, setTasksAccess] = useState([]);

    const [provideAccess, setProvideAccess] = useState([]);
    const [removeAccess, setRemoveAccess] = useState([]);

    const [change, setChange] = useState('');
    const tableRef = useRef(null);

    const [selectedTask, setSelectedTask] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');
    const [showAlert, setShowAlert] = useState(false)
    const [responseData, setResponseData] = useState({})

    const dispatch = useDispatch()
    const navigate = useNavigate()
     
    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (task) => {
        await fetch(`${SERVER_URL}/project/task/delete/${task.pthcode}`, {
            method: 'DELETE',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then((data)=>{
            if (data.statusCode === 200) {
                setResponseData(data)
                const updatedSw = tasks.filter(item => item.hcode !== task.hcode)
                setTasks(updatedSw)
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000);
            }
            if(data.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        })
        
    };

    const handleRemoveTask = async (task) => {
        setSelectedTask(task);
        setConfirmModal(true);
        setSave(true);
    };

    const { project_name, projectHCode, discipline_name } = useParams();

    // Function to open the modal when the "Provide Project Tasks" button is clicked
    const handleProvideTasks = () => {
        setShowModal(true);
    };


    // Function to close the modal
    const closeModal = () => {
        setShowModal(false);
    };


    useEffect(() => {
        fetch(`${SERVER_URL}/project/task/${projectHCode}`,{
            method:"GET",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setTasks(data.tasks);
                    setTasksAccess(data.tasks);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);

    useEffect(() => {
        if (provideAccess.length > 0) {
            const data = {
                tasks: provideAccess,
                projectHCode: projectHCode
            }
            fetchData(data);
        }
        if (removeAccess.length > 0) {
            for (const task of removeAccess) {
                handleRemoveTask(task);
            }
        }
    }, [provideAccess, removeAccess]);


    const fetchData = async (data) => {
        const response = await fetch(`${SERVER_URL}/project/task/add`, {
            method: 'POST',
            credentials:"include",
            mode: 'cors',
            body: JSON.stringify({
                tasks: data.tasks,
                projectHCode: data.projectHCode
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(response);
        return response;
    };

    const columns = [
        {
            name: <span style={{ fontWeight: '800' }}>TASK NAME</span>,
            selector: 'task_name',
            cell: row => <div style={{fontWeight:"500"}}>{row.task_name}</div>,
        },
        {
            name :<span style={{ fontWeight: "800" }}>DELETE</span>,
            cell: row=>(<button className={`rounded btn btn-danger`} onClick={() => { handleRemoveTask(row) }} > Remove Task </button>),
            width: '200px'
        }
    
    ];

    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
                <h2 className="pt-3 text-center w-75 fw-normal" >Tasks for <strong>{project_name}</strong> ( {discipline_name} )</h2>
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                <div className="p-3 w-75 d-flex flex-column align-items-center">
                    <div className="w-100 d-flex justify-content-end">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={handleProvideTasks}
                        >
                            Add Task
                        </button>
                        {showModal && (
                            <ProjectTasksModal project_name={project_name} showModal={showModal} closeModal={closeModal} tasksAccess={tasksAccess} setTasksAccess={setTasksAccess} setProvideAccess={setProvideAccess} setRemoveAccess={setRemoveAccess} />
                        )}
                    </div>

                    {/* <div className="w-100 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Task Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task) => (
                                    <tr key={task.pthcode}>
                                        <td>{task.task_name}</td>
                                        <td>
                                            <button className={`rounded btn btn-danger`} onClick={() => { handleRemoveTask(task) }} > Remove Task </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}
                     <div className='w-75 flex align-items-end justify-content-between'>
                        
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={tasks}
                         />

                    </div>
                  

                </div>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={selectedTask}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default ListOfProjectTasks;
