import withAuth from '../../components/cookie/AuthenticatedComponent';
import ProjectDetails from '../../components/Project/ProjectDetails';
import Layout from '../../components/layout/Layout';

function DashProjectDetails() {
  
    return (
        <Layout>            
            <ProjectDetails></ProjectDetails>
        </Layout>
    );
}

export default withAuth(DashProjectDetails);