
import Layout from '../../components/layout/Layout';
import AddBusinessForm from "../../components/dashpage/AddBusinessForm";
import withAuth from '../../components/cookie/AuthenticatedComponent';

function DashAddBusinessLine() {
   
    return (
        <Layout>
            <AddBusinessForm></AddBusinessForm>
        </Layout>
    );
}

export default withAuth(DashAddBusinessLine);