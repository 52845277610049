import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, softwareHCode) => {
    const response = await fetch(`${SERVER_URL}/software/update/${softwareHCode}`, {
        method: 'PUT',
        credentials:"include",
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const SoftwareModal = ({ software, showModal, closeModal, change }) => {
    const softwareHCode = software.hcode;
    const [softwareData, setSoftwareData] = useState(software);
    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);    
    const [msg, setMsg] = useState('Are you sure?');

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(softwareData, softwareHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    useEffect(() => {
        setSoftwareData(software);
    }, [software]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSoftwareData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };



    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit software</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="software_name">
                            <Form.Label>Software Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="software_name"
                                value={softwareData.software_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        {/* <Form.Group controlId="software_desc">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                // as="textArea"
                                type="text"
                                name="software_desc"
                                value={softwareData.software_desc}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};


export default SoftwareModal;