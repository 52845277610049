import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import NavbarComponent from "../navbar/Navbar";

function Layout({ children }) {

  return (
    <>
      <Header />
      <NavbarComponent></NavbarComponent>
      {children}
      <Footer />
    </>
  );
}

export default Layout;
