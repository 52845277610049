import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import Select from 'react-select';
import ConfirmModal from './ConfirmModal';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';



const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const fetchData = async (data, projectHCode) => {
    const response = await fetch(`${SERVER_URL}/project/discipline/${projectHCode}`, {
        method: 'POST',
        mode: 'cors',
        credentials:"include",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const ProjectDisciplineModal = ({ project, showModal, closeModal, change, proDis }) => {
    const projectHCode = project.hcode;
    const [projectData, setProjectData] = useState(project);

    const [disciplinesList, setDisciplinesList] = useState([]);


    const [projectDisciplines, setProjectDisciplines] = useState();

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData(projectData, projectHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };


    const fetchDisciplines = async () => {
        
        fetch(`${SERVER_URL}/discipline`,{
            method:"GET",
            credentials:"include"
        })
            .then(response => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.disciplines.filter(discipline => !proDis.some(pd => pd.hcode === discipline.hcode))
                .map((discipline) => ({
                    value: discipline.discipline_name,
                    label: discipline.discipline_name,
                }));
                setDisciplinesList(result);
            })
            .catch(error => console.error(error));
    };


    useEffect(() => {
        setProjectData(project);
        const disciplineNames = proDis.map(obj => obj.discipline_name);
        const finalDiscipline = disciplineNames.join(', ');
        setProjectDisciplines(finalDiscipline);
        fetchDisciplines();
    }, [project]);


    const handleSelectChange = (selectedOption, fieldName) => {
        setProjectData((prevState) => ({
            ...prevState,
            [fieldName]: selectedOption ? selectedOption.value: null,
        }));
    };


    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };


    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered size=''>
                <Modal.Header closeButton className={`${style['modal-header']}`} >
                    <Modal.Title className={`${style['modal-tittle']}`}>Add Project Discipline</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="projectID">
                            <Form.Label style={{ fontWeight: 'bold' }}>Project ID</Form.Label>
                            <Form.Control
                                type="text"
                                name="project_id"
                                value={projectData.project_id}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="projectName">
                            <Form.Label style={{ fontWeight: 'bold' }}>Project Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="project_name"
                                value={projectData.project_name}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="discipline">
                            <Form.Label style={{ fontWeight: 'bold' }}>Current Disciplines</Form.Label>
                            <Form.Control
                                type="text"
                                name="discipline"
                                value={projectDisciplines}
                                disabled
                            />
                        </Form.Group>

                        <Form.Group controlId="discipline_name">
                            <Form.Label style={{ fontWeight: 'bold' }}>Discipline</Form.Label>
                            <Select
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: "#000000",
                                    })
                                }}
                                name="discipline_name"
                                options={disciplinesList}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'discipline_name')}
                                value={
                                    projectData.discipline_name
                                        ? { value: projectData.discipline_name, label: projectData.discipline_name }
                                        : null
                                }
                                placeholder="Select Discipline"
                                isSearchable
                                menuPosition="fixed"
                                menuPlacement="bottom"
                                required
                                isClearable
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};


export default ProjectDisciplineModal;