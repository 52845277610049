import React, { useState, useEffect, useRef, useContext } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-colresize-unofficial';
import styles from '../../../common/common.module.css';
import Checkbox from "../../Checkbox/Checkbox";
import { format, startOfWeek, getWeek } from "date-fns";
import RejectCommentsModal from '../../Modal/RejectCommentsModal';
import Select from 'react-select';
import ApprovalStatusModal from '../../Modal/ApprovalStatusModal';
import fetchHcode from '../../../redux/hcodeFetch';
import AppContext from '../../../context/loginContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const previousDate = () => {
    const previous = new Date();
    previous.setDate(previous.getDate() - 30);
    return previous;
}

const fetchEmployees = async (setEmployeeList, isAdmin) => {
    let disciplinesList = [];
    if (!isAdmin) {// Fetch disciplines managed by the manager
        const disciplinesResponse = await fetch(`${SERVER_URL}/user/disciplines`, {
            method: "GET",
            credentials: "include"
        });

        if (!disciplinesResponse.ok) {
            throw new Error(`API request failed with status: ${disciplinesResponse.status}`);
        }

        const disciplinesData = await disciplinesResponse.json();

        if (disciplinesData.statusCode === 200) {
            disciplinesList = disciplinesData.disciplinesData.map((discipline) => ({
                value: discipline.discipline_id_fk,
                label: discipline.discipline_name,
            }));
        }
    }

    const usersResponse = await fetch(`${SERVER_URL}/user`, {
        method: "GET",
        credentials: "include"
    });

    if (!usersResponse.ok) {
        throw new Error(`API request failed with status: ${usersResponse.status}`);
    }

    const usersData = await usersResponse.json();

    const filteredUsers = isAdmin
        ? usersData.users
        : usersData.users.filter((user) =>
            disciplinesList.some((discipline) => discipline.label === user.discipline_name)
        );

    const employeeList = filteredUsers.map((employee) => ({
        value: employee.hcode,
        label: employee.employee_name,
    }));

    setEmployeeList(employeeList);
}

const ListOfApprovals = () => {
    const { isAdmin } = useContext(AppContext);

    const [approvals, setApprovals] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [comments, setComments] = useState([]);
    // const [commented, setCommented] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState(false);
    const tableRef = useRef(null);

    const [selectedStartDate, setSelectedStartDate] = useState(format(previousDate(), 'yyyy-MM-dd'));
    const [selectedEndDate, setSelectedEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [statusList, setStatusList] = useState([{ value: 1, label: 'Approved' }, { value: 2, label: 'Submitted' }]);
    const [selectedStatus, setSelectedStatus] = useState({ value: 2, label: 'Submitted' });

    const [statusModal, setStatusModal] = useState(false);
    const [statusData, setStatusData] = useState('');

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(); // Number of items per page
    const [totalPages, setTotalPages] = useState(0);

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const getUser = fetchHcode()

    const closeModal = () => {
        setShowModal(false);
    }

    const closeStatusModal = () => {
        setStatusModal(false);
    }

    const handleDateChange = (event) => {
        setSelectedStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate(event.target.value);
    };

    useEffect(() => {
        setPageSize(10);
        fetch(`${SERVER_URL}/user`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then(async data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                const result = await data.users.map((employee) => ({
                    value: employee.hcode,
                    label: employee.employee_name,
                }));
                setEmployeeList(result);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        fetchApprovals();
    }, []);

    const fetchApprovals = () => {
        fetch(`${SERVER_URL}/approvals?page=${page}&pageSize=${pageSize}&employee=${selectedEmployee ? selectedEmployee.value : ''}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&status=${selectedStatus.value}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setApprovals(data.approvals);
                    setTotalPages(data.totalPages);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };


    useEffect(() => {
        $(tableRef.current).DataTable().destroy();
        fetchApprovals();
    }, [change, page, pageSize, selectedEmployee]);

    const colResize = {
        isEnabled: true,
        saveState: false,
        hoverClass: 'dt-colresizable-hover',
        hasBoundCheck: true,
        minBoundClass: 'dt-colresizable-bound-min',
        maxBoundClass: 'dt-colresizable-bound-max',
        isResizable: function (column) {
            return true;
        },
        onResize: function (column) {
            //console.log('...resizing...');
        },
        onResizeEnd: function (column, columns) {
            console.log('I have been resized!');
        },
        stateSaveCallback: function (settings, data) {
            let stateStorageName = window.location.pathname + "/colResizeStateData";
            localStorage.setItem(stateStorageName, JSON.stringify(data));
        },
        stateLoadCallback: function (settings) {
            let stateStorageName = window.location.pathname + "/colResizeStateData",
                data = localStorage.getItem(stateStorageName);
            return data != null ? JSON.parse(data) : null;
        }
    }

    useEffect(() => {
        $(tableRef.current).DataTable({
            colResize: colResize,
            destroy: true,
            paging: false,
            searching: true,
            ordering: false
        });
    }, [approvals]);


    const filter = () => {
        setPage(1);
        setChange(prev => !prev);
    }

    const approveUserTimsheet = async (workHCode) => {
        const response = await fetch(`${SERVER_URL}/approvals/approve/${workHCode}`, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    };

    const rejectUserTimsheet = async (workHCode) => {
        const response = await fetch(`${SERVER_URL}/approvals/reject/${workHCode}`, {
            method: 'PUT',
            credentials: "include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    };

    const rejectMailAPI = async (userHCode, week) => {
        const response = await fetch(`${SERVER_URL}/workhours/reject`, {
            method: 'POST',
            credentials: "include",
            mode: 'cors',
            body: JSON.stringify({ week: week, comments: comments, user_hcode: userHCode }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'dd-MM-yyyy');
        return formattedDate;
    };

    const findWeek = (sdate) => {
        const date = new Date(sdate);
        const day = startOfWeek(date, { weekStartsOn: 1 });
        const weekNumber = getWeek(day);
        return weekNumber;
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);

        // Determine which approvals to include based on data.admin
        const filteredApprovals = getUser.isAdmin === 'false' ? approvals.filter(approval => approval.status !== 1) : approvals;

        // Extract hcode values from the filtered approvals
        const hcodes = filteredApprovals.map(approval => approval.hcode);

        // Set the isCheck state
        setIsCheck(isCheckAll ? [] : hcodes);
    };


    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const handleApprove = () => {
        isCheck.map((timesheet) => {
            approveUserTimsheet(timesheet);
        })

        if (isCheck.length) {
            setStatusModal(true);
            setStatusData({ statusCode: 200, msg: 'Timesheet Approved' });
        }

        if (isCheckAll) {
            setIsCheckAll(false);
            setIsCheck([]);
        }
    };

    const handleReject = async () => {
        if (isCheck.length) {
            setShowModal(true);
        }
    };

    const handleCommentSubmit = async () => {

        const rejectedTimesheetEmployees = Array.from(
            new Set(
                approvals
                    .filter(approval => isCheck.some(check => check === approval.hcode))
                    .map(approval => JSON.stringify({ userhcode: approval.userHCode, week: findWeek(approval.date) }))
            )
        ).map(json => JSON.parse(json));

        for (const employee of rejectedTimesheetEmployees) {
            await rejectMailAPI(employee.userhcode, employee.week);
        }

        isCheck.map((timesheet) => {
            rejectUserTimsheet(timesheet);
        });

        if (isCheck.length) {
            setStatusModal(true);
            setStatusData({ statusCode: 409, msg: 'Timesheet Rejected' });
        }

        if (isCheckAll) {
            setIsCheckAll(false);
            setIsCheck([]);
        }

    };

    const convertMinutesToHours = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
        return `${formattedHours}:${formattedMinutes}`;
    };

    const handleNextPage = () => {
        setPage(page + 1);
    };

    const handlePrevPage = () => {
        setPage(page - 1);
    };

    const handleRemoveSelectedEmploye = () => {
        setSelectedEmployee('');
    }

    const handlePageSize = (event) => {
        setPage(1);
        setPageSize(event.target.value);
    }

    return (
        <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
            <h2 className="text-center w-100 p-3">List of Approvals </h2>
            <div className="w-100 d-flex flex-column gap-5">
                <div className="d-flex flex-column gap-5">
                    <div className="d-flex justify-content-between align-self-center gap-5">
                        <Select
                            className="rounded"
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    color: "black",
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    width: 250,
                                }),
                            }}
                            name="EmployeeName"
                            options={employeeList}
                            onChange={(selectedOption) => {
                                setSelectedEmployee(selectedOption);
                            }}
                            value={
                                selectedEmployee
                                    ? { value: selectedEmployee.value, label: selectedEmployee.label }
                                    : null
                            }
                            placeholder="Select Employee Name"
                            menuPosition="fixed"
                            menuPlacement="bottom"
                            isSearchable
                            isClearable
                        />
                        <div className="d-flex justify-content-start align-items-center gap-5">
                            <label className="d-flex align-items-center">
                                <h6 className='px-2'>FROM:</h6>
                                <input
                                    type="date"
                                    value={selectedStartDate}
                                    onChange={handleDateChange}
                                />
                            </label>
                            <label className="d-flex align-items-center">
                                <h6 className='px-2'>TO:</h6>
                                <input
                                    type="date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    max={format(new Date(), 'yyyy-MM-dd')}
                                />
                            </label>
                        </div>
                        <Select
                            className="rounded"
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    color: "black",
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    width: 150,
                                }),
                            }}
                            name="status"
                            options={statusList}
                            onChange={(selectedOption) => {
                                setSelectedStatus(selectedOption);
                            }}
                            value={
                                selectedStatus
                                    ? { value: selectedStatus.value, label: selectedStatus.label }
                                    : null
                            }
                            placeholder="Select Status"
                            menuPosition="fixed"
                            menuPlacement="bottom"
                            isSearchable
                        />
                        <button className="btn btn-primary" onClick={filter}>Filter</button>
                    </div>
                    <div className="d-flex justify-content-between px-4 gap-3 w-100">
                        <div className='d-flex align-items-center gap-3'>
                            <select id="pageSize" onChange={handlePageSize} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={500}>500</option>
                                <option value={1000}>1000</option>
                            </select>
                            <button className='btn btn-primary' onClick={handlePrevPage} disabled={page === 1}>{'<'}</button>
                            <span>Page {page} of {totalPages}</span>
                            <button className='btn btn-primary' onClick={handleNextPage} disabled={page >= totalPages}>{'>'}</button>
                        </div>
                        <div className='d-flex gap-3'>
                            <button className={`rounded btn btn-success`} onClick={handleApprove}> Approve</button>
                            <button className={`${styles['btn-style']}  rounded btn`} onClick={handleReject}> Reject </button>
                        </div>
                    </div>
                </div>

                <div className="mb-3 px-4 overflow-auto" style={{ maxHeight: '65vh' }}>
                    <table id="table" className={`mt-3 table table-bordered table-hover table-striped ${styles.table} table-sm hover`} ref={tableRef} style={{ position: 'sticky' }}>
                        <thead style={{ background: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
                            <tr>
                                <th>
                                    <Checkbox
                                        type="checkbox"
                                        name="selectAll"
                                        id="selectAll"
                                        handleClick={handleSelectAll}
                                        isChecked={isCheckAll}
                                    />
                                </th>
                                <th>Date</th>
                                <th>Employee</th>
                                <th>Project</th>
                                <th>Task</th>
                                <th>NT</th>
                                <th>OT</th>
                                <th>TT</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {approvals.length > 0 && approvals.map((approval) => (
                                <tr key={approval.hcode}>
                                    <td>
                                        <Checkbox
                                            key={approval.hcode}
                                            type="checkbox"
                                            name={approval.employee_name}
                                            id={approval.hcode}
                                            handleClick={handleClick}
                                            isChecked={isAdmin ? isCheck.includes(approval.hcode) : (isCheck.includes(approval.hcode) && approval.status !== 1)}
                                            disable={isAdmin ? '' : approval.status === 1}
                                        />
                                    </td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`} style={{ maxWidth: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{formatDate(approval.date)}</td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`} style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{approval.employee_name}</td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`} style={{ maxWidth: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{approval.project_id}</td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`} style={{ maxWidth: '510px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{approval.task_name}</td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`}>{`${convertMinutesToHours(approval.nt_minutes_worked)}`}</td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`}>{`${convertMinutesToHours(approval.ot_minutes_worked)}`}</td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`}>{`${convertMinutesToHours(approval.nt_minutes_worked + approval.ot_minutes_worked)}`}</td>
                                    <td className={approval.status === 1 ? `${styles['green-row']}` : approval.status === 0 ? `${styles['red-row']}` : `${styles['black-row']}`} style={{ maxWidth: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{approval.status === null ? approval.is_submitted === 1 ? 'Submitted' : 'Created' : approval.status === 1 ? 'Approved' : 'Re Submitted'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
            {
                showModal && (
                    <RejectCommentsModal
                        showModal={showModal}
                        closeModal={closeModal}
                        setComments={setComments}
                        handleCommentSubmit={handleCommentSubmit}
                    />
                )
            }
            {
                statusModal && (
                    <ApprovalStatusModal
                        statusModal={statusModal}
                        closeStatusModal={closeStatusModal}
                        data={statusData}
                        setChange={setChange}
                    />
                )
            }
        </div >
    );
}

export default ListOfApprovals;
