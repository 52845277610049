import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfDisciplines from '../../components/tables/DisciplineTable/ListOfDisciplines';

function DashDisciplines() {

    return (
        <Layout>
            <ListOfDisciplines></ListOfDisciplines>           
        </Layout>
    );
}

export default withAuth(DashDisciplines);