import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';
const { startOfWeek, getWeek } = require('date-fns');


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfPermissions() {
    const [permissions, setPermissions] = useState([]);
    const [change, setChange] = useState(false);
    const tableRef = useRef(null);
     
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        $(tableRef.current).DataTable().destroy();
        fetch(`${SERVER_URL}/permission/read`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setPermissions(data.permission);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);


    useEffect(() => {
        if (permissions.length > 0) {
            $(tableRef.current).DataTable().destroy();
            $(tableRef.current).DataTable(
                {
                    destroy: true,
                    paging: false,
                }
            );
        }
    }, [permissions]);


    const handleDeleteClick = async (hcode) => {
        const response = await fetch(`${SERVER_URL}/permission/delete/${hcode}`, {
            method: 'DELETE',
            credentials:"include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(prev => !prev);
        return response;
    };



    const findWeek = (sdate) => {
        const date = new Date(sdate);
        const day = startOfWeek(date, { weekStartsOn: 1 });
        const weekNumber = getWeek(day);
        return weekNumber;
    }


    return (
        <>
            <div id="content" className='w-100 d-flex flex-column' style={{ minHeight: '79vh' }}>
                <h2 className="text-center w-100 pt-3">List of Permissions </h2>
                <div className="p-3">
                    <div className="d-flex justify-content-end">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={() => window.location.href = `/addPermissions`}
                        >
                            Provide Permission
                    </button>
                    </div>
                    <div className="w-100 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Login Name</th>
                                    <th>Permission Type</th>
                                    <th>Week</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissions.map((permission) => (
                                    <tr key={permission.hcode}>
                                        <td>{permission.login_name}</td>
                                        <td>{permission.permission_type ? 'Provided by Admin' : 'Timesheet got Rejected'}</td>
                                        <td>{findWeek(permission.start_date)}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(permission.hcode)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListOfPermissions;
