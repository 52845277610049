import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import '../../../common/Datatable.css';
import ProjectModal from '../../Modal/ProjectModal';
import { format } from "date-fns";
import ConfirmModal from '../../Modal/ConfirmModal';
import DataTable from 'react-data-table-component';
import Alert from "../../alert/Alert"
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchData = async (project, status) => {
    const response = await fetch(`${SERVER_URL}/project/status/${project.disciplineName}/${project.hcode}`, {
        method: 'PUT',
        mode: 'cors',
        credentials:"include",
        body: JSON.stringify({ project_status: status === 0 ? 1 : 0 }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


function ListOfProjects() {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');
    const [searchText, setSearchText] = useState('');  
    const [filteredData, setFilteredData] = useState([]);
    const [responseData, setResponseData] = useState({})
    const [showAlert, setShowAlert] = useState(false)

    const [updateProject, setUpdateProject] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [activity, setActivity] = useState(null);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');


    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const deleteProject = async (projectHCode) => {
        const response = await fetch(`${SERVER_URL}/project/delete/${projectHCode}`, {
            method: 'DELETE',
            credentials:"include",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(resp => resp.json())
        .then(data => {
            if(data.statusCode == 200){
                setResponseData(data)
                    const updatedSw = projects.filter(item => item.hcode !== projectHCode)
                    setProjects(updatedSw)
                    setFilteredData([])
                    setSearchText("")
                    setShowAlert(true)
                    setTimeout(() => setShowAlert(false), 2000);
            }
            if(responseData.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        })
    };


    const confirm = async (obj) => {
        let response = null;
        if (obj.action === 'Delete') {
            response = await deleteProject(updateProject.hcode);
            // const data = await response.json();
        } else if (obj.action === 'Status') {
            response = await fetchData(updateProject, updateProject.is_active);
            const data = await response.json();
        }
        closeModal();
        setChange(response);
    }


    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${SERVER_URL}/project`,{
                    method:"get",
                    credentials:"include"
                });
                const responseData =await response.json();

                if (responseData.statusCode === 200) {
                    setProjects(responseData.projects);
                }
                if(responseData.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }

            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchData();
    }, [change]);

    const handleProjectNameClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'dd-MM-yyyy');
        return formattedDate;
    };

    const handleProjectClick = (project) => {
        navigate(`/project/details/${project.hcode}`);
    };

    const handleActiveClick = (project) => {
        setUpdateProject(project);
        setMsg(`Mark ${project.project_id} - ${project.project_name} as ${project.is_active === 1 ? 'Completed' : 'Active'}?`)
        setConfirmModal(true);
        setActivity({ 'action': 'Status' });
        setSave(true);
    };

    const handleDownloadProjects = async (selection) => {
        const fileURL = await fetch(`${SERVER_URL}/project/download/${selection}`,{
            method:"get",
            credentials:"include"
        });
        const file = await fileURL.blob();
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        if(selection === 1){
            link.download = `po-tracker.xlsx`;
        }else{
            link.download = `projectStatusReport.xlsx`;
        }
        
        link.click();
    }

    const handleProjectDeleteClick = (project) => {
        setUpdateProject(project);
        setMsg(`Are you sure to delete ${project.project_id} - ${project.project_name}?`)
        setConfirmModal(true);
        setActivity({ 'action': 'Delete' });
        setSave(true);
    };

    let selectionNum

    const handlePoTrackerDownload = () =>{
          selectionNum = 1
          handleDownloadProjects(selectionNum)
    }

    const ProjectStatusReport =()=>{
        selectionNum = 2
        handleDownloadProjects(selectionNum)
    }              

//react table
    //react table
    const columns = [
        {
            name: <span style={{ fontWeight: '800' }}>ID</span>,
            selector: 'project_id',
            width : "80px",
            cell: row => <div style={{fontWeight:"500"}}>{row.project_id}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>PROJECT NAME</span>,
            selector: 'project_name',
            width:"350px",
            cell: row => (<a style={{ cursor: "pointer", fontWeight:"500" }} onClick={() => handleProjectClick(row)} > {row.project_name} </a> ),
        },
        {
            name: <span style={{ fontWeight: '800' }}>BILLABLE</span>,
            selector: 'is_billable',
            cell: row => <div style={{fontWeight:"500"}}>{row.is_billable ? 'Yes' : 'No'}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>START DATE</span>,
            selector: 'start_date',
            cell: row => <div style={{fontWeight:"500"}}>{formatDate(row.start_date)}</div>,
        },
        
        //     name: <span style={{ fontWeight: '800' }}>END DATE</span>,
        //     selector: 'end_date',
        //     cell: row => <div style={{fontWeight:"500"}}>{row.end_date}</div>,
        // },
        {
            name: <span style={{ fontWeight: '800' }}>PO NUMBER</span>,
            selector: 'po_number',
            cell: row => <div style={{fontWeight:"500"}}>{row.po_number}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>PO HOURS</span>,
            selector: 'po_hours',
            width:"80px",
            cell: row => <div style={{fontWeight:"500"}}>{row.po_hours}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>PO DATE</span>,
            selector: 'po_date',
            cell: row => <div style={{fontWeight:"500"}}>{formatDate(row.po_date)}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>STATUS</span>,
            selector: 'is_active',
            cell: row => <div style={{fontWeight:"500"}}>{row.is_active ?
                <button className="btn btn-success" style={{fontSize:"12px"}} onClick={() => handleActiveClick(row)}>
                    Active
                </button>
                : <button className="btn btn-danger" style={{fontSize:"11px"}} onClick={() => handleActiveClick(row)}>
                    Completed
                </button>}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>NETWORK IDS</span>,
            cell: row => ( <button className="btn btn-dark" onClick={() => window.location.href = `/${row.project_name}/network/ids/${row.hcode}/${row.project_id}`}>
            IDS
        </button>)
        },
        {
            name :<span style={{ fontWeight: '800' }}>EDIT</span>,
            cell: row =>(<a style={{ cursor: 'pointer' }} onClick={() => handleProjectNameClick(row)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a>),
            width:"70px"
        },
        {
            name :<span style={{ fontWeight: "800" }}>DELETE</span>,
            cell: row=>(<a style={{ cursor: 'pointer' }} onClick={() => handleProjectDeleteClick(row)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a>),
            width:"80px"
        }
    
    ];

    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
        // Filter the data based on the search text
        const filteredItems = projects.filter(item =>
          item.project_name.toLowerCase().includes(searchText.toLowerCase()) || item.project_id.includes(searchText)
        );
         setFilteredData(filteredItems);
      };
    
      const dataToDisplay = searchText.trim() === '' ? projects : filteredData;


    return (
        <>
            <div id="content" className='w-100 d-flex flex-column' style={{ minHeight: '79vh' }}>
                <h2 className="pt-3 text-center w-100">List of Projects </h2>
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                <div className="p-3">
                    <div className="d-flex justify-content-between" style={{marginBottom:"10px"}}>
                        <div>
                        <button onClick={handlePoTrackerDownload} className={`rounded btn btn-secondary`}>
                        PO-Tracker
                        </button>
                        <button onClick={ProjectStatusReport} style={{marginLeft:"12px"}} className={`rounded btn btn-secondary`}>
                        Project-Status-Report
                        </button>
                        </div>
                        <button className={`rounded btn btn-secondary`} onClick={() => window.location.href = `/addProjects`}>
                            Add Project
                        </button>
                    </div>

                    {/* <div className="w-100 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Project Name</th>
                                    <th>Billable</th>
                                     <th style={{ width: '100px' }}>Start Date</th>
                                    <th style={{ width: '100px' }}>End Date</th>
                                    <th>PO Number</th>
                                    <th>PO Hours</th>
                                    <th style={{ width: '100px' }}>PO Date</th>
                                    <th>Status</th>
                                    <th>Network IDS</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map((project) => (
                                    <tr key={project.hcode}>
                                        <td>{project.project_id}</td>
                                        <td><a style={{ cursor: "pointer" }} onClick={() => handleProjectClick(project)} > {project.project_name} </a> </td>
                                        <td>{project.is_billable ? 'Yes' : 'No'}</td>
                                    
                                        <td>{formatDate(project.start_date)}</td>
                                        <td>{project.end_date ? formatDate(project.end_date) : `NA`}</td>
                                        <td>{project.po_number ? project.po_number : 0}</td>
                                        <td>{project.po_hours ? project.po_hours : 0}</td>
                                        <td>{project.po_date ? formatDate(project.po_date) : `NA`}</td>
                                        <td>
                                            {
                                                project.is_active ?
                                                    <button className="btn btn-success" onClick={() => handleActiveClick(project)}>
                                                        Active
                                                    </button>
                                                    : <button className="btn btn-danger" onClick={() => handleActiveClick(project)}>
                                                        Completed
                                                    </button>
                                            }
                                        </td>
                                        <td>
                                            <button className="btn btn-dark" onClick={() => window.location.href = `/${project.project_name}/network/ids/${project.hcode}/${project.project_id}`}>
                                                IDS
                                            </button>
                                        </td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleProjectNameClick(project)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleProjectDeleteClick(project)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}
                
                <div className='flex align-items-end justify-content-between'>
                        <input
                         style={{ marginBottom: '10px'}}
                         type='text'
                         placeholder='search project'
                         value={searchText}
                         onChange={handleSearch}

                        /> 
                           
                        
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={dataToDisplay}
                         />

                    </div>



                </div>
            </div>
            {
                selectedProject && (
                    <ProjectModal
                        project={selectedProject}
                        showModal={showModal}
                        closeModal={closeModal}
                        change={setChange}
                    />
                )
            }
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={activity}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default ListOfProjects;
