import React from "react";


function Alert(props) {
    let isAuthenticated = false;
    if (props.data.statusCode === 200) {
        isAuthenticated = true;
    }
    return (
        <div className={"alert alert-dismissible fade show" + (isAuthenticated ? ' alert-success' : ' alert-danger')}>
            <h3>{props.data.message}</h3>
            {/* <button className="btn-close pb-2" aria-label="close" data-bs-dismiss="alert">
            </button> */}
        </div>
    );
}


export default Alert;