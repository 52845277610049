import React, { useState } from "react";
import Alert from '../alert/Alert';
import styles from './../../common/common.module.css';
import ConfirmModal from '../Modal/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AddBusinessForm = () => {
    const [name, setName] = useState('');

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        let formData = {
            business_line_name: name
        };


        fetch(`${SERVER_URL}/business/add`, {
            method: 'POST',
            credentials:"include",
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/business`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    }


    const handleName = (event) => {
        setName(event.target.value.trim())
    };

    const handleGoBack = () => {
        navigate('/business')
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (!name) {
            return;
        }

        setConfirmModal(true);
        setSave(true);
    };

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <form className="w-25" onSubmit={handleFormSubmit}>
                    <h1 className="h1 mb-3 fw-normal text-center font-weight-bold">Add Business Line</h1>
                    <div className="form-floating">
                        <input className="form-control" type="text" name="name" onChange={handleName} placeholder="Metals" required />
                        <label htmlFor="name">Business Line Name</label>
                    </div>
                    <br />
                    <div className="d-flex justify-content-between">
                        <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                        <button className={`btn btn-secondary`} type="submit">Add Business Line</button>
                    </div>
                    <br />
                </form>
            </div>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg = {msg}
                    />
                )
            }
        </>
    );
};

export default AddBusinessForm;