import withAuth from "../../components/cookie/AuthenticatedComponent";
import Layout from '../../components/layout/Layout';
import AddDisciplineAccess from "../../components/dashpage/AddDisciplineAccess";


function DashAddDisciplineAccess() {
    return (
        <Layout>
            <AddDisciplineAccess/>           
        </Layout>
    );
}

export default withAuth(DashAddDisciplineAccess);