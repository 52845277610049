
import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfDesignations from '../../components/tables/DesignationTable/ListOfDesignations';

function DashDesignations() {

    return (
        <Layout>            
            <ListOfDesignations></ListOfDesignations>            
        </Layout>
    );
}

export default withAuth(DashDesignations);