import React, { useState } from "react";
import Alert from '../alert/Alert';
import { Link } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ForgotForm() {
    const [username, setUsername] = useState('');
    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const handleUserName = (event) => {
        setUsername(event.target.value)
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        fetch(`${SERVER_URL}/forgotPassword`, {

            method: 'POST',
            
            body: JSON.stringify({
                email: username
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error));
    }


    return (
        <form className="w-25 d-flex flex-column gap-3" method="post" onSubmit={handleFormSubmit}>
            <div id="msg" className="d-flex justify-content-center align-items-center">
                {showAlert && <Alert data={responseData} ></Alert>}
            </div>
            <h1 className="text-center">Forgot Password</h1>
            <div className="py-2">
                <label htmlFor="email" className='h4'>Email address</label>
                <input type="email" className="form-control" name='email' placeholder="name@example.com" onChange={handleUserName} autoComplete="" required />
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <Link to='/login' className={`btn btn-outline-secondary`}>Login Page</Link>
                <button className={`btn btn-secondary`} type="submit">Send Reset Link</button>
            </div>
        </form>
    );
}


export default ForgotForm;