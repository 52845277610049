import React, { useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import style from './style/modal.module.css';
import ConfirmModal from './ConfirmModal';
import { format } from "date-fns";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const fetchData = async (data, holidayHCode) => {
    const response = await fetch(`${SERVER_URL}/holiday/update/${holidayHCode}`, {
        method: 'PUT',
        mode: 'cors',
        credentials:"include",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};


const HolidayModal = ({ holiday, showModal, closeModal, change }) => {
    const holidayHCode = holiday.hcode;
    const [date, setDate] = useState(holiday.date);
    const [hdesc, setHDesc] = useState(holiday.hdesc);

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async () => {
        const response = await fetchData({ date, hdesc }, holidayHCode);
        const data = await response.json();
        closeModal();
        change(response);
    };

    const handleSaveChanges = async () => {
        setConfirmModal(true);
        setSave(true);
    };

    const handleHdesc = (event) => {
        setHDesc(event.target.value.trim())
    };

    const handleDate = (event) => {
        setDate(event.target.value)
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'yyyy-MM-dd');
        return formattedDate;
    };


    return (
        <>
            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton className={`${style['modal-header']}`}>
                    <Modal.Title className={`${style['modal-tittle']}`}>Edit Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${style['modal-body']}`}>
                    <Form >
                        <Form.Group controlId="date">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={formatDate(date)}
                                onChange={handleDate}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="desc">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="hdesc"
                                value={hdesc}
                                onChange={handleHdesc}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={`${style['modal-footer']}`}>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        msg={msg}
                    />
                )
            }
        </>
    );
};


export default HolidayModal;