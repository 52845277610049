import React, { useState } from "react";
import Alert from '../alert/Alert';
import { useNavigate } from "react-router-dom";
import { LogoutIfNoHcode } from '../../common/LogoutIfNoHcode';
import { useDispatch } from 'react-redux';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const AddSoftwareForm = () => {
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');

    const [responseData, setResponseData] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleName = (event) => {
        setName(event.target.value.trim())
    };

    const handleDesc = (event) => {
        setDesc(event.target.value.trim())
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Add validation check for empty fields
        if (!name) {
            return; // If any field is empty, exit the function
        }

        // Prepare the form data
        let formData = {
            software_name: name,
            software_desc: desc
        };


        fetch(`${SERVER_URL}/software/add`, {
            method: 'POST',
            credentials:"include",
            // Convert the form data object to a JSON string and include it in the request body
            body: JSON.stringify(formData),
            headers: {
                // Set the Content-Type header to application/json
                // Tells the server that the content type of the request body is JSON.
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()) // Parse the response body as JSON
            .then(data => {
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
                setResponseData(data);
                setShowAlert(true);
                setTimeout(() => {
                    if (data.statusCode === 200) {
                        navigate(`/softwares`);
                    }
                }, 1000);
                setTimeout(() => setShowAlert(false), 2000);
            })
            .catch(error => console.error(error)); // Log any errors to the console
    };

    const handleGoBack = () => {
        navigate('/softwares')
    }

    return (
        <>
            <div className="w-100" id="content" style={{}}>
                <div id="msg" className="d-flex justify-content-center align-items-center">
                    {showAlert && <Alert data={responseData} ></Alert>}
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '79vh' }}>
                    <form className="w-25" onSubmit={handleFormSubmit}>
                        <h1 className="h1 mb-3 fw-normal text-center font-weight-bold" >Add Software</h1>
                        <div className="form-floating">
                            <input className="form-control" type="text" name="name" onChange={handleName} placeholder="Autodesk Forge" required />
                            <label htmlFor="name">Software Name</label>
                        </div>
                        <br />
                        {/* <div className="form-floating">
                            <input type="text" className="form-control" name="desc" onChange={handleDesc} placeholder="Description" />
                            <label htmlFor="desc">Software Description</label>
                        </div>
                        <br /> */}
                        <div className="d-flex justify-content-between">
                            <button className={`btn btn-secondary`} onClick={handleGoBack}>Go back</button>
                            <button className={`btn btn-secondary`} type="submit">Add Software</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddSoftwareForm;