import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/loginContext";
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router";
import fetchHcode from "../../redux/hcodeFetch";
import { logout } from "../../redux/Slice/userSlice"
import { useDispatch } from "react-redux";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Header = () => {
    const { isLogin, setIsLogin, isAdmin, setIsAdmin, setTheme } = useContext(AppContext);
    const [isDarkTheme, setIsDarkTheme] = useState(true);

    const navigate = useNavigate();
    const getUser = fetchHcode()
    const dispatch = useDispatch()
      
    useEffect(() => {

        if (window.location.pathname !== '/login') {
            if (getUser.hcode == null) {
                if (window.location.pathname === '/forgotpassword') {
                    return;
                }
                else if (window.location.pathname === '/resetpwd') {
                    return;
                } else {
                    navigate('/login');
                }
            }
        }
    }, [])

    // Axios.defaults.withCredentials = true
    // const fetchData = async () => {
    //     // if (getUser.hcode) {
    //     const response = await fetch(`${SERVER_URL}/Username`, {
    //         method: "get",
    //         credentials: "include"
    //     });

    //     const data = await response.json()
    //     console.log(data)
    //     if (data.statusCode === 200) {
    //         setUserName(data.login_name);
    //         setIsAdmin(data.isAdmin === 1);
    //     } else if (data.statusCode === 401) {
    //         dispatch(logout({ hcode: "", isAdmin: "" }));
    //         navigate('/login');
    //     }
    //     // }
    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        setTheme(isDarkTheme);
    }, [isDarkTheme]);

    const handleLogOut = async() => { 
        dispatch(logout({ hcode: "", isAdmin: "" }));
        await fetch(`${SERVER_URL}/logout`,{
            method:"POST",
            credentials:"include"
        })
        setIsLogin(false);
        navigate('/login');
    };

    const toggleTheme = () => {
        setIsDarkTheme((prevTheme) => !prevTheme);
    };


    const darkThemeStyles = {
        backgroundColor: 'black',
        color: 'white',
        fontFamily: 'serif'
    };

    const lightThemeStyles = {
        backgroundColor: 'white',
        color: 'black',
        fontFamily: 'serif'
    };

    const themeStyles = isDarkTheme ? darkThemeStyles : lightThemeStyles;
    const url = isDarkTheme ? process.env.PUBLIC_URL + '/images/light_icon.png' : process.env.PUBLIC_URL + '/images/dark_icon.png';

    return (
        <>
            {/* {isLogin ? */}
            <Navbar style={{ ...themeStyles, minHeight: '7vh' }} className="px-3" >
                <div className="w-25 d-flex">
                    <Navbar.Brand href="https://www.sidvinoutotec.com/" target="_blank">
                        <img src={process.env.PUBLIC_URL + '/images/logo.png'} height="20" alt="" />
                    </Navbar.Brand>
                </div>
                <h2 className="w-50 text-center">SOE Timelog</h2>
                {getUser.hcode ?
                    <>
                        <div className="d-flex flex-row justify-content-end gap-2 w-25">
                            {/* <div className="d-flex flex-row justify-content-center">
                            <a style={{ cursor: 'pointer' }} onClick={toggleTheme}><img src={url} alt="Theme" height='30px' width='30px' /></a>
                        </div> */}
                            <div className="d-flex flex-column align-items-end text-capitalize">
                                <h6 className="">Welcome, {getUser.empName}</h6>
                                <a id="logout-link" href='#' onClick={() => { handleLogOut() }} style={{ textDecoration: 'none' }}>Sign out</a>
                            </div>
                        </div>
                    </> : <></>
                }
            </Navbar >
            {/* // <Navbar style={{ ...themeStyles, minHeight: '7vh' }} className="px-3" >
                //     <div className="d-flex align-items-baseline">
                //         <Navbar.Brand href="/projects">
                //             <a href="https://www.sidvinoutotec.com/" target="_blank">
                //                 <img src={process.env.PUBLIC_URL + '/images/logo.png'} height="20" alt="" />
                //             </a>
                //         </Navbar.Brand>
                //     </div>
                // </Navbar> */}
            {/* } */}
        </>
    );

}

export default Header;