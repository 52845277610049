import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5';
import styles from '../../../common/common.module.css';
import UserModal from '../../Modal/UserModal';
import UserPasswordModal from '../../Modal/UserPasswordModal';
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';
import { useDispatch } from 'react-redux';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfUsers() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');
    const tableRef = useRef(null);

    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        fetch(`${SERVER_URL}/user`,{
            method:'get',
            credentials:'include'
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setUsers(data.users);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);


    useEffect(() => {
        if (users.length > 0) {
            $(tableRef.current).DataTable({
                paging: false,
                destroy: true,
                order: [[0, 'asc']]
            });
        }
    }, [users]);

    const fetchData = async (data) => {
        const response = await fetch(`${SERVER_URL}/user/isEnabled`, {
            method: 'PUT',
            mode: 'cors',
            credentials:"include",
            body: JSON.stringify({
                email: data
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setChange(response);
        return response;
    };

    const handleEmployeeClick = (user) => {
        navigate(`/employee/details/${user.hcode}`);
    };

    const handleUserNameClick = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePasswordChangeClick = (user) => {
        setSelectedUser(user);
        setShowPasswordModal(true);
    };

    const closePasswordModal = () => {
        setShowPasswordModal(false);
    };

    const handleDownloadEmployees = async () => {
        const fileURL = await fetch(`${SERVER_URL}/user/generate/report}`,{
            method:'get',
            credentials:"include"
        });
        const file = await fileURL.blob();
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Employees.xlsx`;
        link.click();
    };

    return (
        <>
            <div id="content" className='w-100 d-flex flex-column' style={{ minHeight: '79vh' }}>
                <h2 className="text-center w-100 pt-3">List of Employees </h2>
                <div className="p-3">
                    <div className="d-flex justify-content-between">
                        <button className={`rounded btn btn-secondary`} onClick={handleDownloadEmployees}>
                            Download Employee List
                        </button>
                        <button className={`rounded btn btn-secondary`} onClick={() => window.location.href = `/addEmployee`}>
                            Add Employee
                        </button>
                    </div>
                    <div className="w-100 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Employee ID</th>
                                    <th>Employee Name</th>
                                    <th>Email</th>
                                    {/* <th>User Type</th> */}
                                    <th>Discipline</th>
                                    <th>Designation</th>
                                    <th>Reporting Manager</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => (
                                    <tr key={user.hcode}>
                                        <td>{user.emp_id}</td>
                                        <td><a style={{ cursor: "pointer" }} onClick={() => handleEmployeeClick(user)} > {user.employee_name} </a> </td>
                                        <td>{user.email_id}</td>
                                        {/* <td>{user.is_Admin ? 'Admin' : 'User'}</td> */}
                                        <td>{user.discipline_name}</td>
                                        <td>{user.designation_name}</td>
                                        <td>{user.reporting_manager}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handlePasswordChangeClick(user)}><img src={process.env.PUBLIC_URL + '/images/padlock.png'} alt="Password Change" height='20px' width='20px' /></a></td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleUserNameClick(user)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                selectedUser && (
                    <UserModal
                        user={selectedUser}
                        showModal={showModal}
                        closeModal={closeModal}
                        change={setChange}
                    />
                )
            }
            {
                selectedUser && (
                    <UserPasswordModal
                        user={selectedUser}
                        showPasswordModal={showPasswordModal}
                        closePasswordModal={closePasswordModal}
                        change={setChange}
                    />
                )
            }
        </>
    );
}

export default ListOfUsers;
