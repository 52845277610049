import React, { useState, useEffect, useRef } from 'react';
import 'datatables.net-bs5';
import ClientModal from '../../Modal/ClientModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import Alert from "../../alert/Alert"
import DataTable from 'react-data-table-component';
import { logout } from '../../../redux/Slice/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import { LogoutIfNoHcode } from '../../../common/LogoutIfNoHcode';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfClients() {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');
    const [searchText, setSearchText] = useState('');  
    const [filteredData, setFilteredData] = useState([]);
    const [showAlert, setShowAlert] = useState(false)
    const [responseData, setResponseData] = useState({})

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (client) => {
        await fetch(`${SERVER_URL}/client/delete/${client.hcode}`, {
            method: 'DELETE',
            mode: 'cors',
            credentials:'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then((data)=>{
            if (data.statusCode === 200) {
                setResponseData(data)
                const updatedSw = clients.filter(item => item.hcode !== client.hcode)
                setClients(updatedSw)
                setFilteredData([])
                setSearchText("")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000);
            }
            if(data.statusCode === 401){
                LogoutIfNoHcode(dispatch, navigate)
            }
        })
    }

    useEffect(() => {
        fetch(`${SERVER_URL}/client`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setClients(data.clients);
                }
                if(data.statusCode === 401){
                    LogoutIfNoHcode(dispatch, navigate)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);

    const closeModal = () => {
        setShowModal(false);
    };

    const handleClientEdit = (client) => {
        setSelectedClient(client);
        setShowModal(true);
    };

    const handleClientDelete = (client) => {
        setSelectedClient(client);
        setConfirmModal(true);
        setSave(true);
    };

    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
    
        // Filter the data based on the search text
        
        const filteredItems = clients.filter(item =>
          item.client_name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredData(filteredItems);
      };
    
      const dataToDisplay = searchText.trim() === '' ? clients : filteredData;

    //react table 
    const columns = [
        {
            name: <span style={{ fontWeight: '800' }}>CLIENT CODE</span>,
            selector: 'client_code',
            cell: row => <div style={{fontWeight:"500"}}>{row.client_code}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>CLIENT NAME</span>,
            selector: 'client_name',
            cell: row => <div style={{fontWeight:"500"}}>{row.client_name}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>CLIENT CITY</span>,
            selector: 'client_city',
            cell: row => <div style={{fontWeight:"500"}}>{row.client_city}</div>,
        },
        {
            name :<span style={{ fontWeight: '800' }}>EDIT</span>,
            cell: row =>(<a style={{ cursor: 'pointer' }} onClick={() => handleClientEdit(row)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a>),
            width: '200px'
        },
        {
            name :<span style={{ fontWeight: "800" }}>DELETE</span>,
            cell: row=>(<a style={{ cursor: 'pointer' }} onClick={() => handleClientDelete(row)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a>),
            width: '200px'
        }
    
    ];



    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '85vh' }}>
                <h2 className="text-center w-75 pt-3">List of Clients </h2>
                <div className="p-3 w-100 d-flex flex-column align-items-center">
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                    <div className="d-flex justify-content-end w-75">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={() => window.location.href = `/clients/add`}
                        >
                            Add Client
                        </button>
                    </div>
                   
                    {/* <div className="w-75 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Client Code</th>
                                    <th>Client Name</th>
                                    <th>Client City</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map((client) => (
                                    <tr key={client.hcode}>
                                        <td>{client.client_code}</td>
                                        <td>{client.client_name}</td>
                                        <td>{client.client_city}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleClientEdit(client)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleClientDelete(client)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}
                  <div className='w-75 flex align-items-end justify-content-between'>
                        <input
                         style={{ marginBottom: '10px'}}
                         type='text'
                         placeholder='search clients'
                         value={searchText}
                         onChange={handleSearch}
                        /> 
                           
                        
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={dataToDisplay}
                         />

                    </div>
                  
                </div>
            </div>
            {
                selectedClient && (
                    <ClientModal
                        client={selectedClient}
                        showModal={showModal}
                        closeModal={closeModal}
                        change={setChange}
                    />
                )
            }
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={selectedClient}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default ListOfClients;
