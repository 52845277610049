import React, { useState, useEffect} from 'react';
import 'datatables.net-bs5';
import ConfirmModal from '../../Modal/ConfirmModal';
import HolidayModal from '../../Modal/HolidayModal';
import { format } from "date-fns";
import DataTable from 'react-data-table-component';
import Alert from "../../alert/Alert"
import { logout } from '../../../redux/Slice/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ListOfHolidays() {
    const [holidays, setHolidays] = useState([]);
    const [selectedHoliday, setSelectedHoliday] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [change, setChange] = useState('');
    const [searchText, setSearchText] = useState('');  
    const [filteredData, setFilteredData] = useState([]);
    const [responseData, setResponseData] = useState({})
    const [showAlert, setShowAlert] = useState(false)

    const [confirmModal, setConfirmModal] = useState(false);
    const [save, setSave] = useState(false);
    const [msg, setMsg] = useState('Are you sure?');

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setSave(false);
    };

    const confirm = async (holiday) => {
        await fetch(`${SERVER_URL}/holiday/delete/${holiday.hcode}`, {
            method: 'DELETE',
            mode: 'cors',
            credentials:"include",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then(respData =>{
            if(respData.statusCode === 200){
                setResponseData(respData)
                const updatedSw = holidays.filter(item => item.hcode !== holiday.hcode)
                setHolidays(updatedSw)
                setFilteredData([])
                setSearchText("")
                setShowAlert(true)
                setTimeout(() => setShowAlert(false), 2000);
            }
        })
       }


    useEffect(() => {
        fetch(`${SERVER_URL}/holiday`,{
            method:"get",
            credentials:"include"
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 200) {
                    setHolidays(data.holidays);
                }
                if(data.statusCode === 401){
                    dispatch(logout({ hcode: "", isAdmin: "" }));
                    navigate('/login');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [change]);


    const closeModal = () => {
        setShowModal(false);
    };

    const handleHolidayEdit = (holiday) => {
        setSelectedHoliday(holiday);
        setShowModal(true);
    };

    const handleHolidayDelete = (holiday) => {
        setSelectedHoliday(holiday);
        setConfirmModal(true);
        setSave(true);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'dd-MM-yyyy');
        return formattedDate;
    };

   //react table columns
    const columns = [
        {
            name: <span style={{ fontWeight: '800' }}>DATE</span>,
            selector: 'date',
            cell: row => <div style={{fontWeight:"500"}}>{formatDate(row.date)}</div>,
        },
        {
            name: <span style={{ fontWeight: '800' }}>DESCRIPTION</span>,
            selector: 'hdesc',
            cell: row => <div style={{fontWeight:"500"}}>{row.hdesc}</div>,
        },
        {
            name :<span style={{ fontWeight: '800' }}>EDIT</span>,
            cell: row =>(<a style={{ cursor: 'pointer' }} onClick={() => handleHolidayEdit(row)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a>),
            width: '100px'
        },
        {
            name :<span style={{ fontWeight: "800" }}>DELETE</span>,
            cell: row=>(<a style={{ cursor: 'pointer' }} onClick={() => handleHolidayDelete(row)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a>),
            width: '100px'
        }
    
    ];


    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
    
        // Filter the data based on the search text
        
        const filteredItems = holidays.filter(item =>
          item.hdesc.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredData(filteredItems);
      };
    
      const dataToDisplay = searchText.trim() === '' ? holidays : filteredData; 


    return (
        <>
            <div id="content" className='w-100 d-flex flex-column align-items-center' style={{ minHeight: '79vh' }}>
                <h2 className="text-center w-100 pt-3">List of Holidays </h2>
                <div className="p-3 w-75 d-flex flex-column align-items-center">
                <div id="msg" className="w-50">
                    {showAlert && <Alert data={responseData}></Alert>}
                </div>
                    <div className="d-flex justify-content-end w-75">
                        <button
                            className={`rounded btn btn-secondary`}
                            onClick={() => window.location.href = `/holidays/add`}
                        >
                            Add Holidays
                        </button>
                    </div>
                    
                    {/* <div className="w-75 pt-3" style={{ minHeight: '65vh' }}>
                        <table id="table" className={`mt-4 table table-bordered table-hover table-striped ${styles.table} table-sm`} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {holidays.map((holiday) => (
                                    <tr key={holiday.hcode}>
                                        <td>{formatDate(holiday.date)}</td>
                                        <td>{holiday.hdesc}</td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleHolidayEdit(holiday)}><img src={process.env.PUBLIC_URL + '/images/edit_icon.png'} alt="Edit" height='20px' width='20px' /></a></td>
                                        <td><a style={{ cursor: 'pointer' }} onClick={() => handleHolidayDelete(holiday)}><img src={process.env.PUBLIC_URL + '/images/trash_icon.png'} alt="Delete" height='20px' width='20px' /></a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> */}

                   <div className='w-75 flex align-items-end justify-content-between'>
                        <input
                         style={{ marginBottom: '10px'}}
                         type='text'
                         placeholder='search holiday'
                         value={searchText}
                         onChange={handleSearch}
                        /> 
                           
                         <DataTable
                          columns={columns}
                          striped
                          highlightOnHover
                          data={dataToDisplay}
                         />

                    </div>

                </div>
            </div>
            {
                selectedHoliday && (
                    <HolidayModal
                        holiday={selectedHoliday}
                        showModal={showModal}
                        closeModal={closeModal}
                        change={setChange}
                    />
                )
            }
            {
                save && (
                    <ConfirmModal
                        confirmModal={confirmModal}
                        closeConfirmModal={closeConfirmModal}
                        confirm={confirm}
                        obj={selectedHoliday}
                        msg={msg}
                    />
                )
            }
        </>
    );
}

export default ListOfHolidays;
