import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import AddPermissionForm from '../../components/dashpage/AddPermissionForm';

function DashAddPermission() {
    
    return (
        <Layout>
            <AddPermissionForm></AddPermissionForm>           
        </Layout>
    );
}

export default withAuth(DashAddPermission);