import withAuth from '../../components/cookie/AuthenticatedComponent';
import Layout from '../../components/layout/Layout';
import ListOfRecords from '../../components/tables/ReportsTable/ListOfRecords';
// import fetchHcode from "../../redux/hcodeFetch";
// import { useNavigate } from 'react-router';


function DashReports() {

    // const getUser = fetchHcode() 
    //  const navigate = useNavigate()
    //  if(getUser.hcode === ""){

    //     navigate("/login")
    //  }

    return (
        <Layout>            
            <ListOfRecords></ListOfRecords>
        </Layout>
    );
}

export default withAuth(DashReports);